import { WHITE } from "../../constants";

const styles = {
  overlayCover: {
    backgroundColor: `rgba(0,0,0,.4)`,
    minWidth: '100%',
    height: '100%'
  },
  container: {
    backgroundColor: WHITE,
    textAlign: 'left'
  }
};

export default styles;
