import { WAIT_TIMEOUT } from '../../constants';
import store from '../../redux/store';

export const updateTimer = component => {
  const { inactivityModal } = store.getState().componentReducer;
  const { timer } = component.state;
  if (inactivityModal && !component.inactivityTimeoutInterval) {
    component.inactivityTimeoutInterval = setInterval(() => {
      const { timer } = component.state;
      if (timer > 0) {
        component.setState({ timer: timer - 1000 });
      }
    }, 1000);
  } else if (!inactivityModal && timer !== WAIT_TIMEOUT) {
    clearInterval(component.inactivityTimeoutInterval);
    component.inactivityTimeoutInterval = null;
    component.setState({ timer: WAIT_TIMEOUT });
  }
};
