import { UNIT, COLOR_LIGHT_GRAY, WHITE } from '../../../constants/configuration';

const styles = color => ({
  addToCartCommandStyle: {
    backgroundColor: color,
    color: WHITE,
    width: '350px',
    height: '40px',
    borderRadius: UNIT * 2,
    fontSize: UNIT
  },
  addToCartVerticalTerminalStyle: {
    backgroundColor: color,
    color: WHITE,
    width: '500px',
    height: '70px',
    borderRadius: UNIT * 2,
    fontSize: UNIT * 1.5
  },
  modalOptionContainer: {
    backgroundColor: color,
    border: `2px solid ${COLOR_LIGHT_GRAY}`,
    borderRadius: UNIT
  }
});

export default styles;
