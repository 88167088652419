import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import Order from '../Order'

class PastOrders extends React.Component {
  renderPastOrders = () => {
    const { pastOrders } = this.props;
    return (
      !_.isEmpty(pastOrders) &&
      _.keys(pastOrders)
        .sort(
          (a, b) => new Date(pastOrders[b].date).getTime() - new Date(pastOrders[a].date).getTime()
        )
        .map(key => {
          const order = pastOrders[key];
          return <Order order={order} key={key} showFees />;
        })
    );
  };

  render() {
    const { orders, pastOrders } = this.props;
    if (_.isEmpty(orders) && _.isEmpty(pastOrders)) {
      return (
        <div className="text-center">
          <p className="text-secondary mb-0 label">
            Vous n&#39;avez encore jamais passé de commandes.
          </p>
        </div>
      );
    }
    return (
    !_.isEmpty(orders) && _.isEmpty(pastOrders)
        ? <div className='text-center'>
            <p className='text-secondary mb-0 label'>
              Vous n&#39;avez pas encore de commandes finalisées.
            </p>
          </div>
        : <div>{this.renderPastOrders()}</div>
    );
  }
}

const mapStateToProps = ({ orderReducer }) => {
  const { orders, pastOrders } = orderReducer;
  return {
    orders,
    pastOrders
  };
};

export default connect(mapStateToProps)(PastOrders);
