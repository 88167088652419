import { UPDATE_ORDERS, UPDATE_ORDER_TIME, UPDATE_EASEL_NUMBER } from './types';

export const updateOrders = newOrder => {
  return { type: UPDATE_ORDERS, payload: newOrder };
};

export const updateOrderTime = orderTime => {
  return { type: UPDATE_ORDER_TIME, payload: orderTime };
};

export const updateEaselNumber = easelNumber => {
  return { type: UPDATE_EASEL_NUMBER, payload: easelNumber };
};
