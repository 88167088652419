import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import Cover from '../../components/Cover/Cover'
import ListSection from '../../components/ListSections/ListSections/ListSections.view'
import ListProduct from '../../components/ListProducts/ListProduct'
import Footer from '../../components/Footer'
import Splash from '../../components/Splash'
import CoverOverlay from '../../components/CoverOverlay'
import BottomCart from '../../components/BottomCart'
import InfoBar from '../../components/InfoBar/InfoBar.view'
import {
  selectSection,
  setIsMobile,
  setNavbarHeight,
  showAddressModal,
  showHorizontalSection,
  showMessageModal,
  updateShop,
  updateShopIsLoaded
} from '../../redux/actions'
import {
  getClosedMessage,
  getOffsetTop,
  isQrCode,
  isSplash,
  isStringNotNull,
  isTerminal,
  isTerminalVertical,
  resetTerminalTimeout
} from '../../utils'
import { loadLocalizeSdk } from '../../api'
import { displayOrNotHeader, getClasses, removeItemFromCommande, updateShopFromLink } from './Home.services'
import { BLOCK_CONTENT } from '../../constants'
import { CUSTOMER_ADDRESS_CHECK_VALUES } from '../../redux/reducers/componentReducer'

const INITIAL_STATE = {
  displaySectionsNavBar: false
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  async UNSAFE_componentWillMount() {
    const { customerAddress, orderType } = this.props;
    await updateShopFromLink(this, customerAddress, orderType);
  }

  componentDidMount() {
    loadLocalizeSdk();
    const { setIsMobile, showHorizontalSection } = this.props;
    setIsMobile(window.matchMedia('screen and (max-width: 575px)').matches);
    if (!isTerminalVertical()) {
      window.addEventListener('scroll', this.handleScroll);
    }
    window.addEventListener('resize', this.updateDimensions);
    showHorizontalSection();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      sectionOrientation,
      showHorizontalSection,
      showAddressModal,
      customerAddress: prevCustomerAddress,
      isUserAnonymous,
      showMessageModal,
      shopIsLoaded,
      updateShopIsLoaded,
      marketplace,
      history,
      match,
      shops,
      orderType: prevOrderType,
      shopId,
      customerAddressCheck,
    } = this.props;
    const customerAddressChanged =
      nextProps.customerAddress &&
      (!prevCustomerAddress ||
        !_.isEqual(
          _.omit(prevCustomerAddress, ['instruction', 'street2']),
          _.omit(nextProps.customerAddress, ['instruction', 'street2'])
        ));
    const isSelectedShopChanged =
      shopId !== nextProps.shopId || prevOrderType !== nextProps.orderType;
    if (customerAddressChanged || isSelectedShopChanged) {
      await updateShopFromLink(this, nextProps.customerAddress, nextProps?.orderType);
    }
    if (!_.isEmpty(nextProps.commande)) {
      removeItemFromCommande(nextProps, this)
    }
    if (sectionOrientation !== nextProps.sectionOrientation && !isTerminalVertical()) {
      showHorizontalSection();
    }
    // Check if shopId in url exists
    let shopExists = true;
    if (marketplace?.enabled && match.params.shopId) {
      shopExists = _.find(shops, (shop, shopIndex) => {
        return shopIndex === match.params.shopId;
      });
    }
    if (
      nextProps.overlayCover !== BLOCK_CONTENT &&
      !nextProps.customerAddress &&
      nextProps.googleMapsEnabled &&
      nextProps.addressIsLoaded &&
      !isUserAnonymous &&
      !this.executed &&
      !isSplash() &&
      !nextProps.connexionModal
    ) {
      this.executed = true;
      if (marketplace?.enabled && !shopExists) {
        history.push('/');
      } else if (!isQrCode()) {
        showAddressModal(true);
      }
    } else if (!nextProps.addressIsLoaded || (nextProps.customerAddress !== this.props.customerAddress && customerAddressCheck === CUSTOMER_ADDRESS_CHECK_VALUES.VALID)) {
      showAddressModal(false);
    }
    // When user does not have an address, force to show error modal
    if (!nextProps.customerAddress && nextProps.addressIsLoaded && !nextProps.shopIsLoaded) {
      updateShopIsLoaded(true);
    }
    if (
      !shopIsLoaded &&
      nextProps.shopIsLoaded &&
      nextProps.customerAddress &&
      isStringNotNull(nextProps.closed) &&
      shopId !== nextProps.shopId &&
      !isTerminal()
    ) {
      showMessageModal(getClosedMessage(nextProps.closed));
    }
  }

  updateDimensions = () => {
    const { setIsMobile, setNavbarHeight, showHorizontalSection } = this.props;
    setIsMobile(window.matchMedia('screen and (max-width: 575px)').matches);
    setNavbarHeight();
    if (!isTerminalVertical()) {
      showHorizontalSection();
    }
  };

  handleScroll = () => {
    const { sectionsRef, selectSection, setNavbarHeight, navbarHeight } = this.props;
    const { displaySectionsNavBar } = this.state;
    const extraTop = 2;
    const needToDisplaySectionsNavBar =
      this.listSections && window.scrollY >= this.listSections.offsetTop - extraTop;
    if (needToDisplaySectionsNavBar && !displaySectionsNavBar) {
      this.setState({ displaySectionsNavBar: true });
    } else if (!needToDisplaySectionsNavBar && displaySectionsNavBar) {
      this.setState({ displaySectionsNavBar: false });
    }
    setNavbarHeight();

    let offsetTop = 0;
    let newSectionKey = null;
    const isBottomPage = window.innerHeight + window.scrollY >= document.body.offsetHeight;
    _.map(sectionsRef, (sectionRef, sectionKey) => {
      if (
        sectionRef &&
        (window.scrollY >= getOffsetTop(sectionRef) - parseInt(navbarHeight, 10) - extraTop ||
          isBottomPage) &&
        getOffsetTop(sectionRef) > offsetTop
      ) {
        offsetTop = getOffsetTop(sectionRef);
        newSectionKey = sectionKey;
      }
    });

    if (newSectionKey) {
      selectSection(newSectionKey);
    }
  };

  onTouchStart = () => {
    document.body.style.overflow = 'hidden';
  };

  onTouchEnd = () => {
    document.body.style.overflow = 'visible';
  };

  renderCloseBar = () => {
    const { closed, shopUnavailable } = this.props;
    const isShopClosed = closed || shopUnavailable;
    if (isStringNotNull(isShopClosed)) {
      return (
        <div className='row'>
          <div className='col text-center'>
            <div className='alert alert-danger mb-0' role='alert'>
              {getClosedMessage(isShopClosed)}
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    const {
      navbarHeight,
      isUserAnonymous,
      overlayCover,
      setNavbarHeight,
      isHorizontalSection,
      shopIsLoaded,
      marketplace
    } = this.props;
    const { listSectionClass, listProductClass } = getClasses();
    const { displaySectionsNavBar } = this.state;
    const displayNavBar = isHorizontalSection && (displaySectionsNavBar || isUserAnonymous);
    displayOrNotHeader(displaySectionsNavBar || isUserAnonymous);
    const displayOverlay = (overlayCover && isTerminal()) || overlayCover === BLOCK_CONTENT;
    let terminalMarginBottom = 0;
    if (document.getElementById('bottom-cart')) {
      terminalMarginBottom = document.getElementById('bottom-cart').scrollHeight;
    }
    const showSplash = (isSplash() && !shopIsLoaded) || (marketplace?.enabled && !shopIsLoaded);
    return (
      <>
        {showSplash && <Splash />}
        {!showSplash && (
          <>
            {displayOverlay && <CoverOverlay />}
            {!displayOverlay && (
              <div
                onClick={() => {
                  if (isTerminal()) {
                    resetTerminalTimeout();
                  }
                }}
                style={{ marginBottom: terminalMarginBottom }}
              >
                <div id='top' style={{ position: 'absolute', top: '0px', left: '0px' }} />
                {(!isUserAnonymous || isTerminalVertical()) && (
                  <div
                    ref = {setNavbarHeight}
                  >
                    <Cover />
                  </div>
                )}
                <div className='container-fluid px-0' id='main-container'>
                  {!isHorizontalSection && !isTerminalVertical() && (
                    <div
                      ref={el => {
                        this.listSections = el;
                        setNavbarHeight();
                      }}
                    >
                      <div
                        id='info-bar'
                        className={`shadow ${
                          displaySectionsNavBar || isUserAnonymous ? 'fixed-top' : ''
                        }`}
                      >
                        {shopIsLoaded && this.renderCloseBar()}
                        <InfoBar />
                      </div>
                    </div>
                  )}
                  {isHorizontalSection && (
                    <div
                      ref={el => {
                        this.listSections = el;
                        setNavbarHeight();
                      }}
                    >
                      <div
                        id='info-bar'
                        className={`shadow ${displayNavBar ? 'fixed-top' : ''}`}
                        onTouchStart={this.onTouchStart}
                        onTouchEnd={this.onTouchEnd}
                      >
                        {shopIsLoaded && this.renderCloseBar()}
                        <InfoBar />
                        <ListSection />
                      </div>
                    </div>
                  )}
                  <div className={`col-12 ${!isTerminalVertical() ? 'px-0 px-sm-5' : 'pl-3 pr-4'}`}>
                    <div className={`row ${!isTerminalVertical() && 'px-3 px-sm-0 px-md-3'}`}>
                      {!isHorizontalSection && (
                        <div
                          className={listSectionClass}
                          style={{
                            marginTop:
                              displaySectionsNavBar || isUserAnonymous ? navbarHeight : '16px'
                          }}
                        >
                          <ListSection />
                        </div>
                      )}
                      <div
                        className={listProductClass}
                        style={{
                          marginTop:
                            displaySectionsNavBar || isUserAnonymous ? navbarHeight : '16px'
                        }}
                      >
                        <ListProduct />
                      </div>
                    </div>
                  </div>
                </div>
                {!isTerminal() && <Footer />}
                {isTerminalVertical() && <BottomCart />}
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  componentReducer,
  sectionReducer,
  userReducer,
  pendingOrderReducer,
  shopReducer,
  configurationReducer,
  addressModal
}) => {
  const { sectionKey, sectionsRef, selectedSection } = sectionReducer;
  const { isUserAnonymous, userConnected, user } = userReducer;
  const {
    commande,
    address: customerAddress,
    orderType,
    addressIsLoaded,
    shopId
  } = pendingOrderReducer;
  const {
    splash,
    navbarHeight,
    isMobile,
    overlayCover,
    isHorizontalSection,
    googleMapsEnabled,
    connexionModal,
    customerAddressCheck
  } = componentReducer;
  const {
    sectionOrientation,
    secondaryColor,
    webapp,
    marketplace,
    servicePrice = {}
  } = configurationReducer;
  const { networkFees } = servicePrice;
  const {
    closed,
    sections,
    categories,
    showClosedShop,
    shopIsLoaded,
    shops,
    shopUnavailable,
  } = shopReducer;
  return {
    selectedSection,
    secondaryColor,
    sectionKey,
    customerAddress,
    networkFees,
    splash,
    commande,
    shopId,
    closed,
    sectionOrientation,
    sectionsRef,
    sections,
    categories,
    navbarHeight,
    isMobile,
    isUserAnonymous,
    overlayCover,
    isHorizontalSection,
    googleMapsEnabled,
    addressIsLoaded,
    userConnected,
    addressModal,
    showClosedShop,
    shopIsLoaded,
    webapp,
    marketplace,
    shops,
    shopUnavailable,
    connexionModal,
    customerId: user?.customerId,
    orderType,
    customerAddressCheck
  };
};

export default connect(mapStateToProps, {
  updateShop,
  selectSection,
  setIsMobile,
  setNavbarHeight,
  showHorizontalSection,
  showAddressModal,
  updateShopIsLoaded,
  showMessageModal
})(withRouter(Home));
