import apiService from '@dishopsaas/dishop-backend-api-service'
import _ from 'lodash'
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from 'react-redux'
import { sendGoogleAnalytics } from '../../../api'
import { ITEM_KEY_COUNTER_SEPARATOR } from '../../../constants/category'
import {
  BLACK,
  COLOR_LIGHT_GRAY,
  COLOR_MID_GRAY,
  DARK_COLOR,
  MODAL_SCROLL_OFFSET,
  PRIMARY,
  SECONDARY,
  UNIT,
  WHITE
} from '../../../constants/configuration'
import { addToCommande, showCartModal, showMessageModal } from '../../../redux/actions'
import {
  convertHoursToDays,
  displayPriceFormat,
  generateCounterOptionKey,
  getNbInCart,
  getProductId,
  getProductUnits,
  isChatbot,
  isDarkTheme,
  isStringNotNull,
  isTerminal,
  isTerminalVertical,
  roundNumber,
  sendCloudWatchAlert
} from '../../../utils'
import { removeUndefined } from '../../../utils/dataType'
import Button from '../../Button'
import Category from '../../Category'
import { isCheckedOrSelected } from '../../Category/Category.services.js'
import CloseButton from '../../CloseButton'
import ModalBorder from '../../ModalBorder'
import Spinner from '../../Spinner'
import TextInputCounter from '../../TextInputCounter'
import {
  generateItemRecursiveKey,
  generateProductItem,
  getMinimumStockRemaining,
  getOptionsData,
  getProductData,
  handleNextOption,
  handlePreviousOption,
  handleRemovedItemSubCategories,
  isNextCategoryDisabled,
  sortProductItemsAccordingToSortedProductCategories,
  updateSubCategories
} from './ProductModal.services'
import styles from './ProductModal.styles'

const { modalOptionContainer } = styles(COLOR_MID_GRAY)

class ProductModal extends React.Component {
  constructor(props) {
    super(props)
    const { product, categories, shopCategories } = props
    const {
      unit,
      productCategories,
      mandatoryCategories,
      shopCategories: updatedShopCategories
    } = getProductData(
      shopCategories,
      product,
      categories
    )
    this.INITIAL_STATE = {
      nb: unit ? 0 : 1,
      items: {},
      instruction: '',
      productCategories,
      mandatoryCategories,
      errorMessage: '',
      loading: false,
      productAdded: false,
      showOptionScrollButton: false,
      unit,
      stepOptionIndex: 0,
      itemsCounter: {},
      modalHeight: null,
      linkBetweenOptionsAndSubOptions: new Map(),
      shopCategories: updatedShopCategories
    }
    this.state = _.cloneDeep(this.INITIAL_STATE)
  }

  componentDidMount() {
    const { product, categories, shopCategories } = this.props;
    const {
      shopCategories: updatedShopCategories
    } = getProductData(
      shopCategories,
      product,
      categories
    )
    this.setState({ shopCategories: _.cloneDeep(updatedShopCategories) });
  }

  componentDidUpdate(prevProps) {
    const { modalHeight } = this.state;
    const { product, shopCategories } = this.props;
    if (!_.isEqual(product, prevProps.product) || !_.isEqual(shopCategories, prevProps.shopCategories)) {
      this.resetComponent(this.props, true);
    }
    if (
      !modalHeight &&
      document.getElementById('product-modal-body') &&
      document.getElementById('product-modal-border') &&
      document.getElementById('product-modal-body').offsetHeight >
      document.getElementById('product-modal-border').offsetHeight + MODAL_SCROLL_OFFSET
    ) {
      this.setState({
        showOptionScrollButton: true,
        modalHeight: document.getElementById('product-modal-body').offsetHeight
      });
    }
  }

  renderCategories = () => {
    const {
      productCategories,
      items,
      stepOptionIndex,
      unit,
      nb,
      loading,
      itemsCounter,
      shopCategories = {}
    } = this.state
    const {
      title,
      product,
      secondaryColor,
      disableProductInstruction
    } = this.props;
    const { stockRemaining, name: stockName } = getMinimumStockRemaining(this);
    const units = getProductUnits(product.unit);
    const categoriesLength = productCategories.length + 1;
    if (
      productCategories?.[stepOptionIndex] &&
      stepOptionIndex < productCategories?.length
    ) {
      const categoryId = productCategories[stepOptionIndex];
      const category = shopCategories[categoryId];
      if (category) {
        return (
          <div
            className='row col-12 mt-4 hide-scroll'
            key={stepOptionIndex}
            style={
              isTerminalVertical()
                ? { height: '700px', overflow: 'auto', ...modalOptionContainer }
                : modalOptionContainer
            }
          >
            <Category
              productTitle={title}
              product={product}
              nbProduct={nb}
              category={category}
              key={categoryId}
              categoryId={categoryId}
              itemsCounter={itemsCounter}
              productItems={items}
              categoryKey={categoryId}
              index={stepOptionIndex}
              addUniqueItem={(index, category, item) =>
                this.addUniqueItem(
                  index,
                  category,
                  item
                )
              }
              addItem={(index, category, item, count) => {
                this.addItem(
                  index,
                  category,
                  item,
                  count
                )
              }
              }
              removeItem={(index, category, item, count) =>
                this.removeItem(
                  index,
                  category,
                  item,
                  count
                )
              }
            />
          </div>
        );
      }
    }
    if (productCategories.length === stepOptionIndex) {
      return (
        <div
          className={`row col-12 mt-4 ${categoriesLength > 1 && 'p-4'}`}
          key={stepOptionIndex}
          style={
            productCategories.length === 0
              ? {}
              : isTerminalVertical()
                ? { height: '700px', overflow: 'auto', ...modalOptionContainer }
                : isDarkTheme()
                  ? {}
                  : modalOptionContainer
          }
        >
          <div
            className={`row col d-flex justify-content-center ${categoriesLength >
              1 &&
              isTerminalVertical() &&
              'pt-4'}`}
            style={{ display: 'flex' }}
          >
            {productCategories.length === 0 ? (
              <div />
            ) : (
              <div
                className={`col-12 align-self-center ${!isTerminalVertical() &&
                  'header-container-option'}`}
                style={isTerminalVertical() ? { fontSize: UNIT * 2.9 } : {}}
              >
                Combien en voulez-vous ?
              </div>
            )}

            {!unit ? (
              <div
                className={`${isTerminalVertical() &&
                  'd-flex align-self-start justify-content-center'} ${categoriesLength >
                  1 && 'pt-4'}
                  }`}
                style={
                  isTerminalVertical()
                    ? {}
                    : { display: 'flex', alignItems: 'center' }
                }
              >
                <Button
                  className={`no-outline ${nb > 0 ? 'label' : 'disabled-label'
                    }   ${isTerminalVertical() ? 'py-0' : 'p-0'}`}
                  onClick={this.removeOne}
                >
                  <i
                    className='fas fa-minus-circle mx-2'
                    style={{
                      fontSize: isTerminalVertical() ? 80 : 40
                    }}
                  />
                </Button>
                <p
                  className={`${isTerminalVertical()
                    ? 'd-flex align-items-center justify-content-center mx-4 lable'
                    : 'mx-2 label'
                    }`}
                  style={
                    isTerminalVertical()
                      ? {
                        fontSize: UNIT * 3.75
                      }
                      : { margin: '0px', fontSize: UNIT * 1.5 }
                  }
                >
                  {nb}
                </p>
                <Button
                  className={`no-outline ${isTerminalVertical() ? 'py-0' : 'p-0'
                    }`}
                  onClick={() => {
                    this.addOne();
                  }}
                  disabled={stockRemaining <= 0}
                >
                  <i
                    className='fas fa-plus-circle mx-2'
                    style={{
                      color:
                        stockRemaining <= 0 ? COLOR_LIGHT_GRAY : secondaryColor,
                      fontSize: isTerminalVertical() ? 80 : 40
                    }}
                  />
                </Button>
              </div>
            ) : (
              <form
                className={`needs-validation ${!isTerminalVertical() &&
                  'pt-4'}`}
              >
                <div
                  className='form-group'
                  style={{
                    display: 'flex',
                    width: 'fit-content',
                    margin: 'auto'
                  }}
                >
                  <div className='input-group mr-3'>
                    <input
                      type='number'
                      className='form-control'
                      style={{ width: '70px' }}
                      value={nb}
                      onChange={(e) => this.updateWeight(e)}
                      min={0}
                    />
                    <div className='input-group-append'>
                      {units && units.length > 1 && (
                        <>
                          <div
                            className='btn-group btn-group-toggle'
                            data-toggle='buttons'
                          >
                            {units.map((u, index) => {
                              return (
                                <label
                                  key={index}
                                  className={`btn btn-light border ${u.name === unit.name ? 'active' : ''
                                    }`}
                                  style={{
                                    borderRadius:
                                      index === units.length - 1
                                        ? '0px 4px 4px 0px'
                                        : '0px'
                                  }}
                                  onClick={() => this.setState({ unit: u })}
                                >
                                  <input
                                    type='radio'
                                    name='options'
                                    id={index}
                                    autoComplete='off'
                                    checked={u.name === unit.name}
                                  />
                                  {u.name}
                                </label>
                              );
                            })}
                          </div>
                        </>
                      )}
                      {units && units.length === 1 && (
                        <span
                          className='input-group-text'
                          style={{ fontSize: UNIT, margin: 'auto' }}
                        >
                          {units[0].name}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            )}
            <div className='col-12'>
              {stockRemaining > 0 && stockRemaining <= 5 && !loading && (
                <p
                  className='text-warning pt-4 font-italic label'
                  style={isTerminalVertical() ? { fontSize: UNIT * 1.5 } : {}}
                >
                  - Plus que {stockRemaining}{' '}
                  <span className='no-localization'>
                    {unit ? unit.name : ''}
                  </span>{' '}
                  {stockName} en stock -
                </p>
              )}
              {stockRemaining <= 0 && !loading && (
                <p
                  className='text-danger pt-4 font-italic mb-0 label'
                  style={isTerminalVertical() ? { fontSize: UNIT * 1.5 } : {}}
                >
                  - Plus de {stockName} en stock -
                </p>
              )}
            </div>
            {categoriesLength > 1 &&
              stepOptionIndex + 1 === categoriesLength &&
              this.renderAddToCartButton()}
            {!isChatbot() && (
              <div className='row col'>
                {!isTerminal() &&
                  !disableProductInstruction &&
                  this.renderSpecialInstructions()}
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  renderOptionScrollButton = () => {
    const { secondaryColor } = this.props;
    const { showOptionScrollButton } = this.state;
    if (showOptionScrollButton) {
      return (
        <i
          className='fas fa-arrow-circle-down shadow-lg'
          onClick={() => {
            document
              .getElementById('optionContainer')
              .scrollIntoView({ top: 0, behavior: 'smooth' });
            this.setState({ showOptionScrollButton: false });
          }}
          style={{
            fontSize: '30px',
            position: 'absolute',
            zIndex: 10,
            top: '-50px',
            color: secondaryColor,
            backgroundColor: COLOR_MID_GRAY,
            borderRadius: UNIT,
            cursor: 'pointer'
          }}
        />
      );
    }
  };

  renderCategorySteps = () => {
    const { stepOptionIndex, productCategories } = this.state;
    const { secondaryColor } = this.props;
    const categoriesLength = productCategories.length + 1;
    if (categoriesLength > 1) {
      return (
        <div className='col-12 d-flex justify-content-center'>
          <Button
            className='no-outline py-0'
            disabled={stepOptionIndex === 0}
            onClick={() => {
              handlePreviousOption(this);
              this.setState({ showOptionScrollButton: false });
            }}
          >
            <a
              href='#optionContainer'
              style={{
                pointerEvents: isTerminalVertical() && 'none'
              }}
            >
              <i
                className={`fas fa-chevron-left mx-2 ${isTerminalVertical() ? 'fa-3x' : 'fa-2x'
                  } `}
                style={{
                  color:
                    stepOptionIndex === 0
                      ? isDarkTheme()
                        ? DARK_COLOR
                        : WHITE
                      : isDarkTheme()
                        ? WHITE
                        : BLACK
                }}
              />
            </a>
          </Button>
          <p
            className='label'
            style={{
              margin: '0px',
              fontSize: isTerminalVertical() ? UNIT * 1.875 : UNIT * 1.375
            }}
          >
            {`${stepOptionIndex + 1} / ${categoriesLength}`}
          </p>
          <Button
            className='no-outline py-0'
            disabled={isNextCategoryDisabled(this)}
            onClick={() => {
              handleNextOption(this);
              this.setState({ showOptionScrollButton: false });
            }}
          >
            <a
              href='#optionContainer'
              style={{
                pointerEvents:
                  (isNextCategoryDisabled(this) || isTerminalVertical()) &&
                  'none'
              }}
            >
              <i
                className={`fas fa-chevron-right mx-2 ${isTerminalVertical() ? 'fa-3x' : 'fa-2x'
                  }`}
                style={{
                  color: isNextCategoryDisabled(this)
                    ? isNextCategoryDisabled(this) === 'lastItem'
                      ? isDarkTheme()
                        ? DARK_COLOR
                        : WHITE
                      : COLOR_LIGHT_GRAY
                    : secondaryColor
                }}
              />
            </a>
          </Button>
          {!isTerminalVertical() && this.renderOptionScrollButton()}
        </div>
      );
    }
  };

  async addItem(
    index,
    category,
    item,
    count
  ) {
    let {
      itemsCounter: updatedItemsCounter = {},
      items: updatedItems = {},
      shopCategories: updatedShopCategories,
      productCategories: updatedProductCategories,
      mandatoryCategories: updatedMandatoryCategories,
      linkBetweenOptionsAndSubOptions: updatedLinkBetweenOptionsAndSubOptions
    } = _.cloneDeep(this.state);
    let counter;
    if (count) {
      const optionKey = generateCounterOptionKey(category.key, item.key);
      counter = updatedItemsCounter[optionKey];
      counter = counter ? counter + 1 : 1;
      updatedItemsCounter[optionKey] = counter;
    }
    const itemRecursiveKey = generateItemRecursiveKey(item, counter);
    if (item?.subcategories?.length) {
      const subCategoryUpdateResult = updateSubCategories(
        updatedItems,
        updatedShopCategories,
        updatedProductCategories,
        updatedMandatoryCategories,
        updatedLinkBetweenOptionsAndSubOptions,
        item,
        itemRecursiveKey,
        category.multiple
      );
      ({
        items: updatedItems,
        shopCategories: updatedShopCategories,
        productCategories: updatedProductCategories,
        mandatoryCategories: updatedMandatoryCategories,
        linkBetweenOptionsAndSubOptions: updatedLinkBetweenOptionsAndSubOptions,
      } = subCategoryUpdateResult);
    }
    updatedItems[index] = generateProductItem(
      category,
      index,
      updatedItems,
      itemRecursiveKey,
      item
    );
    this.setState({
      items: sortProductItemsAccordingToSortedProductCategories(updatedProductCategories, updatedItems),
      shopCategories: updatedShopCategories,
      productCategories: updatedProductCategories,
      mandatoryCategories: updatedMandatoryCategories,
      linkBetweenOptionsAndSubOptions: updatedLinkBetweenOptionsAndSubOptions,
      ...(counter ? { itemsCounter: updatedItemsCounter } : {})
    })
  }
  addUniqueItem(index, category, item) {
    let {
      itemsCounter: updatedItemsCounter = {},
      items: updatedItems = {},
      shopCategories: updatedShopCategories,
      productCategories: updatedProductCategories,
      mandatoryCategories: updatedMandatoryCategories,
      linkBetweenOptionsAndSubOptions: updatedLinkBetweenOptionsAndSubOptions
    } = _.cloneDeep(this.state);
    const itemRecursiveKey = generateItemRecursiveKey(item);
    const isSelected = isCheckedOrSelected(updatedItems, index, item);
    if (isSelected) {
      return;
    }
    const subCategoryUpdateResult = updateSubCategories(
      updatedItems,
      updatedShopCategories,
      updatedProductCategories,
      updatedMandatoryCategories,
      updatedLinkBetweenOptionsAndSubOptions,
      item,
      itemRecursiveKey,
      category.multiple
    );
    ({
      items: updatedItems,
      shopCategories: updatedShopCategories,
      productCategories: updatedProductCategories,
      mandatoryCategories: updatedMandatoryCategories,
      linkBetweenOptionsAndSubOptions: updatedLinkBetweenOptionsAndSubOptions,
    } = subCategoryUpdateResult);
    if (subCategoryUpdateResult?.productItemToRemove) {
      const itemsToRemove = subCategoryUpdateResult.productItemToRemove?.options
        ? Object.values(subCategoryUpdateResult.productItemToRemove?.options)
        : [];
      for (const item of itemsToRemove) {
        const result = handleRemovedItemSubCategories(
          item,
          updatedItems,
          updatedItemsCounter,
          item?.itemRecursiveKey,
          true,
          updatedLinkBetweenOptionsAndSubOptions,
          updatedShopCategories,
          updatedProductCategories
        );
        ({
          productCategories: updatedProductCategories,
          shopCategories: updatedShopCategories,
          mandatoryCategories: updatedMandatoryCategories,
          items: updatedItems
        } = result);
      }
    }
    updatedItems[index] = generateProductItem(
      category,
      index,
      updatedItems,
      itemRecursiveKey,
      item
    );
    const reorderedProductItemsAccordingToProductCategories = sortProductItemsAccordingToSortedProductCategories(updatedProductCategories, updatedItems)
    this.setState({
      items: reorderedProductItemsAccordingToProductCategories,
      shopCategories: updatedShopCategories,
      productCategories: updatedProductCategories,
      mandatoryCategories: updatedMandatoryCategories,
      linkBetweenOptionsAndSubOptions: updatedLinkBetweenOptionsAndSubOptions,
      itemsCounter: updatedItemsCounter
    });
  }

  removeItem(index, category, item, count) {
    let {
      items: updatedItems = {},
      itemsCounter: updatedItemsCounter = {},
      linkBetweenOptionsAndSubOptions,
      productCategories,
      shopCategories,
      mandatoryCategories
    } = _.cloneDeep(this.state);
    const optionKey = generateCounterOptionKey(category.key, item.key);
    let counter = count
      ? updatedItemsCounter[optionKey]
      : null;
    const itemRecursiveKey = generateItemRecursiveKey(item, counter);
    const newProductItem = updatedItems[index];
    const result = handleRemovedItemSubCategories(
      item,
      updatedItems,
      updatedItemsCounter,
      itemRecursiveKey,
      false,
      linkBetweenOptionsAndSubOptions,
      shopCategories,
      productCategories
    );
    let updateItemsCounter = result?.shouldUpdateItemsCounter;
    ({
      productCategories,
      shopCategories,
      mandatoryCategories,
      items: updatedItems
    } = result);
    if (counter) {
      counter -= 1;
      updatedItemsCounter[optionKey] = counter;
      updateItemsCounter = true;
    }
    // remove option item
    const itemGeneratedId = _.findKey(updatedItems.options,
      (option) => itemRecursiveKey && option?.itemRecursiveKey === itemRecursiveKey
    );
    if (itemGeneratedId) {
      delete newProductItem?.options[itemGeneratedId];
      if (newProductItem?.options && _.keys(newProductItem?.options).length === 0) {
        // remove product item if no options
        delete updatedItems[index];
      } else {
        updatedItems[index] = newProductItem;
      }
    }
    const reorderedNewProductItems = sortProductItemsAccordingToSortedProductCategories(
      productCategories,
      updatedItems,
      null
    );
    this.setState({
      items: reorderedNewProductItems,
      linkBetweenOptionsAndSubOptions,
      shopCategories,
      productCategories,
      mandatoryCategories,
      ...(updateItemsCounter ? { itemsCounter: updatedItemsCounter } : {})
    })
  }

  renderSpecialInstructions = () => {
    const { instruction, productCategories } = this.state;

    return (
      <div className='col-12 pt-4 dark-modal'>
        <div
          className={`pb-2 dark-modal ${productCategories.length === 0 ? 'bg-white' : 'bg-light'
            }`}
          style={{ fontSize: UNIT * 0.875 }}
        >
          Instructions spéciales :
        </div>
        <div className='form-group mb-0'>
          <TextInputCounter
            className='form-control text-center font-italic'
            placeholder='Entrez vos instructions'
            style={{
              margin: 'auto',
              width: '90%',
              resize: 'none',
              fontSize: UNIT * 0.625,
              borderRadius: UNIT / 2,
              backgroundColor: isDarkTheme() && '#202020'
            }}
            counterStyle={{
              position: 'absolute',
              right: '25px',
              bottom: '15px',
              fontSize: UNIT * 0.75
            }}
            value={instruction}
            onChange={(e) => {
              this.setState({ instruction: e.target.value });
            }}
          />
        </div>
      </div>
    );
  };

  updateWeight = (e) => {
    const nb = e.target.value;
    if (nb >= 0 || !isStringNotNull(nb)) {
      this.setState({ nb: e.target.value });
    }
  };

  addOne = () => {
    const { nb } = this.state;
    const result = getMinimumStockRemaining(this);
    if (_.isEmpty(result) || result.stockRemaining > 0) {
      const nbIncremented = nb + 1;
      this.setState({ nb: nbIncremented });
    }
  };

  removeOne = () => {
    const { nb } = this.state;
    const nbDecremented = nb === 0 ? 0 : nb - 1;
    this.setState({ nb: nbDecremented });
  };

  calculatePrice = () => {
    const { items, shopCategories } = this.state;
    const { price } = this.props;
    let itemsPrice = 0;
    _.map(items, (item) => {
      const optionFree = shopCategories[item?.categoryKey]?.optionFree;
      if (item) {
        const itemOptions = _.values(item.options);
        _.map(itemOptions, (option, index) => {
          if (!optionFree || index >= optionFree) {
            itemsPrice += parseFloat(option.price, 10);
          }

        });
      }
    });
    const productPrice = parseFloat(price, 10);
    return roundNumber(productPrice + itemsPrice);
  };

  renderFinalPrice = () => {
    const { nb, unit } = this.state;
    const productPrice = this.calculatePrice();
    let price = productPrice * nb;
    if (unit) {
      price /= unit.conversion;
    }
    return price;
  };

  addToCartClick = async () => {
    const { product } = this.props;
    const {
      shopCategories,
      items,
      mandatoryCategories,
      nb,
      productCategories,
      instruction,
      unit
    } = this.state;
    const { name, price, key, position } = product;
    try {
      const {
        addToCommande,
        sectionName,
        userConnected,
        sectionKey,
        productKey,
        customerId,
        isUserAnonymous,
        showMessageModal,
        shopId
      } = this.props;

      this.setState({ errorMessage: '', loading: true });

      for (let i = 0; i < mandatoryCategories.length; i += 1) {
        const key = mandatoryCategories[i];
        if (!items[key]) {
          const categoryId = productCategories[key];
          const categoryKey = categoryId.split(ITEM_KEY_COUNTER_SEPARATOR)[0];
          const category = shopCategories[categoryKey];
          if (category) {
            const errorMessage = `Vous devez sélectionner l'option : ${category.name}`;
            showMessageModal(errorMessage);
            this.setState({ loading: false });
          }
          return;
        }
      }
      const productId = getProductId(productKey, items, instruction);
      let cartNb = getNbInCart(productId) + Number(nb);
      let productPrice = this.calculatePrice() * cartNb;
      if (unit) {
        cartNb = Number(nb);
        productPrice = (this.calculatePrice() * cartNb) / unit.conversion;
      }
      const newProduct = { ...product };
      newProduct.items = getOptionsData(items);
      newProduct.nb = cartNb;
      newProduct.unit = unit ? unit.name : null;
      newProduct.sectionName = sectionName;
      newProduct.sectionKey = sectionKey;
      newProduct.productKey = productKey;
      newProduct.unitPrice = parseFloat(product.price);
      newProduct.price = productPrice;
      newProduct.shopId = shopId;
      if (!newProduct.boughtPrice) {
        newProduct.boughtPrice = 0;
      }
      if (instruction) {
        newProduct.instruction = instruction;
      }
      if ((userConnected || isUserAnonymous) && !isTerminal()) {
        removeUndefined(newProduct.items)
        await apiService.pendingOrdersUpsert([customerId, 'commande', productId], newProduct,
          { updateMode: 'replace' })
      } else {
        addToCommande(productId, newProduct);
      }
      setTimeout(() => {
        this.resetComponent(this.props);
      }, 1000);
      sendGoogleAnalytics('add_to_cart', {
        currency: 'EUR',
        items: [
          {
            id: key,
            name,
            price,
            position,
            category: sectionName,
            quantity: nb
          }
        ],
        value: productPrice
      });
    } catch (error) {
      sendCloudWatchAlert(
        `Error adding product ${key} with items ${JSON.stringify(
          items
        )} to commande ${error}`
      );
    }
  }

  resetComponent(props, notHide) {
    const { onHideModal, shopCategories, product, categories } = props;
    if (!notHide) {
      onHideModal();
    }
    const {
      unit,
      productCategories,
      mandatoryCategories,
      shopCategories: updatedShopCategories
    } = getProductData(
      shopCategories,
      product,
      categories
    );
    setTimeout(() => {
      this.setState({
        ...this.INITIAL_STATE,
        items: {},
        itemsCounter: {},
        productCategories,
        mandatoryCategories,
        unit,
        nb: unit ? 0 : 1,
        linkBetweenOptionsAndSubOptions: new Map(),
        shopCategories: updatedShopCategories
      })
    }, 200)
  }

  renderAddToCartButton = () => {
    const { secondaryColor, isMobile, closed, shopUnavailable } = this.props;
    const { loading, nb, productCategories, stepOptionIndex } = this.state;
    const categoriesLength = productCategories.length + 1;
    let disabled = false;
    const { stockRemaining } = getMinimumStockRemaining(this);
    const { addToCartVerticalTerminalStyle, addToCartCommandStyle } = styles(
      secondaryColor
    );
    const isShopClosed = closed || shopUnavailable;
    if (isShopClosed) {
      disabled = true;
    }
    if (
      nb === '' ||
      nb <= 0 ||
      stockRemaining < 0 ||
      stepOptionIndex < productCategories.length ||
      loading
    ) {
      disabled = true;
    }
    return (
      <>
        <div
          className={
            categoriesLength > 1
              ? `col-12 d-flex justify-content-center ${isTerminalVertical() ? 'align-self-start' : 'pt-4'
              }`
              : 'col-12 d-flex justify-content-center'
          }
        >
          <Button
            disabled={disabled}
            style={
              isTerminalVertical()
                ? addToCartVerticalTerminalStyle
                : addToCartCommandStyle
            }
            onClick={() => {
              this.addToCartClick();
            }}
          >
            {loading && (
              <div>
                <Spinner
                  color={WHITE}
                  size={`${!isTerminalVertical() && 'sm'}`}
                />
                <span className='sr-only'>Loading...</span>
              </div>
            )}
            {!loading && (
              <div>
                <span>
                  {isShopClosed
                    ? 'Indisponible'
                    : isMobile
                      ? 'Ajouter '
                      : 'Ajouter au panier '}
                </span>
                {!isShopClosed && (
                  <span> {displayPriceFormat(this.renderFinalPrice())} </span>
                )}
              </div>
            )}
          </Button>
        </div>
      </>
    )
  }

  renderModalBody = () => {
    const { image, title, description, product } = this.props;
    const { productAdded } = this.state;
    const { preparationTime } = product;
    if (productAdded) {
      return <p className='label'>{title} a bien été ajouté au panier.</p>;
    }
    return (
      <div className='text-center'>
        {isStringNotNull(image) && (
          <div
            className='mb-3 p-0'
            style={isTerminalVertical() ? { width: '80%', margin: 'auto' } : {}}
          >
            <img
              src={image}
              alt={title}
              className='img-fluid col-12 image-modal-product'
              style={
                isTerminalVertical()
                  ? { borderRadius: UNIT, width: '50%' }
                  : { borderRadius: UNIT }
              }
            />
          </div>
        )}
        {!isStringNotNull(image) && <div className='mt-4' />}
        <div className='px-sm-4 my-3'>
          <h2
            className={`text-break ${isTerminalVertical() && 'py-2'}`}
            id='product-modal-title'
          >
            {title}
          </h2>
          {isStringNotNull(description) && (
            <p className='text-secondary text-break line-break mb-1 label'>
              {description}
            </p>
          )}
          <div className='col-12'>
            {preparationTime > 0 && (
              <p className='text-warning font-weight-bold font-italic pt-3 label'>
                {`Pour votre information, ce produit nécessite au moins ${convertHoursToDays(preparationTime) > 0
                  ? `${convertHoursToDays(preparationTime)} jour(s)`
                  : `${preparationTime} heure(s)`
                  } de préparation`}
              </p>
            )}
          </div>
          {!isChatbot() && (
            <div>
              <div className='row' id='optionContainer'>
                {this.renderCategories()}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderModalFooter = () => {
    const { showCartModal } = this.props;
    const { productAdded, productCategories } = this.state;
    const categoriesLength = productCategories.length + 1;
    if (productAdded) {
      return (
        <>
          <Button
            type={PRIMARY}
            onClick={() => this.resetComponent(this.props)}
          >
            Continuer mes achats
          </Button>
          <Button
            type={SECONDARY}
            onClick={() => {
              this.resetComponent(this.props);
              showCartModal(true);
            }}
          >
            Accéder au panier
          </Button>
        </>
      );
    }
    return (
      <>
        {categoriesLength > 1
          ? this.renderCategorySteps()
          : this.renderAddToCartButton()}
      </>
    );
  };

  render() {
    const { showModal, secondaryColor } = this.props;
    return (
      <>
        <Modal
          show={showModal}
          onHide={() => this.resetComponent(this.props)}
          aria-labelledby='contained-modal-title-vcenter'
          scrollable
          centered
          dialogClassName={`${isTerminalVertical() &&
            'modal-terminal-vertical'}`}
        >
          <ModalBorder
            className={`hide-scroll ${!isTerminalVertical() &&
              'modal-size-fixed'}`}
            style={
              isTerminalVertical()
                ? {
                  overflow: 'auto',
                  maxHeight: '110vh'
                }
                : {}
            }
            color={`${isTerminalVertical() ? secondaryColor : COLOR_LIGHT_GRAY
              }`}
            id='product-modal-border'
            onScroll={() => {
              if (
                document.getElementById('product-modal-border').scrollTop <
                document.getElementById('product-modal-title').offsetHeight &&
                document.getElementById('product-modal-body').offsetHeight >
                document.getElementById('product-modal-border').offsetHeight +
                MODAL_SCROLL_OFFSET
              ) {
                this.setState({ showOptionScrollButton: true });
              } else {
                this.setState({ showOptionScrollButton: false });
              }
            }}
          >
            <div>
              <CloseButton
                closeStyle={{ right: UNIT * 0.5 }}
                onClick={() => {
                  this.resetComponent(this.props);
                }}
              />
              <Modal.Body className='p-3  dark-modal' id='product-modal-body'>
                {this.renderModalBody()}
              </Modal.Body>
              {!isChatbot() && (
                <Modal.Footer
                  className={` footer-color modal-sticky-footer  ${isTerminalVertical() ? 'row' : 'px-3'
                    }`}
                  style={{
                    height: isTerminalVertical() ? '100px' : '60px'
                  }}
                >
                  {this.renderModalFooter()}
                </Modal.Footer>
              )}
            </div>
          </ModalBorder>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({
  shopReducer,
  configurationReducer,
  userReducer,
  cartReducer,
  componentReducer
}) => {
  const { categories, shopId, closed, shopUnavailable } = shopReducer;
  const { isMobile } = componentReducer;
  const { secondaryColor, disableProductInstruction } = configurationReducer;
  const { customerAddress, userConnected, user, isUserAnonymous } = userReducer;
  return {
    shopCategories: categories,
    secondaryColor,
    customerAddress,
    userConnected,
    cartReducer,
    user,
    customerId: user.customerId,
    isUserAnonymous,
    isMobile,
    shopId,
    disableProductInstruction,
    closed,
    shopUnavailable
  };
};

export default connect(mapStateToProps, {
  addToCommande,
  showCartModal,
  showMessageModal
})(ProductModal);
