import { ORDER_TYPE_CLICK_AND_COLLECT, ORDER_TYPE_CLICK_AND_SEAT, ORDER_TYPE_DELIVERY } from '../../constants';
import { getAddressFormatted } from '../../utils';
import store from '../../redux/store';
import { ShopState } from '../../1_types/genericTypes';
import { isCustomerAddressDefined } from '../../utils/customer-address-and-shops';

const generateDisplayAddress = (address, nbCaract = 25) =>
    `${getAddressFormatted(address).substring(0, nbCaract)}${address.length > nbCaract ? '...' : ''}`;

export const getAddress = () => {
    const { address: customerAddress, orderType } = store.getState().pendingOrderReducer;
    const { addressShop } = store.getState().shopReducer as Partial<ShopState>;
    const nbCaract = 25;
    const EMPTY_ADDRESS = 'Aucune adresse';
    if (orderType === ORDER_TYPE_DELIVERY) {
        const customerAddressDefined = isCustomerAddressDefined(customerAddress)
        return customerAddressDefined
            ? generateDisplayAddress(customerAddress, nbCaract)
            : EMPTY_ADDRESS
    }
    return addressShop ?
        generateDisplayAddress(addressShop)
        : EMPTY_ADDRESS;
}

export const getOrderTypeLabel = (orderType, isMobile) => {
    switch (orderType) {
        case ORDER_TYPE_DELIVERY :
            return !isMobile ? 'Commande en Livraison' : 'En Livraison';
        case ORDER_TYPE_CLICK_AND_COLLECT :
            return !isMobile ? 'Commande à Emporter' : 'A Emporter';
        case ORDER_TYPE_CLICK_AND_SEAT :
            return !isMobile ? 'Commande sur Place' : 'Sur Place';
        default :
            return '';
    }
}
