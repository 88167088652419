import { SELECT_SECTION, GET_SECTIONS_REF } from '../actions/types';

const INITIAL_STATE = {
  selectedSection: null,
  sectionsRef: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_SECTION:
      return { ...state, selectedSection: action.payload };
    case GET_SECTIONS_REF:
      return {
        ...state,
        sectionsRef: {
          ...state.sectionsRef,
          [action.payload.sectionKey]: action.payload.sectionRef
        }
      };
    default:
      return state;
  }
};
