import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCart'
import UpdateIcon from '@mui/icons-material/Update'
import { Divider } from '@mui/material'
import '../../css/react-tabs.css'
import Spinner from '../Spinner'
import { TEXT_CANCEL_ORDER } from '../../constants/text'
import {
  CONTACTING_IMAGE_URL,
  COOKING_IMAGE_URL,
  MODIFY_ORDER_TYPE_PROMOTION,
  ORDER_FLOW_TYPE,
  ORDER_PHASE_CONTACTING,
  ORDER_PHASE_DELIVERING,
  ORDER_PHASE_PREPARING,
  ORDER_PHASE_TAKEAWAY,
  ORDER_PHASE_WAITER,
  ORDER_TYPE_DELIVERY,
  PAYMENT_TYPE_STRIPE,
  PICKUP_STATUS,
  PROMOTION_DELIVERY_FREE,
  SECONDARY,
  WAITER_IMAGE_URL
} from '../../constants'
import { getAddressFormatted, getDayFormat, getEstimationTime, getShopFromOrder, getStatus } from '../../utils'

import OrderSteps from '../OrderSteps'
import { showMessageModal } from '../../redux/actions'
import { cancelOrder, changeOrderType } from './OrderStatus.services'

import MapClient from '../MapClient'
import Order from '../Order'
import Button from '../Button'

const INITIAL_STATE = {
  estimationTime: '',
  isLoading: false,
  loadingChanginOrderType: false
};

class OrderStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  renderTabs = () => {
    const { chargeId, orders } = this.props;
    const order = orders[chargeId];
    return (
      <Tabs>
        <TabList>
          <Tab style={{ width: '45%' }}>
            <div className='row justify-content-center'>
              <UpdateIcon className='mr-2' />
              <p className='mb-0 d-none d-sm-block'>Statut</p>
            </div>
          </Tab>
          <Tab style={{ width: '45%' }}>
            <div className='row justify-content-center'>
              <ShoppingCartOutlinedIcon className='mr-2' />
              <p className='mb-0 d-none d-sm-block'>Commande</p>
            </div>
          </Tab>
        </TabList>

        <TabPanel>
          <div>
            {this.renderStatus()}
            {this.renderAddress()}
            {this.renderFooter()}
          </div>
        </TabPanel>
        <TabPanel>
          <div className='text-left' style={{ paddingTop: '16px', marginBottom: '16px' }}>
            <Order order={order} showCart showFees />
          </div>
        </TabPanel>
      </Tabs>
    );
  };

  renderAddress = () => {
    const { chargeId, orders } = this.props;
    const order = orders[chargeId];
    const { address, orderType, pickupStatus } = order;
    const shop = getShopFromOrder(order);
    const { address: addressShop } = shop;
    const isContactingEmergency = order?.status == ORDER_PHASE_CONTACTING && order?.selfPickupEligible && order.orderFlowType === ORDER_FLOW_TYPE.REVERSE && pickupStatus === PICKUP_STATUS.ORDER_NOT_CURRENTLY_SUPPORTED
    const isPreparingEmergency = order?.status == ORDER_PHASE_PREPARING && order?.selfPickupEligible && order.pickupStatus < PICKUP_STATUS.DRIVER_ON_THE_WAY_TO_RESTAURANT
    return (
      <div>
        <p className='text-left text-gray mb-2 label'>
          {orderType === ORDER_TYPE_DELIVERY && !isContactingEmergency && !isPreparingEmergency && (
             <p className='text-left  mb-2 label'> Adresse de livraison :<strong> {getAddressFormatted(address)}</strong></p>
          )}
          {orderType !== ORDER_TYPE_DELIVERY && (
             <p className='text-left mb-2 label'> Adresse du commerce :<strong> {getAddressFormatted(addressShop)}</strong></p>
          )}
        </p>
      </div>
    );
  };

  renderEmergencyDelivery = () => {
    const { chargeId, orders, secondaryColor, delivery } = this.props;
    const order = orders[chargeId];
    const { totalProductPriceOrder, userServicePrice, managementFee, promotion } = order;
    const deliveryFreeReduction = promotion?.type === PROMOTION_DELIVERY_FREE ? 0 : userServicePrice
    const reductionToClickAndCollect = Math.round(delivery.emergency.clickAndCollectConversionReduction * totalProductPriceOrder) / 100 + deliveryFreeReduction + managementFee
    return (reductionToClickAndCollect > 0 &&
      <div style={{ fontSize: 14 }} className='text-center'>
       <p className='text-gray mt-1'>

       Nous n'avons pas encore trouvé de livreur pour votre commande.</p>
       <p className='text-gray mb-2'>
       Si vous ne souhaitez pas attendre, vous pouvez choisir l'une des deux options suivantes :
       </p>
       <h5 className='my-2'>OPTION 1</h5>
       <p className='text-gray my-1'>
        Une <strong>réduction immédiate de {reductionToClickAndCollect.toFixed(2)} €</strong> si vous choisissez de venir chercher votre commande à <strong>Emporter</strong>
       </p>
       <Button
        type={SECONDARY}
        className='my-2'
        disabled={this.state.isLoading}
        isLoading={this.state.loadingChanginOrderType}
        style={{
          backgroundColor: secondaryColor,
          borderColor: secondaryColor
        }}
        onClick={ async() => await changeOrderType(this, order, MODIFY_ORDER_TYPE_PROMOTION.CLICK_AND_COLLECT)}
        >
       J'économise {reductionToClickAndCollect.toFixed(2)} € et je me déplace
        </Button>
        <h5 className='my-2' >OPTION 2</h5>
       <p className='text-gray my-1'>
       Un code promo de <strong>{delivery.emergency.refundReduction
        }%</strong> sur votre prochaine commande, si vous choisissez d'annuler votre commande
        </p>
       <Button
        type={SECONDARY}
        className='my-2'
        disabled={this.state.loadingChanginOrderType}
        isLoading={this.state.isLoading}
        style={{
          backgroundColor: 'gray',
          borderColor: 'gray'
        }}
        onClick={ async() => await changeOrderType(this, order, MODIFY_ORDER_TYPE_PROMOTION.REFUND)}
        >
            J'annule ma commande
        </Button>
     </div>
    );
  };

  renderContactingOrderDetails = () => {
    const { chargeId, orders } = this.props;
    const order = orders[chargeId];
    const { orderType, pickupStatus, orderFlowType } = order;
    return (
      <div>
      <p className='text-left mb-0 label'>{getStatus(order)}</p>
      {orderFlowType === ORDER_FLOW_TYPE.REVERSE && orderType === ORDER_TYPE_DELIVERY && pickupStatus === PICKUP_STATUS.ORDER_NOT_CURRENTLY_SUPPORTED ?
      <div className='my-3'>
      <MapClient chargeId={chargeId} />
      </div> :
      <img
        style={{ height: '160px', width: '240px', alignItems: 'center' }}
        className='img-fluid rounded mx-auto mb-4'
        alt='contacting gif'
        src={CONTACTING_IMAGE_URL}
      />
      }
      {this.renderEstimationTime()}
     </div>
    );
  }
  renderEstimationTime = () => {
    const { chargeId, orders } = this.props;
    const order = orders[chargeId];
    const { isSchedule, date, orderType } = order;
    const estimationTime = getEstimationTime(order);
    let estimationDay = '';
    if (isSchedule) {
      estimationDay = getDayFormat(date);
    }
    return <p className='text-left text-gray mb-2 label'>
           { orderType === ORDER_TYPE_DELIVERY ? 'Votre livraison' : `L'heure de récupération` } est prévue pour {estimationDay && 'le '} : <strong>{`${estimationDay} ${estimationTime}`}</strong>
        </p>

  }

  renderStatus = () => {
    const { chargeId, orders, delivery = {} } = this.props;
    const order = orders[chargeId];
    const { emergency } = delivery
    const { status, orderFlowType, pickupStatus, orderType } = order;
    if (!_.isEmpty(order)) {
      const orderFlowReverse = orderFlowType === ORDER_FLOW_TYPE.REVERSE
      const deliveryOrderNotSuppprted = orderType === ORDER_TYPE_DELIVERY && pickupStatus === PICKUP_STATUS.ORDER_NOT_CURRENTLY_SUPPORTED
      switch (status) {
        case ORDER_PHASE_CONTACTING: {
          const isContactingEmergency = orderFlowReverse && order?.selfPickupEligible && deliveryOrderNotSuppprted
          return (
            <>
              <OrderSteps order={order} activeStep={orderFlowReverse && pickupStatus === PICKUP_STATUS.DRIVER_ON_THE_WAY_TO_RESTAURANT ? '2' : '1'} />
              <div>
              {emergency?.enabled && emergency?.clickAndCollectConversionReduction && isContactingEmergency ?
               this.renderEmergencyDelivery() :
               this.renderContactingOrderDetails()
              }
             </div>
              </>
          );
        }
        case ORDER_PHASE_PREPARING: {
          const isPreparingEmergency = orderType === ORDER_TYPE_DELIVERY && order?.selfPickupEligible && order.pickupStatus < PICKUP_STATUS.DRIVER_ON_THE_WAY_TO_RESTAURANT
          return (
            <>
              <OrderSteps order={order} activeStep={orderFlowReverse && orderType === ORDER_TYPE_DELIVERY ? '3' : '2'} />
              {emergency?.enabled && emergency?.clickAndCollectConversionReduction && isPreparingEmergency ?
              this.renderEmergencyDelivery() :
              <div>
               <p className='text-left mb-0 label'>{getStatus(order)}</p>
               <img
                 style={{ height: '150px', width: '300px' }}
                 className='img-fluid rounded mx-auto d-block my-4'
                 alt='cooking gif'
                 src={COOKING_IMAGE_URL}
               />
               {this.renderEstimationTime()}
             </div>
             }
            </>
          );
        }
        case ORDER_PHASE_DELIVERING: {
          return (
            <>
              <OrderSteps order={order} activeStep={orderFlowReverse && orderType === ORDER_TYPE_DELIVERY ? '4' : '3'} />
              <p className='text-left label'>{getStatus(order)}</p>
              <div className='my-3'>
                <MapClient chargeId={chargeId} />
              </div>
              {this.renderEstimationTime()}
            </>
          );
        }
        case ORDER_PHASE_TAKEAWAY: {
          return (
            <>
              <OrderSteps order={order} activeStep='3' />
              <p className='text-left label'>{getStatus(order)}</p>
              <div className='my-3'>
                <MapClient chargeId={chargeId} />
              </div>
            </>
          );
        }
        case ORDER_PHASE_WAITER: {
          return (
            <>
              <OrderSteps order={order} activeStep='3' />
              <p className='text-left mb-0 label'>{getStatus(order)}</p>
              <img
                style={{ height: '300px', width: '300px' }}
                className='img-fluid rounded mx-auto my-4'
                alt='waiter gif'
                src={WAITER_IMAGE_URL}
              />
            </>
          );
        }
        default:
          return (
            <>
              <OrderSteps order={order} activeStep='1' />
              <img
                style={{ height: '160px', width: '240px' }}
                className='img-fluid rounded mx-auto my-4'
                alt='contacting gif'
                src={CONTACTING_IMAGE_URL}
              />
            </>
          );
      }
    }
  };

  renderFooter = () => {
    const {
      orders,
      chargeId,
      showMessageModal,
      secondaryColor,
      support,
      shops,
      disabledCancel,
      marketPlace
    } = this.props;
    const order = orders[chargeId];
    const { status, selfPickupEligible, userCancelServicePrice } = order;
    const marketPlaceEnabled = marketPlace?.enabled;
    let number = support?.number;
    if (marketPlaceEnabled) {
      const foundShop = _.find(shops, (shop, id) => id === order.shopId);
      number = foundShop?.support?.number;
    }
    const phone = order.numeroDep ? order.numeroDep : number;
    if (!_.isEmpty(order)) {
      switch (status) {
        case ORDER_PHASE_CONTACTING: {
          return (
            <>
              {!disabledCancel && !selfPickupEligible && (
                <div
                  className='button-bottom-modal'
                  onClick={() => {
                    showMessageModal(TEXT_CANCEL_ORDER, {
                      onClick: () => cancelOrder(this, order),
                      showCancel: true
                    });
                  }}
                >
                  <i className='fas fa-times-circle fa-2x' style={{ color: secondaryColor }} />
                  <p className='text-muted'>Annuler la commande</p>
                </div>
              )}
            </>
          );
        }
        case ORDER_PHASE_PREPARING: {
          return (
            <>
              {!disabledCancel && order.paymentType === PAYMENT_TYPE_STRIPE && (
                <div
                  className='button-bottom-modal'
                  onClick={() => {
                    showMessageModal(`Êtes-vous sûr(e) de vouloir annuler votre commande ?${userCancelServicePrice > 0
                  ? `\nVous serez facturé d'un montant de ${userCancelServicePrice}€.`
                  : ''
                  }`, {
                      onClick: () => cancelOrder(this, order),
                      showCancel: true
                    });
                  }}
                >
                  <i className='fas fa-times-circle fa-2x' style={{ color: secondaryColor }} />
                  <p className='text-muted'>Annuler la commande</p>
                </div>
              )}
            </>
          );
        }
        case ORDER_PHASE_DELIVERING: {
          return (
            <>
              <div className='button-bottom-modal' onClick={() => window.open(`tel:${phone}`)}>
                <p className='text-muted mb-0 label'>Contactez le livreur en cas de problème</p>
                <p className='text-muted'>{phone}</p>
              </div>
            </>
          );
        }
        default:
          break;
      }
    }
  };

  render() {
    const { orders, chargeId } = this.props;
    const order = orders[chargeId];
    if (!_.isEmpty(order)) {
      return (
        <div className='row'>
          <div className='container-fluid text-center'>{this.renderTabs()}</div>
        </div>
      );
    }
    return (
      <div className='row justify-content-center'>
        <Spinner />
      </div>
    );
  }
}

const mapStateToProps = ({ configurationReducer, shopReducer, orderReducer }) => {
  const { support, shops } = shopReducer;
  const { secondaryColor, name, cancel = {}, marketPlace, delivery } = configurationReducer;
  const { orders } = orderReducer;
  const { disabled: disabledCancel } = cancel;
  return {
    secondaryColor,
    delivery,
    support,
    shops,
    name,
    orders,
    disabledCancel,
    marketPlace
  };
};

export default connect(mapStateToProps, { showMessageModal })(OrderStatus);