import { formatPromotionDate } from './date';

const parseDefaultError = error => {
  const defaultError = 'La connexion a été perdue. Veuillez réessayer.';
  if (error) {
    return error;
  }
  return defaultError;
};

export const errorShopUnavailable = shopName => {
  return `Désolé, ${shopName} ne livre pas dans cette zone actuellement.`;
};

export const renderErrorMessage = (error, param = null) => {
  let errorMessage = '';
  switch (error) {
    case 'auth/no-user-data':
      errorMessage =
        'Aucune donnée n\'est associée à ce compte.\n Veuillez réessayer avec un email différent.';
      break;
    case 'auth/error-signup':
      errorMessage =
        'La création de votre compte a échoué. Veuillez vérifier les informations saisies.';
      break;
    case 'auth/invalid-email':
      errorMessage = 'Votre email est incorrect ! Veuillez réessayer';
      break;
    case 'auth/email-already-in-use':
      errorMessage = 'Un compte est déjà associé à cet email \n! Veuillez réessayer';
      break;
    case 'auth/weak-password':
      errorMessage = 'Votre mot de passe doit contenir au moins 6 caractères ! Veuillez réessayer';
      break;
    case 'auth/number-phone-empty':
      errorMessage = 'Vous devez entrer un numéro de téléphone !\n Veuillez réessayer';
      break;
    case 'auth/email-empty':
      errorMessage = 'Vous devez entrer un email !\n Veuillez réessayer';
      break;
    case 'auth/password-empty':
      errorMessage = 'Vous devez entrer un mot de passe !\n Veuillez réessayer';
      break;
    case 'auth/firstname-empty':
      errorMessage = 'Vous devez entrer votre prénom ! Veuillez réessayer';
      break;
    case 'auth/lastname-empty':
      errorMessage = 'Vous devez entrer votre nom de famille ! Veuillez réessayer';
      break;
    case 'auth/user-disabled':
      errorMessage =
        'Votre compte a été supprimé ! Veuillez contacter le support pour plus d\'informations';
      break;
    case 'auth/user-not-found':
      errorMessage = 'Votre compte n\'existe pas !\n Inscrivez-vous pour ouvrir un compte';
      break;
    case 'auth/wrong-password':
      errorMessage = 'Votre mot de passe est incorrect ! Veuillez réessayer';
      break;
    case 'auth/wrong-birth-date':
      errorMessage = 'Veuillez rentrer votre date de naissance sous le format jj/mm/aaaa !';
      break;
    case 'auth/facebook_error':
      errorMessage = 'Nous n\'avons pas pu vous connecter via Facebook! Veuillez réessayer';
      break;
    case '11':
      errorMessage =
        'L\'authentification a échoué! Veuillez réessayer ou rafraichir la page si cela ne fonctionne toujours pas.';
      break;
    case 'auth/facebook_cancel':
      errorMessage = 'La connexion via Facebook a été annulée! Veuillez réessayer';
      break;
    case 'incorrect_number':
      errorMessage = 'Le numéro de votre carte bancaire est incorrect! Veuillez réessayer';
      break;
    case 'expired_card':
      errorMessage = 'Votre carte bancaire a expiré ! Veuillez réessayer';
      break;
    case 'invalid_expiry_year':
      errorMessage = 'Votre carte bancaire est expiré !';
      break;
    case 'incorrect_cvc':
      errorMessage = 'Le numéro CVC est incorrect! Veuillez réessayer';
      break;
    case 'card_declined':
      errorMessage =
        'Votre carte bancaire est incorrecte ou bien ne possède pas les fonds nécessaires pour effectuer votre commande! Veuillez modifier vos informations de carte bancaire ou contacter votre banque pour plus d\'informations.';
      break;
    case 'parameter_missing':
      errorMessage = 'Votre carte bancaire est incorrecte! Veuillez réessayer.';
      break;
    case 'resource_missing':
      errorMessage = 'Votre carte bancaire est manquante! Veuillez réessayer.';
      break;
    case 'auth/agreement-unchecked':
      errorMessage =
        'Vous devez accepter nos Conditions générales d\'utilisation pour pouvoir continuer.';
      break;
    case 'auth/under-eighteen':
      errorMessage = 'Vous devez avoir plus de 18 ans.';
      break;
    case 'auth/wrong-number-phone':
      errorMessage = 'Votre numéro de téléphone est incorrect.';
      break;
    case 'auth/password-not-match':
      errorMessage = 'Votre mot de passe doit être identique ! Veuillez réessayer.';
      break;
    case 'auth/too-many-requests':
      errorMessage = 'Vous avez effectué trop de tentatives ! Veuillez réessayer plus tard.';
      break;
    case 'address/wrong-address':
      errorMessage = 'Entrez une adresse de livraison valide.';
      break;
    case 'CANT_GEOCODE_ADDRESS':
      errorMessage = 'Entrez une adresse de livraison valide.';
      break;
    case 'OUT_OF_RANGE':
      errorMessage = 'Entrez une adresse de livraison valide.';
      break;
    case 'ADDRESS_CONTACT_PHONE_REQUIRED':
      errorMessage = 'Votre adresse n\'est pas assez précise.';
      break;
    case 'address/error-address':
      errorMessage = 'Nous n\'avons pas pu ajouter votre adresse. Veuillez réessayer.';
      break;
    case 'promotion/promotion-pending':
      errorMessage = 'Vous avez déjà un code promotionnel en cours';
      break;
    case 'promotion/promotion-expired':
      errorMessage = 'Ce code promotionnel n\'existe pas ou a expiré';
      break;
    case 'promotion/loyalty-invalid':
      errorMessage = 'Pas assez de points pour obtenir vos points de fidélités';
      break;
    case 'promotion/promotion-already-used':
      errorMessage = 'Vous avez déjà utilisé ce code promotionnel.';
      break;
    case 'promotion/canal-not-authorized':
      errorMessage = 'Ce code promotionnel n\'est pas autorisé sur cette application.';
      break;
    case 'promotion/ordertype-not-authorized':
      errorMessage = 'Ce code promotionnel n\'est pas autorisé pour ce mode de vente.';
      break;
    case 'promotion/promotion-available-later/startedDate': {
      const date = formatPromotionDate(param?.startedDate);
      return errorMessage = `Soyez patient ce code promotionnel sera disponible à partir du ${date}`;
    }
    case 'promotion/promotion-expired/endedDate': {
      const date = formatPromotionDate(param?.endedDate);
      return errorMessage = `Malheureusement ce code promotionnel n'est plus disponible depuis le ${date}`;
    }
    case 'stuart/phone-invalid':
      errorMessage =
        'Nous n\'avons pas pu lancer votre livraison car votre numéro de téléphone est invalide.';
      break;
    case 'stuart/unavailable':
      errorMessage =
        'Notre service de livraison est indisponible pour le moment. Veuillez réessayer plus tard.';
      break;
    case 'stuart/address-not-served':
      errorMessage = 'Notre service de livraison ne dessert pas votre adresse.';
      break;
    case 'stuart/job-already-pickedup':
      errorMessage = 'Veuillez contacter le livreur pour pouvoir annuler votre commande.';
      break;
    case 'auth/error-updateInfo':
      errorMessage =
        'La modification de votre compte a échoué. Veuillez vérifier les informations saisies.';
      break;
    case 'orders/limit':
      errorMessage =
        'Le nombre maximum de commandes pour ce créneau a été atteint. Veuillez choisir un créneau différent';
      break;
    default:
      errorMessage = parseDefaultError(error);
      break;
  }
  return errorMessage;
};

