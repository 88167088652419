export const loadLocalizeSdk = () => {
  const script = document.createElement('script');
  script.setAttribute('async', 'true');
  script.setAttribute('src', `https://global.localizecdn.com/localize.js`);
  document.head.appendChild(script);
};

export const initializeLocalize = localize => {
  const { enabled } = localize;
  if (enabled) {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    let code =
      '!function(a){if(!a.Localize){a.Localize={};for(var e=["translate","untranslate","phrase","initialize","translatePage","setLanguage","getLanguage","detectLanguage","getAvailableLanguages","untranslatePage","bootstrap","prefetch","on","off","hideWidget","showWidget","getSourceLanguage"],t=0;t<e.length;t++)a.Localize[e[t]]=function(){}}}(window);';
    try {
      script.appendChild(document.createTextNode(code));
      document.body.appendChild(script);
    } catch (e) {
      script.text = code;
      document.body.appendChild(script);
    }
    script = document.createElement('script');
    script.type = 'text/javascript';
    code =
      'Localize.initialize({ key: "hGvwKhAZ39Qss", rememberLanguage: true, disableWidget: true, autoApprove: true });';
    try {
      script.appendChild(document.createTextNode(code));
      document.body.appendChild(script);
    } catch (e) {
      script.text = code;
      document.body.appendChild(script);
    }
  }
};
