export const FETCH_SECTIONS = 'FETCH_SECTIONS';
export const SELECT_SECTION = 'SELECT_SECTION';
export const GET_SECTIONS_REF = 'GET_SECTION_REF';
export const SHOW_PRODUCTS = 'SHOW_PRODUCTS';
export const FETCH_ADDRESS = 'FETCH_ADDRESS';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const UPDATE_ADDRESS_ATTRIBUTE = 'UPDATE_ADDRESS_ATTRIBUTE';
export const UPDATE_ADDRES_IS_LOADED = 'UPDATE_ADDRES_IS_LOADED';
export const FETCH_HOURS = 'FETCH_HOURS';
export const FETCH_SUPPORT = 'FETCH_SUPPORT';
export const FETCH_CONFIGURATION = 'FETCH_CONFIGURATION';
export const UPDATE_LOYALTY = 'UPDATE_LOYALTY'
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const USER_CONNECTION = 'USER_CONNECTION';
export const USER_CONNECTION_ERROR = 'USER_CONNECTION_ERROR';
export const USER_SIGNOUT = 'USER_SIGNOUT';
export const USER_SIGNUP_ERROR = 'USER_SIGNUP_ERROR';
export const USER_START_CONNECTION = 'USER_START_CONNECTION';
export const USER_START_RESET_PASSWORD = 'USER_START_RESET_PASSWORD';
export const RESET_LOGIN = 'RESET_LOGIN';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const USER_START_UPDATE_INFO = 'USER_START_UPDATE_INFO';

export const ADD_TO_COMMANDE = 'ADD_TO_COMMANDE';
export const UPDATE_PENDING_ORDER = 'UPDATE_PENDING_ORDER';
export const REMOVE_TO_COMMANDE = 'REMOVE_TO_COMMANDE';
export const UPDATE_COMMANDE = 'UPDATE_COMMANDE';
export const RESET_COMMANDE = 'RESET_COMMANDE';

export const UPDATE_SHOP_ORDERS = 'UPDATE_SHOP_ORDERS';
export const UPDATE_SHOP = 'UPDATE_SHOP';
export const UPDATE_SHOPS = 'UPDATE_SHOPS';
export const UPDATE_CLOSEST_SHOPS = 'UPDATE_CLOSEST_SHOPS';
export const UPDATE_SHOP_IS_LOADED = 'UPDATE_SHOP_IS_LOADED';

export const UPDATE_LAST_ORDER_CHARGE_IDS = 'UPDATE_LAST_ORDER_CHARGE_IDS';

export const UPDATE_ORDERS = 'UPDATE_ORDERS';
export const UPDATE_ORDER_TIME = 'UPDATE_ORDER_TIME';
export const UPDATE_PAST_ORDERS = 'UPDATE_PAST_ORDERS';
export const UPDATE_CANCELED_ORDERS = 'UPDATE_CANCELED_ORDERS';
export const RESET_ORDER = 'RESET_ORDER';
export const UPDATE_EASEL_NUMBER = 'UPDATE_EASEL_NUMBER';

export const UPDATE_DRIVERS_DATA = 'UPDATE_DRIVERS_DATA';

export const MESSAGE_MODAL = 'MESSAGE_MODAL';
export const IS_GOOGLE_MAPS_ENABLED = 'IS_GOOGLE_MAPS_ENABLED';
export const SET_TERMINAL_TIMEOUT = 'SET_TERMINAL_TIMEOUT';
export const SHOW_PAYMENT_MODAL = 'SHOW_PAYMENT_MODAL';
export const SHOW_CART_MODAL = 'SHOW_CART_MODAL';
export const SHOW_ADDRESS_MODAL = 'SHOW_ADDRESS_MODAL';
export const SHOW_CHOOSE_SHOP = 'SHOW_CHOOSE_SHOP';
export const SHOW_PRODUCT_MODAL = 'SHOW_PRODUCT_MODAL';
export const SHOW_HOURS_MODAL = 'SHOW_HOURS_MODAL';
export const SHOW_SPLASH = 'SHOW_SPLASH';
export const SHOW_CONNEXION_MODAL = 'SHOW_CONNEXION_MODAL';
export const SHOW_SIGNUP = 'SHOW_SIGNUP';
export const GET_SIGNUP_DATA = 'GET_SIGNUP_DATA';
export const SHOW_FORGET_PASSWORD = 'SHOW_FORGET_PASSWORD';
export const SHOW_ACCOUNT_MODAL = 'SHOW_ACCOUNT_MODAL';
export const IS_MOBILE = 'IS_MOBILE';
export const SET_NAVBAR_HEIGHT = 'SET_NAVBAR_HEIGHT';
export const SHOW_OVERLAY_COVER = 'SHOW_OVERLAY_COVER';
export const SHOW_INACTIVITY_MODAL = 'SHOW_INACTIVITY_MODAL';
export const SHOW_HORIZONTAL_SECTION = 'SHOW_HORIZONTAL_SECTION';
export const UPDATE_ADDRESS_TEMP = 'UPDATE_ADDRESS_TEMP';
export const SHOW_UPDATE_INFO = 'SHOW_UPDATE_INFO';
export const SHOW_ORDER_MODAL = 'SHOW_ORDER_MODAL';
export const SHOW_ORDER_STATUS = 'SHOW_ORDER_STATUS';
export const IS_SHOP_UNAVAILABLE = 'IS_SHOP_UNAVAILABLE';
export const EMAIL = 'EMAIL';
export const SHOW_SIGNIN = 'SHOW_SIGNIN';
export const CUSTOMER_ADDRESS_CHECK = 'CUSTOMER_ADDRESS_CHECK';