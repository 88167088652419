import React from 'react';
import { connect } from 'react-redux';

class Spinner extends React.Component {
  render() {
    const { secondaryColor, color, size } = this.props;
    let newColor = secondaryColor;
    if (color) {
      newColor = color;
    }
    return (
      <div className={size === "sm" ? `spinner-border spinner-border-sm` : 'spinner-border align-self-center'} style={{ color: newColor }} role='status'>
        <span className='sr-only'>Loading...</span>
      </div>
    );
  }
}

const mapStateToProps = ({ configurationReducer }) => {
  const { secondaryColor } = configurationReducer;
  return { secondaryColor };
};

export default connect(mapStateToProps, {})(Spinner);
