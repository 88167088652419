import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from './CoverSection.styles';
import { BLACK, DESCRIPTION_ADDRESS, PRIMARY } from '../../constants';
import { isTerminalVertical } from '../../utils';
import Button from '../Button';

class CoverSection extends React.Component {
  renderButton = () => {
    const { isMobile } = this.props;
    return (
      <Button type={PRIMARY} className={`${isMobile ? '' : 'btn-lg'}`}>
        Passer une commande
      </Button>
    );
  };

  renderDesign0 = () => {
    const { webapp, name, descriptionText } = this.props;
    const {
      images,
      descriptionApp,
      descriptionAddress = DESCRIPTION_ADDRESS
    } = webapp;
    return (
      <div
        className="cover"
        style={{
          backgroundImage: `url(${images.imageCoverUrl ||
            images.imageCoverOrderUrl})`,
          backgroundSize: 'cover'
        }}
      >
        <div className="px-3 px-sm-5 py-sm-4 text-white" style={styles.sectionCover}>
          <div className="row text-center h-100 mt-sm-0">
            <div className="col my-auto px-3 px-sm-5">
              <p className="text-white text-title cover-title mb-0">{name}</p>
              <div className="px-lg-5 px-0">
                <p
                  className="line-break cover-description mb-3 px-lg-5 px-0"
                  style={{ textShadow: '2px 2px 4px ${BLACK}' }}
                >
                  {descriptionApp || descriptionText || descriptionAddress}
                </p>
              </div>
              <Link to="/commander">{this.renderButton()}</Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderDesign1 = () => {
    const { webapp, name, descriptionText } = this.props;
    const {
      images,
      descriptionApp,
      descriptionAddress = DESCRIPTION_ADDRESS
    } = webapp;
    return (
      <div
        className="cover"
        style={{
          backgroundImage: `url(${images.imageCoverUrl ||
            images.imageCoverOrderUrl})`,
          backgroundSize: 'cover'
        }}
      >
        <div className="px-3 px-sm-5 py-sm-4 text-white" style={styles.sectionCover}>
          <div className="row text-center h-100 mt-sm-0">
            <div className="col my-auto px-3 px-sm-5">
              <p className="text-white text-title cover-title mb-0">{name}</p>
              <div className="px-lg-5 px-0">
                <p
                  className="line-break cover-description mb-3 px-lg-5 px-0"
                  style={{ textShadow: `2px 2px 4px ${BLACK}` }}
                >
                  {descriptionApp || descriptionText || descriptionAddress}
                </p>
              </div>
              <Link to="/commander">{this.renderButton()}</Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderDesign = () => {
    const { webapp } = this.props;
    const { landingDesign } = webapp;
    switch (landingDesign) {
      case 0:
        return this.renderDesign0();
      case 1:
        return this.renderDesign1();
      default:
        return this.renderDesign0();
    }
  };

  renderSlides = () => {
    const { slides } = this.props;
    return (
      <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">{this.renderDesign()}</div>
          {_.keys(slides).map(key => {
            const image = slides[key];
            return (
              <div
                key={key}
                className="cover carousel-item"
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundSize: 'cover'
                }}
              />
            );
          })}
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleControls"
          role="button"
          data-slide="prev"
        >
          <span
            className={`${
              isTerminalVertical()
                ? 'carousel-control-terminal-vertical-prev-icon'
                : 'carousel-control-prev-icon'
            }`}
            aria-hidden="true"
          />
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleControls"
          role="button"
          data-slide="next"
        >
          <span
            className={`${
              isTerminalVertical()
                ? 'carousel-control-terminal-vertical-next-icon'
                : 'carousel-control-next-icon'
            }`}
            aria-hidden="true"
          />
          <span className="sr-only">Next</span>
        </a>
      </div>
    );
  };

  render() {
    const { slides } = this.props;

    return (
      <section>
        {(!slides || _.isEmpty(slides)) && this.renderDesign()}
        {!_.isEmpty(slides) && <div>{this.renderSlides()}</div>}
      </section>
    );
  }
}

const mapStateToProps = ({ configurationReducer, componentReducer }) => {
  const {
    webapp,
    slides,
    name,
    secondaryColor,
    titleDescription
  } = configurationReducer;
  const { isMobile } = componentReducer;
  return {
    titleDescription,
    webapp,
    slides,
    name,
    secondaryColor,
    isMobile
  };
};

export default connect(mapStateToProps, {})(CoverSection);
