import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';
import { PRIMARY } from '../../../constants/configuration';
import Button from '../../Button';
import { isStringNotNull, displayPriceFormat, getMinimumPriceFreeProduct } from '../../../utils';

class BestSellerComponent extends React.Component {
  renderDesign = () => {
    const { section, sectionKey } = this.props;

    if (section) {
      const { iconUrl, name } = section;
      let minimumPrice;
      _.map(section.products, product => {
        let productPrice = product.price;
        if (parseInt(productPrice, 10) <= 0) {
          productPrice = getMinimumPriceFreeProduct(product);
        }
        if (
          parseFloat(productPrice, 10) > 0 &&
          (!minimumPrice || parseFloat(productPrice) < parseFloat(minimumPrice))
        ) {
          minimumPrice = productPrice;
        }
      });
      const link = `/commander/#${sectionKey}`;
      return (
        <div className='card' style={{ height: '160px' }}>
          <div className='row card-body justify-content-center align-items-center p-0 px-2'>
            {isStringNotNull(iconUrl) && (
              <div className='row justify-content-center align-items-center d-none d-sm-block'>
                <img
                  alt={name}
                  src={iconUrl}
                  style={{ width: '100px', height: '100px' }}
                  className='rounded-circle align-self-center'
                />
              </div>
            )}
            <div
              className={`p-2 my-2 align-self-center col-12 ${
                isStringNotNull(iconUrl) ? 'col-sm-7' : ''
              }`}
            >
              <div className='row text-dark'>
                <div className='col my-auto'>
                  <h5>{name}</h5>
                </div>
              </div>
              {minimumPrice && (
                <div className='row text-muted'>
                  <div className='col px-1'>
                    <p className='mb-1'>À partir de {displayPriceFormat(minimumPrice)}</p>
                  </div>
                </div>
              )}
              <div className='row'>
                <div className='col'>
                  <Link to={link}>
                    <Button type={PRIMARY}>Choisissez</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className='card'>
        <div className='row card-body p-2'>
          <div
            style={{
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              minHeight: '150px'
            }}
          />
        </div>
      </div>
    );
  };

  render() {
    return <div>{this.renderDesign()}</div>;
  }
}

const mapStateToProps = ({ configurationReducer }) => {
  const { webapp, secondaryColor } = configurationReducer;
  return {
    webapp,
    secondaryColor
  };
};

export default connect(mapStateToProps)(BestSellerComponent);
