import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import Radio from '@mui/material/Radio'
import _ from 'lodash'
import { connect } from 'react-redux'
import Button from '../Button'
import { generateCounterOptionKey, getFirstProductCategory, isStringNotNull, isTerminalVertical } from '../../utils'
import {
  generateOptionChoiceMessage,
  getOptionData,
  handleCheckboxClick,
  handleCounterClick,
  handleRadioButtonClick,
  isCheckedOrSelected,
  optionHasNotEnoughStock,
  getFreeItem
} from './Category.services'
import styles from './Category.styles'
import { BLACK, COLOR_LIGHT_GRAY, COLOR_MID_GRAY, IMAGE_ASPECT_COVER, UNIT } from '../../constants'
import { isUniqueOption } from '../ListProducts/ProductModal/ProductModal.services.js'

class Category extends React.Component {
  renderOptionIcon = optionIconUrl => {
    const { images } = this.props;
    const { aspect } = images;
    const imageOptionStyle = isTerminalVertical()
      ? {
        borderRadius: UNIT * 2
      }
      : { width: '60px', height: '60px' };
    const imageOptionClassName = isTerminalVertical() ? 'image-option' : 'rounded-circle';
    return (
      <div
        style={{ marginRight: UNIT * 0.5 }}
        className={`align-self-center ${isTerminalVertical() && 'px-4 pt-3'}`}
      >
        {aspect === IMAGE_ASPECT_COVER && (
          <div
            className={imageOptionClassName}
            style={{
              ...imageOptionStyle,
              backgroundImage: `url(${optionIconUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              margin: 'auto'
            }}
          />
        )}
        {aspect !== IMAGE_ASPECT_COVER && (
          <img
            alt=''
            src={optionIconUrl}
            style={imageOptionStyle}
            className={imageOptionClassName}
          />
        )}
      </div>
    );
  };

  renderOptionLabel = (item, notAvailable) => {
    const { product, categoryKey, secondaryColor } = this.props;
    const { price, isFree } = item;
    let formatPrice = `+${price} €`;
    const firstProductCategory = getFirstProductCategory(product);
    if (firstProductCategory && firstProductCategory.path === categoryKey && product.price <= 0) {
      formatPrice = `${price} €`;
    }
    const { image: optionIconUrl, description: optionDescription } = getOptionData(item);
    const hasIcon = isStringNotNull(optionIconUrl);

    return (
      <div
        className={`${isTerminalVertical()
          ? `col h-100 ${hasIcon ? 'justify-content-center' : 'row align-items-center'}`
          : 'row align-items-center'
          }`}
        style={{
          flex: !isTerminalVertical() && 1,
          opacity: notAvailable && 0.2
        }}
      >
        {hasIcon && this.renderOptionIcon(optionIconUrl)}
        <div
          className={`row align-items-center ${isTerminalVertical() &&
            `py-3 ${hasIcon && 'justify-content-center'}`}`}
          style={{ flex: 1, textAlign: 'start' }}
        >
          <div style={styles.divNameAndDescription}>
            <p
              className={`text-break ${isTerminalVertical()
                ? `col-12 ${hasIcon && 'align-items-center text-center px-3'}`
                : 'col-8 col-sm-9'
                }`}
              style={{ ...styles.productName, fontSize: isTerminalVertical() && UNIT * 1.375 }}
            >
              {item?.name?.substring(0, 40)}
            </p>
            {isStringNotNull(optionDescription) && (
              <p style={styles.optionDescription}>{optionDescription}</p>
            )}

            {!isFree && price > 0 && (
              <div
                className={`text-break text-muted ${!isTerminalVertical() &&
                  'text-option-price-size ml-auto'}`}
              >
                <span className='no-localization' style={{ fontSize: 14, color: secondaryColor }}>
                  {price !== 0 ? formatPrice : ''}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderOptionWithOverlay = (id, index, itemKey, item, notAvailable, isItemChecked, isMultiple) => {
    const { secondaryColor, category, productItems } = this.props;
    const productItem = productItems[index];
    return (
      <div className={`py-3 h-100 ${index % 2 === 0 ? 'pl-4 pr-3' : 'pl-3 pr-4'}`}>
        <div
          className='h-100'
          id={id}
          key={itemKey}
          disabled={notAvailable}
          style={{
            position: 'relative',
            border: `3px solid ${isItemChecked && !isMultiple ? secondaryColor : COLOR_MID_GRAY}`,
            borderRadius: UNIT * 2
          }}
        >
          {isMultiple && this.renderMultiOptionOverlay(item, itemKey, notAvailable)}
          {this.renderOptionLabel(getFreeItem(productItem?.options, item, category), notAvailable || (isItemChecked && isMultiple))}
        </div>
      </div>
    );
  };

  renderMultiOptionOverlay = (item, itemKey, notAvailable) => {
    const { itemsCounter, category } = this.props;
    const optionKey = generateCounterOptionKey(category.key, itemKey);
    const counter = itemsCounter[optionKey] || 0;
    if (counter > 0) {
      return (
        <div
          className='overlay'
          style={{
            borderRadius: UNIT * 2,
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
          }}
        >
          <div className='text-overlay'>
            {this.renderMultiOptionButtons(counter, item, notAvailable, true)}
          </div>
        </div>
      );
    }
  };

  renderMultiOptionButtons = (counter, item, counterDisabled, hasPicture) => {
    const { secondaryColor, category } = this.props;
    return (
      <div className='row' style={{ paddingLeft: UNIT * 0.5 }}>
        {counter > 0 && (
          <div className='row'>
            <Button
              className={`p-0 no-outline align-items-center ${isTerminalVertical() &&
                hasPicture &&
                'order-first'}`}
              onClick={e => {
                e.stopPropagation();
                handleCounterClick(this, item, category, 'remove');
              }}
            >
              <i
                className='fas fa-minus-circle fa-lg label'
                style={{
                  fontSize: isTerminalVertical() && (hasPicture ? UNIT * 4 : UNIT * 2)
                }}
              />
            </Button>
            <p
              className={`align-self-center ${isTerminalVertical() && hasPicture ? 'mx-4' : 'mx-2'
                }`}
              style={{
                fontSize: isTerminalVertical() && (hasPicture ? UNIT * 2.5 : UNIT * 1.5)
              }}
            >
              {counter}
            </p>
          </div>
        )}
        <Button
          className={`p-0 no-outline align-items-center ${counterDisabled ? 'disabled' : ''
            } ${isTerminalVertical() && hasPicture && 'order-last'}`}
          onClick={e => {
            if (!counterDisabled) {
              e.stopPropagation();
              handleCounterClick(this, item, category, 'add');
            }
          }}
        >
          <i
            className='fas fa-plus-circle fa-lg label'
            style={{
              color: counterDisabled ? COLOR_LIGHT_GRAY : secondaryColor,
              fontSize: isTerminalVertical() && (hasPicture ? UNIT * 4 : UNIT * 2)
            }}
            onClick={this.addOne}
          />
        </Button>
      </div>
    );
  };

  renderItems = () => {
    const {
      productTitle,
      category,
      secondaryColor,
      productItems,
      index,
      itemsCounter,
      nbProduct,
      categoryId
    } = this.props;

    const { items, multiple } = category;
    const uniqueId = new Date().getUTCMilliseconds();
    const itemsKeys = items ? Object.keys(items) : [];
    const isMultiple = multiple && category?.max !== 1;
    const optionContainerWebapp = 'padding col-12 py-0 py-sm-1';
    const productItem = productItems[index];

    const concernedProductItem = productItem && productItem?.categoryKey === categoryId;
    const totalMaxItemsSelected = concernedProductItem && _.size(productItem.options) >= category?.max;
    const uniqueOption = isUniqueOption(category);
    if (isMultiple) {
      return (
        <div className='row col-12'>
          <div className='row col-12 py-3'>
            {itemsKeys
              .sort((a, b) => items[a].position - items[b].position)
              .map((key, i) => {
                const item = items[key];
                item.categoryKey = category.key;
                const notEnoughStock = optionHasNotEnoughStock(this, item, nbProduct);
                const { image: isOptionWithPicture } = getOptionData(item);
                const id = `${uniqueId}-${productTitle}-${categoryId}-${items[key]}`;
                const optionKey = generateCounterOptionKey(category.key, key);
                const counter = itemsCounter[optionKey] || 0;
                const currentItemSelected = _.filter(productItem?.options, option => option.key === item.key);
                const multipleMaxItemSelected = concernedProductItem && category?.multipleMax
                  ? _.size(currentItemSelected) >= category?.multipleMax
                  : false;
                const counterDisabled = totalMaxItemsSelected || notEnoughStock || multipleMaxItemSelected;
                const productContainerPadding = `${isTerminalVertical()
                  ? `padding col-6 ${i % 2 !== 0 &&
                  !isStringNotNull(isOptionWithPicture) &&
                  'pr-4'}`
                  : optionContainerWebapp
                  }`;
                const optionContainer = `${isTerminalVertical() &&
                  isStringNotNull(isOptionWithPicture) &&
                  'mx-5'} h-100`;
                return (
                  <div
                    key={key}
                    className={productContainerPadding}
                    style={{ cursor: !counterDisabled ? 'pointer' : 'auto' }}
                  >
                    <div
                      className={optionContainer}
                      onClick={() => {
                        if (
                          ((isStringNotNull(isOptionWithPicture) &&
                            isTerminalVertical() &&
                            counter === 0) ||
                            !isTerminalVertical()) &&
                          !counterDisabled
                        ) {
                          handleCounterClick(this, item, category, 'add');
                        }
                      }}
                    >
                      {isStringNotNull(isOptionWithPicture) && isTerminalVertical() ? (
                        <div>
                          {this.renderOptionWithOverlay(
                            id,
                            index,
                            key,
                            item,
                            counterDisabled,
                            counter > 0,
                            isMultiple
                          )}
                        </div>
                      ) : (
                        <div
                          className={`row h-100 d-flex align-items-center ${isTerminalVertical() ? (i % 2 === 0 ? 'offset-2' : 'offset-1') : ''
                            }`}
                        >
                          {this.renderOptionLabel(getFreeItem(productItem?.options, item, category), counterDisabled)}
                          {this.renderMultiOptionButtons(counter, item, counterDisabled)}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      );
    }
    if (uniqueOption) {
      return (
        <div className='row col-12'>
          <div className='row col-12 py-3'>
            {itemsKeys
              .sort((a, b) => parseInt(items[a].position, 10) - parseInt(items[b].position, 10))
              .map((key, i) => {
                const item = items[key];
                item.categoryKey = category.key;
                const notEnoughStock = optionHasNotEnoughStock(this, item, nbProduct);
                const isItemSelected = isCheckedOrSelected(productItems, index, item);
                const { image: isOptionWithPicture } = getOptionData(item);
                const id = `${uniqueId}-${productTitle}-${categoryId}-${items[key]}`;
                const productContainerPadding = `${isTerminalVertical()
                  ? `padding col-6 ${i % 2 !== 0 &&
                  !isStringNotNull(isOptionWithPicture) &&
                  'pr-4'}`
                  : optionContainerWebapp
                  }`;
                const optionContainer = `${isTerminalVertical() &&
                  isStringNotNull(isOptionWithPicture) &&
                  'mx-5'} h-100`;
                return (
                  <div
                    className={productContainerPadding}
                    style={{ cursor: !notEnoughStock ? 'pointer' : 'auto' }}
                    key={key}
                  >
                    <div
                      className={optionContainer}
                      onClick={() => {
                        if (!notEnoughStock) {
                          handleRadioButtonClick(this, item);
                        }
                      }}
                    >
                      {isStringNotNull(isOptionWithPicture) && isTerminalVertical() ? (
                        <>
                          {this.renderOptionWithOverlay(
                            id,
                            i,
                            key,
                            item,
                            notEnoughStock,
                            isItemSelected
                          )}
                        </>
                      ) : (
                        <div
                          className={`row h-100 d-flex align-items-center ${isTerminalVertical() ? (i % 2 === 0 ? 'offset-2' : 'offset-1') : ''
                            }`}
                        >
                          {this.renderOptionLabel(getFreeItem(productItem?.options, item, category), notEnoughStock)}
                          <Radio
                            checked={isItemSelected}
                            id={id}
                            value={key}
                            style={{
                              paddingLeft: UNIT * 0.5,
                              paddingRight: 0,
                              color: notEnoughStock ? COLOR_LIGHT_GRAY : secondaryColor,
                              transform: isTerminalVertical() ? 'scale(1.5)' : ''
                            }}
                            disabled={notEnoughStock}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      );
    } else {
      return (
        <div className='row col-12'>
          <div className='row col-12 py-3'>
            {itemsKeys
              .sort((a, b) => parseInt(items[a].position, 10) - parseInt(items[b].position, 10))
              .map((key, i) => {
                const item = items[key];
                item.categoryKey = category.key;
                const id = `${uniqueId}-${productTitle}-${categoryId}-${items[key]}`;
                const notEnoughStock = optionHasNotEnoughStock(this, item, nbProduct);
                const isItemChecked = isCheckedOrSelected(productItems, index, item);
                const { image: isOptionWithPicture } = getOptionData(item);
                const checkBoxDisabled = (totalMaxItemsSelected || notEnoughStock) && !isItemChecked;
                const productContainerPadding = `${isTerminalVertical()
                  ? `padding col-6 ${i % 2 !== 0 &&
                  !isStringNotNull(isOptionWithPicture) &&
                  'pr-4'}`
                  : optionContainerWebapp
                  }`;
                const optionContainer = `${isTerminalVertical() &&
                  isStringNotNull(isOptionWithPicture) &&
                  'mx-5'} h-100`;
                return (
                  <div
                    className={productContainerPadding}
                    key={key}
                    style={{ cursor: !checkBoxDisabled ? 'pointer' : 'auto' }}
                  >
                    <div
                      className={optionContainer}
                      onClick={() => {
                        if (!checkBoxDisabled) {
                          handleCheckboxClick(this, item);
                        }
                      }}
                    >
                      {isStringNotNull(isOptionWithPicture) && isTerminalVertical() ? (
                        <>
                          {this.renderOptionWithOverlay(
                            id,
                            i,
                            key,
                            item,
                            checkBoxDisabled,
                            isItemChecked
                          )}
                        </>
                      ) : (
                        <div
                          className={`row d-flex align-items-center ${isTerminalVertical() ? (i % 2 === 0 ? 'offset-2' : 'offset-1') : ''
                            }`}
                        >
                          {this.renderOptionLabel(getFreeItem(productItem?.options, item, category), checkBoxDisabled)}
                          <Checkbox
                            checked={isItemChecked}
                            value={key}
                            style={{
                              color: checkBoxDisabled ? COLOR_LIGHT_GRAY : secondaryColor,
                              transform: isTerminalVertical() ? 'scale(1.5)' : '',
                              paddingLeft: UNIT * 0.5,
                              paddingRight: 0
                            }}
                            disabled={checkBoxDisabled}
                            id={id}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      );
    }
  };

  render() {
    const { category, key } = this.props;
    const choiceMessage = generateOptionChoiceMessage(category);
    return (
      <div className='row col-12 align-self-start dark-modal border-radius' key={key}>
        <div
          className='col-12 d-flex py-1 py-sm-4 shadow-sm align-items-center dark-modal dark-border-bottom'
          style={styles.containerOptionHeader}
        >
          <div className='col-12'>
            <p style={{ color: BLACK }} className='mb-0 header-container-option px-2 label'>
              {category.name}
            </p>
            {choiceMessage && <span className='text-muted header-description-container-option label'>
              {choiceMessage}
            </span>}
          </div>
        </div>
        {this.renderItems()}
      </div>
    );
  }
}

const mapStateToProps = ({ configurationReducer }) => {
  const { secondaryColor, images } = configurationReducer;
  return {
    secondaryColor,
    images
  };
};

export default connect(mapStateToProps, {})(Category);
