import React from 'react'
import { connect } from 'react-redux'
import { isDarkTheme, isStringNotNull, isTerminalVertical } from '../../../utils'
import { IMAGE_ASPECT_COVER, UNIT, WHITE } from '../../../constants'
import styles from './Section.styles'

const INITIAL_STATE = { background: 'transparent', textColor: false };
class Section extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  toggleHover = () => {
    const { secondaryColor } = this.props;
    this.setState({
      background: secondaryColor,
      textColor: true
    });
  };

  toggleLeave = () => {
    this.setState({ background: 'transparent', textColor: false });
  };

  renderSectionImage = () => {
    const { images, image, title } = this.props;
    const { aspect } = images;
    if (isTerminalVertical()) {
      const sectionImageClassName = 'img-fluid mb-2 mx-2';
      if (aspect === IMAGE_ASPECT_COVER) {
        return (
          <div
            className={sectionImageClassName}
            style={{
              ...styles.imageSectionTerminalVertical,
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '92px'
            }}
          />
        );
      }
      return (
        <img
          src={image}
          className={sectionImageClassName}
          alt={title}
          style={styles.imageSectionTerminalVertical}
        />
      );
    }
    const sectionImageClassName = 'mr-2 rounded-circle';
    return (
      <div className='align-self-center'>
        {aspect === IMAGE_ASPECT_COVER && (
          <div
            className={sectionImageClassName}
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              ...styles.imageSection
            }}
          />
        )}
        {aspect !== IMAGE_ASPECT_COVER && (
          <img
            alt={title}
            src={image}
            style={styles.imageSection}
            className={sectionImageClassName}
          />
        )}
      </div>
    );
  };

  render() {
    const {
      sectionKey,
      image,
      title,
      selectedSection,
      sectionIndex,
      isHorizontalSection,
      secondaryColor
    } = this.props;
    const { background, textColor } = this.state;
    let anchor = `${sectionKey}`;
    if (sectionIndex === 0 && !isTerminalVertical()) {
      anchor = 'main-container';
    }
    const maxTitleTerminalVertical = 24;
    return (
      <a
        className='custom-link'
        key={sectionKey}
        href={`#${anchor}`}
        onMouseEnter={() => this.toggleHover()}
        onMouseLeave={() => this.toggleLeave()}
      >
        {isHorizontalSection && (
          <div style={{ backgroundColor: background }}>
            <div
              className={`py-4 py-lg-3 custom-border-bottom${
                String(selectedSection) === String(sectionKey)
                  ? textColor
                    ? '-active'
                    : ''
                  : '-none'
              } mx-2 mx-md-4`}
            >
              <div className='text-center'>
                <h6
                  className={textColor ? 'text-white' : 'text-color'}
                  style={{ fontSize: UNIT * 1.125, marginBottom: '0px' }}
                >
                  {title?.length && (title[0].toUpperCase() + title.slice(1).toLowerCase())}
                </h6>
              </div>
            </div>
          </div>
        )}
        {!isHorizontalSection && !isTerminalVertical() && (
          <div className='row border-bottom py-3' style={{ backgroundColor: background }}>
            {isStringNotNull(image) && this.renderSectionImage()}
            <h6
              className={`text-left mb-0 ${isStringNotNull(image) ? 'col-7' : ' px-1 col-12'} ${
                !isDarkTheme()
                  ? selectedSection === String(sectionKey)
                    ? textColor
                      ? 'text-white'
                      : 'text-color'
                    : textColor
                      ? 'text-white'
                      : 'text-muted'
                  : ''
              } text-break align-self-center`}
              style={{
                fontSize: UNIT * 1.125,
                color: isDarkTheme()
                  ? selectedSection === String(sectionKey)
                    ? textColor
                      ? WHITE
                      : secondaryColor
                    : WHITE
                  : ''
              }}
            >
              {title[0].toUpperCase() + title.slice(1).toLowerCase()}
            </h6>
          </div>
        )}
        {isTerminalVertical() && (
          <div
            className={`row justify-content-center align-items-center shadow mb-4 ${
              sectionIndex === 0 ? '' : 'mt-4'
            } text-center`}
            style={{
              height: '150px',
              width: '150px',
              margin: 'auto',
              border: `5px solid ${
                String(selectedSection) === String(sectionKey) ? secondaryColor : '#d9e2ef'
              }`,
              borderRadius: UNIT * 2
            }}
          >
            {isStringNotNull(image) && this.renderSectionImage()}
            <div className='row justify-content-center align-items-center'>
              <h6 className='text-break text-color px-2'>
                {(title[0].toUpperCase() + title.slice(1).toLowerCase()).substring(
                  0,
                  maxTitleTerminalVertical
                )}
                {title.length > maxTitleTerminalVertical ? '...' : ''}
              </h6>
            </div>
          </div>
        )}
      </a>
    );
  }
}

const mapStateToProps = ({ configurationReducer, sectionReducer, componentReducer }) => {
  const { sectionOrientation, theme, secondaryColor, images } = configurationReducer;
  const { isMobile, isHorizontalSection } = componentReducer;
  const { selectedSection } = sectionReducer;
  return {
    sectionOrientation,
    selectedSection,
    isMobile,
    isHorizontalSection,
    theme,
    secondaryColor,
    images
  };
};

export default connect(mapStateToProps, {})(Section);
