import React from 'react'
import { connect } from 'react-redux'
import styles, { backgroundHeightProperty, mobileDescriptionStyle } from './Cover.styles'
import { isStringNotNull, isTerminal, isTerminalVertical } from '../../../utils'
import { showHoursModal, updateAddressTemp, updateClosestShops } from '../../../redux/actions'
import { BLACK, DESCRIPTION_ADDRESS, PRIMARY, UNIT } from '../../../constants'
import Button from '../../Button'
import AddressInputMarketPlace from '../../AddressInput/for_marketplace/AddressInputMarketplace.view'

class Cover extends React.Component {
  render() {
    const {
      secondaryColor,
      webapp,
      showHoursModal,
      name,
      openHours,
      isMobile,
      marketplace,
      sliding,
      shopName,
      marketplaceLanding,
      description,
      logoUrl,
      descriptionText,
      fullScreenBackgroundImg = false,
      dispatch
    } = this.props;
    const {
      images,
      hideTitle,
      descriptionAddress = DESCRIPTION_ADDRESS
    } = webapp;
    const { imageCoverOrderUrl } = images;
    return (
      <div
        className={`text-white ${isTerminal() ? 'fixed-top' : ''} cover`}
        id='cover'
        style={
          isTerminalVertical()
            ? {
              height: '150px'
            }
            : {
              backgroundImage: `url(${
                sliding && !marketplaceLanding && marketplace?.enabled
                  ? sliding[0]
                  : imageCoverOrderUrl
              })`,
              backgroundSize: 'cover',
              ...backgroundHeightProperty(fullScreenBackgroundImg, isMobile)
            }
        }
      >
        <div
          className={`px-sm-5 py-sm-4 text-white ${
            isTerminalVertical() ? 'shadow' : ''
          }`}
          style={{
            ...styles.overlayCover,
            backgroundColor: isTerminalVertical()
              ? secondaryColor
              : `rgba(0,0,0,.4)`
          }}
        >
          <div className='row h-100 mt-sm-0 justify-content-center'>
            <div className='row text-center mt-sm-0'>
              <div
                className={`${
                  isTerminalVertical()
                    ? 'row justify-content-center'
                    : `col ${(!marketplaceLanding || (isMobile && marketplaceLanding)) && 'my-auto'} px-3 px-sm-5`
                }`}
              >
                {!hideTitle && (
                  <>
                    {isTerminalVertical() && isStringNotNull(logoUrl) && (
                      <img
                        src={logoUrl}
                        style={{
                          height: '100px',
                          width: '100px',
                          borderRadius: UNIT * 2
                        }}
                        alt=''
                      />
                    )}
                    <p
                      className={`text-white text-title ${isMobile ? 'shop-title' : 'cover-title'} mb-0 ${isTerminalVertical() &&
                        ' ml-3 justify-content-start align-self-center text-nowrap'}`}
                    >
                      {marketplace?.enabled && !marketplaceLanding
                        ? shopName
                        : name}
                    </p>
                  </>
                )}
                {!isTerminalVertical() && (
                  <div className='px-lg-5 px-0'>
                    <p
                      className='line-break cover-description mb-3 px-lg-5 px-0'
                      style={{ textShadow: `2px 2px 4px ${BLACK}`, ...mobileDescriptionStyle(isMobile) }}
                    >
                      {marketplace?.enabled && !marketplaceLanding
                        ? description
                        : descriptionText || descriptionAddress}
                    </p>
                  </div>
                )}
                {!isTerminal() && !marketplaceLanding && (
                  <Button
                    type={PRIMARY}
                    className={`${isMobile ? '' : 'btn-lg'}`}
                    onClick={() => {
                      const hoursModalData = {
                        show: true,
                        hours: openHours
                      };
                      showHoursModal(hoursModalData);
                    }}
                  >
                    Afficher les horaires
                  </Button>
                )}
                {marketplaceLanding && (
                  isMobile
                  ? <Button
                      type={PRIMARY}
                      onClick={()=> {
                        dispatch(updateAddressTemp({
                          isCustomerAddress: true
                        }))
                        dispatch(updateClosestShops([]))
                      }
                        }
                    >
                      Rechercher avec une autre adresse
                    </Button>
                    : (<div
                    className='rounded d-none d-md-inline-block'
                    style={styles.container}
                  >
                    <AddressInputMarketPlace />
                  </div>)
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  userReducer,
  configurationReducer,
  shopReducer,
  componentReducer
}) => {
  const { customerAddress } = userReducer;
  const {
    webapp,
    name,
    secondaryColor,
    marketplace,
    logoUrl,
    descriptionText
  } = configurationReducer;
  const { openHours, sliding, shopName, description } = shopReducer;
  const { navbarHeight, isMobile } = componentReducer;
  return {
    descriptionText,
    customerAddress,
    webapp,
    openHours,
    navbarHeight,
    secondaryColor,
    name,
    isMobile,
    sliding,
    shopName,
    marketplace,
    description,
    logoUrl
  };
};

export default connect(mapStateToProps, { showHoursModal })(Cover);
