import React from 'react';
import _ from 'lodash';
import { Fade } from 'react-awesome-reveal';
import BestSellerComponent from '../BestSellerComponent';
import { getTheme } from '../../../utils';
import { WHITE } from '../../../constants';
import { useAppSelector } from '../../../redux/hook';

function BestSellerSection({ index }) {

  const { sections } = useAppSelector(state => state.shopReducer);
  const { webapp } = useAppSelector(state => state.configurationReducer);
  const { imageCoverUrl2 } = webapp.images;
  const { style, textColor } = getTheme(index, imageCoverUrl2);

  const renderBestSellersDesign = () => {
    let i = 0;
    if (!_.isEmpty(sections)) {
      return _.keys(sections)
        .sort((a, b) => sections[a].position - sections[b].position)
        .map(key => {
          const section = sections[key];
          if (section && i < 4) {
            i++;
            return (
              <div className='col-md-6 p-0 p-sm-4 py-3 py-sm-0' key={key}>
                <BestSellerComponent section={section} sectionKey={section.key} />
              </div>
            );
          } else return null
        });
    }
    return _.map([1, 2, 3, 4], i => {
      return (
        <div className='col-md-6 p-0 p-sm-4 py-3 py-sm-0' key={i}>
          <BestSellerComponent />
        </div>
      );
    });
  };

  const renderDesign0 = textColor => {
    return (
      <Fade>
        <div className='container py-5'>
          <div className='row'>
            <div className='col'>
              <h1 className={`${textColor} text-title`}>Nos Best Sellers</h1>
              <h5 className={textColor === 'text-white' ? textColor : 'text-muted'}>
                A ne surtout pas rater
              </h5>
            </div>
          </div>
          <div className='row'>{renderBestSellersDesign(textColor)}</div>
        </div>
      </Fade>
    );
  };

  const renderDesign1 = textColor => {
    return (
      <div className='container py-5'>
        <div className='row'>
          <div className={`col ${textColor}`}>
            <h1 className='text-title'>Nos Best Sellers</h1>
            <h5 className={textColor === 'text-white' ? textColor : 'text-muted'}>
              A ne surtout pas rater
            </h5>
          </div>
        </div>
        <div className='row'>{renderBestSellersDesign(textColor)}</div>
      </div>
    );
  };

 const renderDesign = textColor => {
    const { landingDesign } = webapp;
    switch (landingDesign) {
      case 0:
        return renderDesign0(textColor);
      case 1:
        return renderDesign1(textColor);
      default:
        return renderDesign0(textColor);
    }
  };

    return (
      <section className='text-center' style={style}>
        <div style={{ backgroundColor: index % 2 ? 'rgba(0,0,0,.8)' : WHITE }}>
          {renderDesign(textColor)}
        </div>
      </section>
    );
  }




export default BestSellerSection;
