import store from '../redux/store';
import { sendGetRequest } from './backend';
import { API_VALIDATE_ADDRESS_DELIVERY } from '../constants';
import { sendCloudWatchLogs } from '../utils';

export const checkDeliveryAddress = async (customerAddress: any, shopId: string) => {
    if (!customerAddress) {
        throw new Error('No address given');
    }
    const { name, companyId } = store.getState().configurationReducer;
    const { user = {} } = store.getState().userReducer;
    try {
        sendCloudWatchLogs(
            `Checking address with stuart and Uber Direct : ${JSON.stringify(customerAddress)}`
        );
        const data = {
            dropoffAddress: customerAddress,
            customerPhoneNumber: user?.numero
        };
        const response = await sendGetRequest(
            API_VALIDATE_ADDRESS_DELIVERY(companyId, shopId),
            data,
            {},
            false
        );
        const isAddressDeliverable = response?.data?.addressDeliverable;
        sendCloudWatchLogs(
        isAddressDeliverable
                    ? 'Address validated with Stuart/Uber Direct.'
                    : `Could not validate address with stuart/Uber Direct: ${JSON.stringify(customerAddress)}`
            );
        return isAddressDeliverable;
    } catch (error) {
        let errorMessage = error;
        sendCloudWatchLogs(`Could not validate address with stuart/Uber Direct: ${errorMessage}`);
        if (errorMessage === 'OUT_OF_RANGE' && customerAddress?.city) {
            errorMessage = `${name} n'a pas encore été déployé sur ${(customerAddress.city)}.`;
        }
        throw errorMessage;
    }
};