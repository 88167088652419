import {
  UPDATE_ORDERS,
  UPDATE_ORDER_TIME,
  UPDATE_PAST_ORDERS,
  RESET_ORDER,
  UPDATE_CANCELED_ORDERS,
  UPDATE_EASEL_NUMBER
} from '../actions/types';

const INITIAL_STATE = {
  orders: {},
  orderTime: {},
  pastOrders: {},
  canceledOrders: {},
  easelNumber: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ORDERS:
      return { ...state, orders: action.payload };
    case UPDATE_ORDER_TIME:
      return { ...state, orderTime: action.payload };
    case UPDATE_PAST_ORDERS:
      return { ...state, pastOrders: action.payload };
    case UPDATE_CANCELED_ORDERS:
      return { ...state, canceledOrders: action.payload };
    case RESET_ORDER:
      return INITIAL_STATE;
    case UPDATE_EASEL_NUMBER:
      return { ...state, easelNumber: action.payload };
    default:
      return state;
  }
};
