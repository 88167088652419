import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import DayHoraire from '../DayHoraire'
import { DAYS, ORDER_TYPE_DELIVERY } from '../../../constants'
import { sortOpenHours } from './Horaires.services'
import { sortSlots } from '../../../utils'
import { useAppSelector } from '../../../redux/hook'

function Horaires({ hours, availableDates }) {
  const { customerAddress } = useAppSelector(state => state.userReducer);
  const { orderType } = useAppSelector(state => state.pendingOrderReducer);
  const renderHours = () => {
    if (hours) {
      const sortedOpenHours = sortOpenHours(hours);
      return (
        <tbody>
          {_.map(sortedOpenHours, (openHour, key) => {
            const { name, slots, slotsDelivery } = openHour;
            const selectedSlots =
              customerAddress && orderType === ORDER_TYPE_DELIVERY && slotsDelivery
                ? slotsDelivery
                : slots;
            const sortedSlots = sortSlots(selectedSlots, moment(key));
            return (
              <DayHoraire
                name={name}
                slots={sortedSlots}
                key={name}
                displayTop={sortedOpenHours[0] !== openHour}
              />
            );
          })}
        </tbody>
      );
    }
  };
  const renderAvailableDate = () => {
      return (
        <tbody>
          {_.map(availableDates, (availableDate) => {
            const { dayOfWeek, slots } = availableDate;
            return (
              <DayHoraire
                name={DAYS[dayOfWeek]}
                slots={_.map(slots, (slot) => {
                const [startSlot, endSlot] = slot.split('-');
                return startSlot === '00:00' && endSlot === '23:59' ? 'Toute la journée' : slot;
                })}
                key={dayOfWeek}
                styles ={{ fontSize: 14 }}
                displayTop={availableDates[0] !== availableDate}

              />
            );
          })}
        </tbody>
      );
  };
  return (
    <div>
      <table className='table dark-modal' style={{ margin: 'auto', width: '80%' }}>
        {availableDates ? renderAvailableDate() : renderHours()}
      </table>
    </div>
  );

}



export default Horaires;
