import { UNIT } from '../../constants/configuration';

const styles = {
  inputStyle: {
    backgroundColor: '#FBFBFB',
    border: '1px solid #F3F2F2',
    fontSize: UNIT * 0.875
  }
};

export default styles;