import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, Field } from 'redux-form'
import Modal from 'react-bootstrap/Modal';
import {
  showUpdateInfo,
  showMessageModal,
  showAccountModal,
  userSignOut,
  updateProfile,
  updateUser
} from '../../redux/actions';
import { renderErrorMessage, isStringNotNull } from '../../utils';
import { deleteUserAccount, updateAccount } from './AccountForm.services';
import { UNIT, PRIMARY, SECONDARY, LINK } from '../../constants/configuration';
import { TEXT_VALIDATION_DELETE_ACCOUNT } from '../../constants';
import Button from '../Button';
import PhoneInput from '../PhoneInput';
import Spinner from '../Spinner';

const INITIAL_STATE = {
  loading: false
};

class AccountForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  renderPhoneInput = ({ meta, input, label }) => {
    const { phone } = this.props;
    const { value, onBlur } = input;
    const className = `form-control ${
      (this.isPhoneIncorrect || !isStringNotNull(phone)) && meta.touched ? 'is-invalid' : ''
    }`;
    return (
      <div className='form-group'>
        <p className='text-muted mb-2'>{label}</p>
        <PhoneInput
            onChange={(phone, country, e, formattedPhone) => {
                  input.onChange(formattedPhone)
                }}
          value={value}
          onBlur={onBlur}
          className={`${className} no-localization dark-modal`}
          isPhoneIncorrect={isPhoneIncorrect => {
            this.isPhoneIncorrect = isPhoneIncorrect;
          }}

          getPhoneObj={(obj) => {
          this.phoneObj = obj
          }}
        />
        {(this.isPhoneIncorrect || !isStringNotNull(phone)) && meta.touched && (
          <div className="invalid-feedback-block">Veuillez fournir un telephone valide</div>
        )}
      </div>
    );
  }

  renderInput = ({ input, type, placeholder, meta, description, label }) => {
    const className = `form-control ${meta.error && meta.touched ? 'is-invalid' : ''}`;
    return (
      <div className='form-group'>
        <p className='text-muted mb-2'>{label}</p>
        <input
          type={type}
          className={`${className} no-localization dark-modal`}
          placeholder={placeholder}
          {...input}
        />
        <small className="form-check-label">{description}</small>
        <div className="invalid-feedback">{meta.error}</div>
      </div>
    );
  }

  componentDidMount() {
    const { user, initialize } = this.props;
    const { firstName, lastName, numero } = user;
    initialize({ firstName, lastName, numero });
  }

  onSubmit = ({ firstName, lastName, numero }) => {
    if (!this.isPhoneIncorrect && isStringNotNull(numero)) {
      updateAccount(this, firstName, lastName, numero);
    }
  }

  render() {
    const { showUpdateInfo, errorMessage, handleSubmit, user, showMessageModal } = this.props;
    const { loading } = this.state;
    const { firstName, lastName, numero } = user;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <Modal.Body className='pb-2'>
          <div className='p-0 p-sm-2'>
            {isStringNotNull(errorMessage) && (
              <p className="text-danger">{renderErrorMessage(errorMessage)}</p>
            )}
            <Field
              name="firstName"
              type="text"
              placeholder={firstName}
              component={this.renderInput}
              label="Prénom"
            />
            <Field
              name="lastName"
              type="text"
              placeholder={lastName}
              component={this.renderInput}
              label="Nom de famille"
            />
            <Field
              name="numero"
              type="text"
              placeholder={numero}
              component={this.renderPhoneInput}
              label="Numéro de téléphone"
            />
            <div className="text-center">
              <Button
                type={LINK}
                className='p-0'
                style={{ fontSize: UNIT * 0.875 }}
                onClick={() => {
                  showMessageModal(TEXT_VALIDATION_DELETE_ACCOUNT, {
                    onClick: () => deleteUserAccount(this),
                    showCancel: true
                  });
                }}
              >
                Supprimer mon compte
              </Button>
            </div>
          </div>
        </Modal.Body>
        {loading && (
          <Modal.Footer className="justify-content-center">
            <Spinner />
          </Modal.Footer>
        )}
        {!loading && (
          <Modal.Footer className="justify-content-center">
            <Button
              type={SECONDARY}
              onClick={() => {
                showUpdateInfo(false);
              }}
            >
              Retour
            </Button>
            <Button type={PRIMARY} isSubmit>
              Mettre à jour
            </Button>
          </Modal.Footer>
        )}
      </form>
    );
  }
}

const validate = formValues => {
  const errors = {};
  if (!formValues.firstName) {
    errors.firstName = 'Merci de fournir un prénom';
  }

  if (!formValues.lastName) {
    errors.lastName = 'Merci de fournir un nom de famille';
  }

  return errors;
};

const selector = formValueSelector('updateInfoForm');

const mapStateToProps = state => {
  const { configurationReducer, userReducer } = state;
  const phone = selector(state, 'numero');
  const { secondaryColor } = configurationReducer;
  const { loadingUpdateInfo, user, isUserAnonymous } = userReducer;
  return { secondaryColor, loadingUpdateInfo, user, phone, isUserAnonymous };
};
export default reduxForm({
  form: 'updateInfoForm',
  validate
})(
  connect(mapStateToProps, {
    showUpdateInfo,
    showMessageModal,
    showAccountModal,
    userSignOut,
    updateProfile,
    updateUser
  })(AccountForm)
);
