import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, styled } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Check from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import ForumIcon from '@mui/icons-material/Forum';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import StoreIcon from '@mui/icons-material/Store';
import { UNIT } from '../../constants/configuration';
import { ORDER_TYPE_DELIVERY, COLOR_GREY, DARK_COLOR, ORDER_FLOW_TYPE } from '../../constants';
import { isDarkTheme } from '../../utils';
import { getOrderSteps } from './OrderSteps.services';
import _ from 'lodash';

class OrderSteps extends React.Component {
  render() {
    const { order } = this.props;
    const { orderType } = order;
    const { secondaryColor } = this.props;
    const steps = getOrderSteps(orderType, order.orderFlowType)
    const ConnectorStyled = styled(StepConnector)(() => ({
      '& .MuiStepper-root': {
        padding: '0px'
      },
      [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
        padding: '0px'
      },
      [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: COLOR_GREY
        },
      },
      [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: secondaryColor
        },
      },
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: COLOR_GREY,
        borderTopWidth: 3,
        borderRadius: 1
      },
    }))

    const StepIconStyle = makeStyles({
      root: {
        color: COLOR_GREY,
        display: 'flex',
        height: 22,
        alignItems: 'center'
      },
      active: {
        color: secondaryColor
      },
      completed: {
        color: secondaryColor,
        zIndex: 1,
        fontSize: UNIT * 1.125
      }
    });

    const StepIconClasses = props => {
      const classes = StepIconStyle();
      const { active, completed } = props;
      const reverseFlowIcons = {
        1: <SearchIcon />,
        2: <ForumIcon />,
        3: <RestaurantIcon />,
        4: <DirectionsBikeIcon />,
      };
      let icons = order.orderFlowType == ORDER_FLOW_TYPE.REVERSE ? reverseFlowIcons : {
        1: <ForumIcon />,
        2: <RestaurantIcon />,
        3: <DirectionsBikeIcon />,
        4: <Check />
      };

      if (orderType !== ORDER_TYPE_DELIVERY) {
        icons = {
          1: <ForumIcon />,
          2: <RestaurantIcon />,
          3: <StoreIcon />,
          4: <Check />
        };
      }

      return (
        <div
          className={clsx(classes.root, {
            [classes.active]: active,
            [classes.completed]: completed
          })}
        >
          {icons[String(props.icon)]}
        </div>
      );
    };

    StepIconClasses.propTypes = {
      active: PropTypes.bool,
      completed: PropTypes.bool
    };

    const { activeStep } = this.props;

    return (
      <div>
        <Stepper style={{
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: isDarkTheme() && DARK_COLOR
        }} alternativeLabel activeStep={activeStep} connector={<ConnectorStyled />}>
          {_.map(steps, (label, index) => (
            <Step key={label} className='px-0'>
              <StepLabel StepIconComponent={StepIconClasses}>
                <span style ={{ color: index < activeStep ? secondaryColor : COLOR_GREY }} className={`${index < activeStep ? 'label ' : 'disabled-label'}`}>
                  {label}
                </span>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  }
}

const mapStateToProps = ({ configurationReducer }) => {
  const { secondaryColor } = configurationReducer;
  return {
    secondaryColor
  };
};

export default connect(mapStateToProps)(OrderSteps);