import { ADD_TO_COMMANDE, REMOVE_TO_COMMANDE, RESET_COMMANDE, UPDATE_COMMANDE } from './types'

export const addToCommande = (id, product) => {
  return {
    type: ADD_TO_COMMANDE,
    payload: {
      id,
      product
    }
  };
};

export const removeToCommande = id => {
  return {
    type: REMOVE_TO_COMMANDE,
    payload: { id }
  };
};

export const updateCommande = newCommande => {
  return {
    type: UPDATE_COMMANDE,
    payload: newCommande
  };
};

export const resetCommande = () => {
  return {
    type: RESET_COMMANDE
  };
};
