import _ from 'lodash'
import { connect } from 'react-redux'
import Media from 'react-bootstrap/Media'
import React from 'react'
import Radio from '@mui/material/Radio'
import Modal from 'react-bootstrap/Modal'
import Spinner from '../Spinner'
import Button from '../Button'
import { showAddressModal, showChooseShop, showMessageModal } from '../../redux/actions'
import { getAddressFormatted } from '../../utils'
import { onChooseShop } from './ChooseShop.services'
import { PRIMARY, SECONDARY } from '../../constants/configuration'
import { NO_SHOP_SELECTED_ERROR_MESSAGE } from '../../constants'

const INITIAL_STATE = {
  loading: false,
  selectedShop: {}
};

class ChooseShop extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  changeShop = shop => {
    this.setState({
      selectedShop: shop
    });
  };

  renderShops = () => {
    const { closestShops, secondaryColor } = this.props;
    if (closestShops && !_.isEmpty(closestShops)) {
      return _.keys(closestShops).map(key => {
        const shop = closestShops[key];
        const { name, address, distance } = shop;
        const { selectedShop } = this.state;
        let className = 'mt-3 align-self-center border-bottom';
        if (closestShops.length - 1 === parseInt(key)) {
          className = 'mt-3 align-self-center';
        }
        return (
          <Media
            key={key}
            as='li'
            style={{
              cursor: 'pointer'
            }}
            className={className}
            onClick={() => {
              this.changeShop(shop);
            }}
          >
            <Media.Body>
              <div className='align-items-center'>
                <div className='row justify'>
                  <div className='form-check pl-0 pr-2'>
                    <Radio
                      onChange={() => this.changeShop(shop)}
                      checked={selectedShop.shopId === shop.shopId}
                      id={name}
                      className='p-0 mb-1'
                      style={{ color: secondaryColor }}
                      size='small'
                    />
                  </div>
                  <h5 className='text-secondary mb-0 label'>{name}</h5>
                </div>
                <p className='text-secondary mb-3 no-localization label'>
                  {distance >= 0 && <span className='d-block label'>{distance.toFixed(2)} km</span>}
                  <span className='d-block label'>{getAddressFormatted(address)}</span>
                </p>
              </div>
            </Media.Body>
          </Media>
        );
      });
    }
  };

  render() {
    const { showMessageModal, showChooseShop } = this.props;
    const { selectedShop, loading } = this.state;
    return (
      <>
        <Modal.Body className='py-2 px-0 px-sm-2 dark-modal'>
          <div className='dishop-iso'>
            <div className='container-fluid dark-modal'>
              <div className='row'>
                <div className='col-12'>
                  <div className='px-0 px-sm-3'>
                    <div className='col'>{this.renderShops()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          className={`modal-sticky-footer d-flex justify-content-center 
            dark-modal`}
        >
          <>
            {loading && <Spinner />}
            {!loading && (
              <>
                <Button type={SECONDARY} onClick={() => showChooseShop(false)}>
                  Retour
                </Button>
                <Button
                  type={PRIMARY}
                  className='btn'
                  onClick={() => {
                    if (_.isEmpty(selectedShop)) {
                      showMessageModal(NO_SHOP_SELECTED_ERROR_MESSAGE);
                    } else {
                      onChooseShop(this, selectedShop);
                    }
                  }}
                >
                  Choisir
                </Button>
              </>
            )}
          </>
        </Modal.Footer>
      </>
    );
  }
}

const mapStateToProps = ({ configurationReducer, shopReducer }) => {
  const { secondaryColor } = configurationReducer;
  const { shopId, closestShops } = shopReducer;
  return {
    secondaryColor,
    shopId,
    closestShops
  };
};

export default connect(mapStateToProps, { showMessageModal, showAddressModal, showChooseShop })(
  ChooseShop
);