import React from 'react'
import Footer from '../../components/Footer'
import Cover from '../../components/Cover/Cover'
import { useAppSelector } from '../../redux/hook'
import styles from './LegalNotices.styles'
import Spinner from '../../components/Spinner';

import { getFullAddressFormatted, isDarkTheme } from '../../utils';

export default function LegalNotices() {
  const { servicePrice } = useAppSelector((state) => state.configurationReducer);
  const { legal } = useAppSelector((state) => state.shopReducer);
  const legalResult = servicePrice?.legal || legal
  return (
    <>
      <Cover />
      <div style={{ ...styles.containerStyle, color: isDarkTheme() ? 'white' : '#1a1a1a' }}>
        {legalResult ?
          <div >
            <h2 style={styles.header1Style}>Mentions Légales</h2>
            <section className="legal-info">
              <h5 style={styles.header2Style}>Informations légales de {legalResult.companyName} </h5>
              <p style={styles.paragraphStyle}>Siège social : {getFullAddressFormatted(legalResult.address)} </p>
              <p style={styles.paragraphStyle}>SIREN : {legalResult.registreCommerceNumero}</p>
              <p style={styles.paragraphStyle}>Directeur de la publication : {legalResult.companyName}</p>
            </section>

            <section className="host-info">
              <h5 style={styles.header2Style}>Conception et réalisation du site et de l’application mobile</h5>
              <p style={styles.paragraphStyle}>Crédit : Dishop </p>
              <p style={styles.paragraphStyle}>Siège sociél: 38 bis boulevard victor hugo, 06000 Nice </p>
              <p style={styles.paragraphStyle}>SIREN : 879 9128 06</p>
              <p style={styles.paragraphStyle}>Hébergeur : Google Cloud et AWS</p>
            </section>
          </div>
          : <div className='row justify-content-center justify-content-items m-5'><Spinner /></div>
        }
      </div>
      <Footer style={{
      bottom: 0,
      width: '100%',
      position: 'absolute'
    }} />
    </>
  )
}