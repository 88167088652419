import ReactPixel from 'react-facebook-pixel';

export const initializeFacebook = facebook => {
  const { pixel } = facebook;
  if (pixel) {
    const advancedMatching = {};
    const options = {
      autoConfig: true
    };
    ReactPixel.init(pixel, advancedMatching, options);
  }
};
