import store from '../../redux/store'
import { showMessageModal } from '../../redux/actions'
import { isObjectNotNull, sendCloudWatchLogs } from '../../utils'
import { updateAddressAndShop } from '../../utils/customer-address-and-shops'
import { ORDER_TYPE_DELIVERY } from '../../constants'

export const onChooseShop = async (component, shop) => {
  try {
    const { showAddressModal, showChooseShop } = component.props;
    const { delivery } = store.getState().configurationReducer;
    const { addressTemp } = store.getState().componentReducer;
    const { chooseShop } = delivery;
    const { address } = shop;
    component.setState({
      loading: true
    });
    let customerAddress = address;
    let orderType = store.getState().pendingOrderReducer?.orderType;
    if (isObjectNotNull(addressTemp)) {
      customerAddress = addressTemp;
      orderType = addressTemp.orderType
    }
    await updateAddressAndShop(
      shop.shopId,
      customerAddress,
      chooseShop && orderType === ORDER_TYPE_DELIVERY
        ? ORDER_TYPE_DELIVERY
        : orderType
    );
    showAddressModal(false);
    showChooseShop(false);
    component.setState({
      loading: false
    });
  } catch (error) {
    sendCloudWatchLogs(`Could not change shop ${error}`);
    store.dispatch(showMessageModal(error));
    component.setState({
      loading: false
    });
  }
};
