import _ from 'lodash';
import { IS_SHOP_UNAVAILABLE, UPDATE_CLOSEST_SHOPS, UPDATE_SHOP_IS_LOADED } from './types';
import { COOKIE_ORDER_TYPE, ORDER_TYPE_DELIVERY } from '../../constants';
import {
  checkIfShopAvailable,
  getClosestShops,
  getFirstShopId,
  getShopAndZoneData,
  getShopIdMarketplaceLink,
  setPendingOrderCookies
} from '../../utils';
import { sendCloudWatchAlert, sendCloudWatchLogs } from '../../utils/logs'
import { listenShopData } from '../../api/firebase/firebaseOn'
import { removeCustomerPendingOrder, updateAddressAndShop } from '../../utils/customer-address-and-shops'
import { Cookies } from 'react-cookie';

export const updateShop = (address: any) => async (dispatch: any, getState: any) => {
  try {
    dispatch({ type: UPDATE_SHOP_IS_LOADED, payload: false });
    const shopIdFromLink = getShopIdMarketplaceLink();
    const { shopId: previousShopId } = getState().shopReducer;
    const {
      shopId: pendingOrderShopId,
      orderType: pendingOrderOrderType
    } = getState().pendingOrderReducer;
    const orderType = pendingOrderOrderType || (new Cookies().get(COOKIE_ORDER_TYPE));
    const shopIdToGet = shopIdFromLink || (address && pendingOrderShopId);
    const shop = getState().shopReducer || getState().shopReducer?.shops?.find((shop: any) => shop?.shopId === shopIdToGet)
    if (!shop) {
      await removeCustomerPendingOrder();
    }
    await sendCloudWatchLogs(`Updating shop with address ${JSON.stringify(address)}`);
    const newShopId = shopIdToGet || (await getFirstShopId());
    await sendCloudWatchLogs(`Updating shop to ${newShopId}`);
    await listenShopData(previousShopId, newShopId, address, getShopAndZoneData, orderType);
    if (address && shopIdFromLink) {
      getClosestShops(address, orderType).then((closestShops) => {
        checkIfShopAvailable(address, shopIdFromLink, closestShops);
      });
    }
      if (!shopIdFromLink && address && orderType === ORDER_TYPE_DELIVERY) {
        const { delivery = {} } = getState().configurationReducer;
        const { userServicePrice: deliveryPricing } = delivery;
        if (shop) {
          const { configuration = {} } = shop;
          const { delivery: deliveryShop = {} } = configuration;
          const { userServicePrice: deliveryPricingShop } = deliveryShop;
          if (
            (deliveryPricingShop && !_.isEqual(deliveryPricingShop, address?.pricing)) ||
            (!deliveryPricingShop && deliveryPricing && !_.isEqual(deliveryPricing, address?.pricing)) ||
            (address?.pricing && !deliveryPricingShop && !deliveryPricing)
          ) {
              const newAddress = _.clone(address);
              if (address?.pricing && !deliveryPricingShop && !deliveryPricing) {
                delete newAddress.deliveryPrice;
                delete newAddress.pricing;
              }
                await updateAddressAndShop(shopIdToGet, newAddress, ORDER_TYPE_DELIVERY);
          }
      }
    }
    setPendingOrderCookies(!!address, address, newShopId, orderType);
  } catch (error) {
    await sendCloudWatchAlert(`Error updating shop ${error}`);
  }
};

export const updateShopIsLoaded = (shopIsLoaded: boolean): { type: string; payload: boolean } => ({
  type: UPDATE_SHOP_IS_LOADED,
  payload: shopIsLoaded
});

  export const updateClosestShops = (shops: any[]): { type: string; payload: any[] } => ({
  type: UPDATE_CLOSEST_SHOPS,
  payload: shops
});

export const isShopUnavailable = (shopUnavailable: boolean): { type: string; payload: boolean } => ({
  type: IS_SHOP_UNAVAILABLE,
  payload: shopUnavailable
});
