import _ from 'lodash';

export const isStringNotNull = string => {
  return string !== '' && string !== ' ' && string;
};

export const isObjectNotNull = object => {
  return !_.isEmpty(object) && object !== ' ' && object;
};

export const isEqualDeep = (a, b) => {
  const diff = _.reduce(
    a,
    (result, value, key) => {
      return _.isEqual(value, b[key]) ? result : result.concat(key);
    },
    []
  );
  return diff.length === 0;
};

export const removeUndefined = object => {
  _.map(object, (obj, index) => {
    if (!obj) {
      delete object[index];
    }
  });
};
