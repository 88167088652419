import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { showInactivityModal } from '../../redux/actions';
import { resetTerminalTimeout } from '../../utils';
import { WAIT_TIMEOUT } from '../../constants';
import { updateTimer } from './InactivityModal.services';
import ModalBorder from '../ModalBorder';

const INITIAL_STATE = {
  timer: WAIT_TIMEOUT
};

class InactivityModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  onClose = () => {
    const { showInactivityModal } = this.props;
    resetTerminalTimeout();
    showInactivityModal(false);
  };

  render() {
    const { inactivityModal, name } = this.props;
    const { timer } = this.state;
    updateTimer(this);
    return (
      <Modal
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={inactivityModal}
        onHide={() => {
          this.onClose();
        }}
      >
        <ModalBorder>
          <div
            onClick={() => {
              if (!this.preventClick) {
                this.onClose();
              } else {
                this.preventClick = false;
              }
            }}
          >
            <Modal.Header className='justify-content-center'>
              <h1 className='text-secondary'>{name}</h1>
            </Modal.Header>
            <Modal.Body className='dark-modal'>
              <h3 className='text-secondary text-center text mt-4'>
                Votre session va bientôt expirer !
              </h3>
              <h4 className='text-secondary text-center'>Touchez l'écran pour reprendre</h4>
              <h1 className='text-secondary text-center my-4'>{timer / 1000}</h1>
            </Modal.Body>
          </div>
        </ModalBorder>
      </Modal>
    );
  }
}

const mapStateToProps = ({ configurationReducer, componentReducer }) => {
  const { inactivityModal } = componentReducer;
  const { secondaryColor, name } = configurationReducer;
  return { secondaryColor, name, inactivityModal };
};

export default connect(mapStateToProps, { showInactivityModal })(InactivityModal);