import React, { Component } from 'react';
import { connect } from 'react-redux';
import { initializeMap, initializeMapWithAddress, updateMap, updateMapDirection } from './MapClient.services';
import { DRIVER, ORDER_FLOW_TYPE, ORDER_PHASE, ORDER_PHASE_CONTACTING, SHOP } from '../../constants';

const INITIAL_STATE = { orderLocation: null };

class MapClient extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    const { chargeId, orders, drivers, shops } = this.props;
    const { shopId, address, status, orderFlowType } = orders[chargeId];
    const { location } = address;
    const { address: addressShop } = shops[shopId];
    if (orderFlowType === ORDER_FLOW_TYPE.REVERSE && status == ORDER_PHASE_CONTACTING) {
      initializeMapWithAddress(address, this);
      updateMap(location, this)
  } else {
    const driverLocation = (drivers[chargeId] && drivers[chargeId].driverLocation) || null;
    initializeMap(this);
    if (!driverLocation) {
      updateMapDirection(addressShop.location, location, SHOP, this);
    } else {
      updateMapDirection(driverLocation, location, DRIVER, this);
    }
}
  }

  componentWillReceiveProps(nextProps) {
    const { chargeId, orders, drivers, shops } = nextProps;
    const { shopId, address, status } = orders[chargeId];
    const { location } = address;
    const { address: addressShop } = shops[shopId];
    const driverLocation = (drivers[chargeId] && drivers[chargeId].driverLocation) || null;
    if (status === ORDER_PHASE.DELIVERING) {
    if (!driverLocation) {
      updateMapDirection(addressShop.location, location, SHOP, this);
    } else {
      updateMapDirection(driverLocation, location, DRIVER, this);
    }
}
  }

  render() {
    return <div ref='map_canvas' style={{ height: '35vh' }} />;
  }
}
const mapStateToProps = ({ userReducer, driverReducer, shopReducer, orderReducer }) => {
  const { customerAddress } = userReducer;
  const { drivers } = driverReducer;
  const { shops } = shopReducer;
  const { orders } = orderReducer;
  return {
    customerAddress,
    drivers,
    shops,
    orders
  };
};

export default connect(mapStateToProps)(MapClient);
