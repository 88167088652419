import React from 'react';
import { connect } from 'react-redux';
import Spinner from '../Spinner';
import { COLOR_GREY, UNIT, PRIMARY, SECONDARY, LINK, KIOSK, WHITE } from '../../constants';

class Button extends React.PureComponent {
  getTheme = type => {
    const { secondaryColor } = this.props;
    switch (type) {
      case PRIMARY:
        return { backgroundColor: secondaryColor, color: WHITE };
      case SECONDARY:
        return { color: WHITE };
      case LINK:
        return { color: COLOR_GREY };
      case KIOSK:
        return {};
      default:
        break;
    }
    return {};
  };

  getClassname = type => {
    switch (type) {
      case SECONDARY:
        return 'btn-secondary';
      case LINK:
        return 'btn-link';
      case KIOSK:
        return 'btn-light';
      default:
        break;
    }
    return '';
  };

  render() {
    const {
      type,
      className,
      onClick,
      isSubmit,
      isLoading,
      id,
      disabled,
      onMouseEnter,
      onMouseLeave,
      style,
      children
    } = this.props;
    return (
      <>
        {isLoading && <Spinner size={UNIT * 1.25} />}
        {!isLoading && (
          <button
            type={isSubmit ? 'submit' : 'button'}
            className={`btn ${this.getClassname(type)} ${className}`}
            onClick={onClick}
            disabled={disabled}
            id={id}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={{
              ...this.getTheme(type),
              borderRadius: UNIT / 2,
              ...style
            }}
          >
            {children}
          </button>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ configurationReducer }) => {
  const { secondaryColor } = configurationReducer;
  return { secondaryColor };
};

export default connect(mapStateToProps, {})(Button);
