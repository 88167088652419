import {
  EMAIL,
  GET_SIGNUP_DATA,
  RESET_LOGIN,
  RESET_PASSWORD_ERROR,
  UPDATE_LAST_ORDER_CHARGE_IDS,
  UPDATE_PROFILE,
  USER_CONNECTION,
  USER_CONNECTION_ERROR,
  USER_SIGNOUT,
  USER_SIGNUP_ERROR,
  USER_START_CONNECTION,
  USER_START_RESET_PASSWORD,
  USER_START_UPDATE_INFO
} from '../actions/types'

const INITIAL_STATE = {
  user: {},
  userConnected: null,
  userConnectionError: '',
  userSignupError: '',
  resetPasswordError: '',
  updateInfoError: '',
  customerAddress: null,
  loadingConnexion: false,
  loadingResetPassword: false,
  lastOrderChargeIds: {},
  loadingUpdateInfo: false,
  signUpData: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_CONNECTION:
      return {
        ...state,
        user: action.payload.user,
        userConnected: !action.payload.isUserAnonymous,
        userConnectionError: '',
        loadingConnexion: false,
        isUserAnonymous: action.payload.isUserAnonymous
      };
    case USER_CONNECTION_ERROR:
      if (state.userConnectionError === action.payload) {
        const errorMessage = `${action.payload}`;
        return { ...state, userConnectionError: errorMessage, loadingConnexion: false };
      }
      return { ...state, userConnectionError: action.payload, loadingConnexion: false };
    case USER_SIGNUP_ERROR:
      if (state.userSignupError === action.payload) {
        const errorMessage = `${action.payload}`;
        return { ...state, userSignupError: errorMessage, loadingConnexion: false };
      }
      return { ...state, userSignupError: action.payload, loadingConnexion: false };
    case RESET_PASSWORD_ERROR:
      if (state.resetPasswordError === action.payload) {
        const errorMessage = `${action.payload}`;
        return { ...state, resetPasswordError: errorMessage, loadingResetPassword: false };
      }
      return { ...state, resetPasswordError: action.payload, loadingResetPassword: false };
    case USER_SIGNOUT:
      return { ...state, userConnected: false, user: {} };
    case USER_START_CONNECTION:
      return { ...state, loadingConnexion: true };
    case USER_START_RESET_PASSWORD:
      return { ...state, loadingResetPassword: true };
    case USER_START_UPDATE_INFO:
      return { ...state, loadingUpdateInfo: true };
    case UPDATE_PROFILE:
      return {
        ...state,
        user: { ...state.user, [action.payload.attribute]: action.payload.value }
      };
    case RESET_LOGIN:
      return { ...state, userConnectionError: '', userSignupError: '', loadingConnexion: false };
    case UPDATE_LAST_ORDER_CHARGE_IDS:
      return { ...state, lastOrderChargeIds: action.payload };
    case EMAIL:
      return { ...state, email: action.payload };
    case GET_SIGNUP_DATA:
      return { ...state, signUpData: action.payload };
    default:
      return state;
  }
};
