import { changeOrderTypeEndpoint, sendPostRequest } from '../../api/backend'
import { getIdToken } from '../../api/firebase/firebaseAuthentication'
import { renderErrorMessage, sendCloudWatchAlert } from '../../utils'
import { showMessageModal, showOrderStatus } from '../../redux/actions'
import store from '../../redux/store'
import {
  API_CANCEL_ORDER,
  CUSTOMER_CANCEL,
  CUSTOMER_CANCEL_PHASE_CONTACTING,
  CUSTOMER_CANCEL_PHASE_DELIVERING,
  CUSTOMER_CANCEL_PHASE_PREPARING,
  CUSTOMER_CANCEL_PHASE_TAKEAWAY,
  MODIFY_ORDER_TYPE_PROMOTION,
  ORDER_PHASE_CONTACTING,
  ORDER_PHASE_DELIVERING,
  ORDER_PHASE_PREPARING,
  ORDER_PHASE_TAKEAWAY
} from '../../constants'

export const cancelOrder = async (component, order) => {
  try {
    const { userConnected, user } = store.getState().userReducer;
    const { customerId } = user;
    const { status, chargeId, canal, orderNumber } = order;
    let cancelReason = '';
    switch (status) {
      case ORDER_PHASE_CONTACTING:
        cancelReason = CUSTOMER_CANCEL_PHASE_CONTACTING;
        break;
      case ORDER_PHASE_PREPARING:
        cancelReason = CUSTOMER_CANCEL_PHASE_PREPARING;
        break;
      case ORDER_PHASE_DELIVERING:
        cancelReason = CUSTOMER_CANCEL_PHASE_DELIVERING;
        break;
      case ORDER_PHASE_TAKEAWAY:
        cancelReason = CUSTOMER_CANCEL_PHASE_TAKEAWAY;
        break;
      default:
        break;
    }
    const cancellation = {
      canceledBy: CUSTOMER_CANCEL,
      cancelReason
    };
    const data = {
      customerId,
      cancellation,
      chargeId,
      canal
    };
    let headers = {};
    if (userConnected) {
      const idToken = await getIdToken();
      headers = { Authorization: `Bearer ${idToken}` };
    }
    component.setState({ isLoading: true })
    await sendPostRequest(API_CANCEL_ORDER, data, headers);
    store.dispatch(showMessageModal(`Votre commande n°${orderNumber} a bien été annulée`));
    store.dispatch(showOrderStatus(false));
  } catch (error) {
    store.dispatch(
      showMessageModal(`Nous n'avons pas pu annuler votre commande n°${order.orderNumber}`)
    );
    sendCloudWatchAlert(`Error cancelling order ${error}`);
  }
  component.setState({ isLoading: false })
};
export const changeOrderType = async (component, order, newOrderType) => {
  try {
    if (newOrderType === MODIFY_ORDER_TYPE_PROMOTION.REFUND) {
      component.setState({ isLoading: true })
      await changeOrderTypeEndpoint(order, newOrderType);
      store.dispatch(showOrderStatus(false));
      store.dispatch(showMessageModal(`Votre commande n°${order.orderNumber} a été annulée avec succès. Vous bénéficierez d'une réduction sur votre prochaine commande.`));
      return;
    }
    if (newOrderType === MODIFY_ORDER_TYPE_PROMOTION.CLICK_AND_COLLECT) {
      component.setState({ loadingChanginOrderType: true })
      await changeOrderTypeEndpoint(order, newOrderType);
    }
  } catch (error) {
    store.dispatch(showMessageModal(renderErrorMessage(error)));
    sendCloudWatchAlert(`Error changing order type ${error}`);
  }
  component.setState({ loadingChanginOrderType: false, isLoading: false })
};