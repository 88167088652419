const styles = {
  containerStyle: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
    fontFamily: 'Arial, sans-serif',
    justfiyContent: 'center',
    alignItems: 'center',
  },
  header1Style: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '20px',
    marginTop: '20px'
  },
  header2Style: {
    fontWeight: 'bold',
    marginTop: '40px'
  },
  paragraphStyle: {
    lineHeight: '1.5',
    fontSize: '1rem',
    marginBottom: '10px'
  },
  sectionStyle: {
    marginBottom: '20px'
  }
};
export default styles;
