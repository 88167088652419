import _ from 'lodash'
import store from '../redux/store'

export const getNbTotalCommande = (): number => {
  const { commande } = store.getState().pendingOrderReducer as any;
  if (_.isEmpty(commande)) {
    return 0;
  }
  let nbTotalCart = 0;
  _.map(commande, product => {
    const { nb, unit } = product;
    nbTotalCart += unit ? 1 : nb;
  });
  return nbTotalCart;
};
