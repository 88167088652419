export const PRODUCT_UNITS = {
  kg: [
    {
      name: 'g',
      conversion: 1000
    }
  ],
  l: [
    {
      name: 'cl',
      conversion: 100
    }
  ]
};

export const PRODUCT_KEY_ID_SEPARATOR = '___';