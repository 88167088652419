import { connect } from 'react-redux';
import { CardElement } from '@stripe/react-stripe-js';
import React from 'react';
import { showPaymentModal } from '../../redux/actions';
import { UNIT, WHITE } from '../../constants/configuration';
import { isDarkTheme } from '../../utils';

const INITIAL_STATE = {};

class AddPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  renderAddCreditCard = () => {
    return (
      <div className='col-12 border-bottom pb-3'>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: UNIT,
                color: isDarkTheme() ? WHITE : '#424770',
                '::placeholder': {
                  color: '#aab7c4'
                }
              },
              invalid: {
                color: '#9e2146'
              }
            }
          }}
        />
      </div>
    );
  };

  render() {
    return (
      <>
        <div>{this.renderAddCreditCard()}</div>
      </>
    );
  }
}

const mapStateToProps = ({ configurationReducer, userReducer, componentReducer }) => {
  const { paymentModal } = componentReducer;
  const { secondaryColor } = configurationReducer;
  const { user } = userReducer;
  return {
    secondaryColor,
    user,
    paymentModal
  };
};

export default connect(mapStateToProps, { showPaymentModal })(AddPayment);
