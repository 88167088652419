const styles = {
  shopCard: { width: '100%', cursor: 'pointer', borderRadius: '15px' },
  shopCardDisabled: {
    width: '100%',
    cursor: 'default',
    borderRadius: '15px'
  },
  link: {
    color: '#222222',
    textDecoration: 'none'
  }
};

export default styles;
