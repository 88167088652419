import { UNIT } from '../../constants/configuration';

const styles = {
  overlayCover: {
    backgroundColor: `rgba(0,0,0,.3)`,
    width: '100%',
    height: '100%'
  },
  containerTitleTerminalVerticalStyle: {
    height: '496px'
  },
  containerTitleTerminalHorizontalStyle: {
    height: '180px'
  },
  titleTerminalVerticalStyle: {
    fontSize: UNIT * 4.5
  },
  titleTerminalHorizontalStyle: {
    fontSize: UNIT * 3
  },
  textTermialVerticalStyle: {
    fontSize: UNIT * 3
  },
  textTerminalHorizontalStyle: {
    fontSize: UNIT * 2
  },
  backTextTermialVerticalStyle: {
    fontSize: UNIT * 2.5
  },
  backTextTerminalHorizontalStyle: {
    fontSize: UNIT * 1.5
  },
  iconTerminalVerticalStyle: {
    fontSize: UNIT * 3,
    alignSelf: 'center'
  },
  iconTerminalHorizontalStyle: {
    fontSize: UNIT * 2,
    alignSelf: 'center'
  },
  cardTerminalHorizontalStyle: {
    width: '180px',
    height: '180px',
    borderWidth: '6px',
    borderRadius: UNIT * 2
  },
  cardTerminalVerticalStyle: {
    width: '220px',
    height: '220px',
    borderWidth: '6px',
    borderRadius: UNIT * 2
  }
};

export default styles;
