import React, { Component, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

/**
 * PerfectScrollbar wrapper that accepts an index
 * and scrolls to a child ScrollElement at that index
 */
class ScrollView extends Component {
  constructor(props) {
    super(props);

    // Keeps refs to child ScrollElements
    this._register = [];
    this.registration = this.registration.bind(this);
  }

  // This gets passed to ScrollElements
  // so they can register themselves
  registration(sectionKey, childRef) {
    this._register[sectionKey] = childRef;
  }

  componentDidUpdate(prevProps) {
    const { scrollToIndex, topOffset = 50 } = this.props;
    // If scrollToIndex changes, scroll to new index
    if (scrollToIndex !== prevProps.scrollToIndex) {
      const container = this._psRef;
      const nodeRef = this._register[scrollToIndex];
      // If we have the container to scroll
      // and a node to scroll to, go for it
      if (container && nodeRef && nodeRef.current) {
        container.scrollTop = nodeRef.current.offsetTop - topOffset;
      }
    }
  }

  render() {
    return (
      <PerfectScrollbar
        options={{ wheelPropagation: false }}
        containerRef={ref => {
          this._psRef = ref;
        }}
        className='hide-scroll'
      >
        {// Using `children` as a render prop
        // to pass the registration function
        // https://reactjs.org/docs/render-props.html#using-props-other-than-render
          this.props.children(this.registration)}
      </PerfectScrollbar>
    );
  }
}

export default ScrollView;

/**
 * Anchors within ScrollView
 * that can be scrolled to
 */
function ScrollElement({ registration, sectionKey, children }) {
  // Create ref and pass it to parent ScrollView
  const ref = useRef();
  registration(sectionKey, ref);

  return <span ref={ref}>{children}</span>;
}

export { ScrollElement };
