import moment from 'moment'
import _ from 'lodash'
import apiService from '@dishopsaas/dishop-backend-api-service'
import {
  checkOrdersLimit,
  getWebViewCustomerId,
  getWebViewPageId,
  isSameDay,
  sendCloudWatchAlert,
  sortSlots
} from '../../utils'
import { API_SEND_ORDER_MESSENGER, ORDER_TYPE_DELIVERY } from '../../constants'
import { showCartModal, showMessageModal, updateOrderTime } from '../../redux/actions'
import store from '../../redux/store'
import { closeWebView, sendPostRequest } from '../../api'

export const getMaximumPreparationTime = commande => {
  let maxPreparationTime = 0;
  _.map(commande, product => {
    const { preparationTime } = product;
    if (maxPreparationTime < parseInt(preparationTime, 10)) {
      maxPreparationTime = parseInt(preparationTime, 10);
    }
  });
  return maxPreparationTime;
};

export const getLimit = (limits, slots, slot) => {
  if (limits) {
    const indexSlot = _.findIndex(slots, s => s === slot);
    return limits[indexSlot];
  }
};

export const calculateStartTime = (now, slotInterval) => {
  let date = moment(now);
  const nowMinutes = parseInt(moment().format('mm'), 10);
  const nbSlot = parseInt(nowMinutes / slotInterval, 10) + 1;
  const minutesToAdd = nbSlot * slotInterval - nowMinutes;
  date = date.add(minutesToAdd, 'm');
  return date;
}

export const getSlotsAndLimits = (day, orderType) => {
  const { openHours, specialHours } = store.getState().shopReducer;
  const hasSpecialHours = _.find(specialHours, hours => isSameDay(hours.day, day));
  const anyDayHasDeliverySlot = _.some(openHours, hours => hours?.slotsDelivery?.length > 0 && orderType === ORDER_TYPE_DELIVERY);
  const targetDay = openHours[day.format('dddd')] ;
  const limits = anyDayHasDeliverySlot ? targetDay.limitsDelivery : targetDay.limits
  if (hasSpecialHours) {
    const slots = hasSpecialHours.slots;
    return { slots, limits };
  }
  const slots = anyDayHasDeliverySlot ? targetDay.slotsDelivery : targetDay.slots
  return { slots, limits };
}

export const getOrderTimes = (day, orderType, maximumPreparationTime) => {
    const orderTimes = [];
    const { configHours } = store.getState().configurationReducer;
    const now = maximumPreparationTime ? moment().add(maximumPreparationTime, 'h') : moment();
    const slotsAndLimits = getSlotsAndLimits(day, orderType);
    if (slotsAndLimits?.slots) {
      const sortedSlots = sortSlots(slotsAndLimits.slots, day);
      const slotInterval = _.get(configHours, 'slotInterval', 30);

      _.forEach(sortedSlots, slot => {
        const [beginHours, endHours] = _.map(slot.split('-'), time => moment(`${day.format('YYYY-MM-DD')}T${time}:00`));
        if (slot.endsWith('00:00')) {
            endHours.add(1, 'd');
        }
        const date = now > beginHours ? calculateStartTime(now, slotInterval) : moment(beginHours);
        while (date < endHours) {
          const datePlus30Minutes = date.clone().add(slotInterval, 'm');
          const hasLimit = checkOrdersLimit(getLimit(slotsAndLimits.limits, slotsAndLimits.slots, slot), date, datePlus30Minutes);
          const momentPlusSlotMinutes = moment().add(slotInterval, 'm');
          const currentSlot = momentPlusSlotMinutes.isSameOrAfter(date) && momentPlusSlotMinutes.isSameOrBefore(datePlus30Minutes);
          const dateFormatted = `${date.format('HH:mm')} - ${datePlus30Minutes.format('HH:mm')}`;
          orderTimes.push({ value: dateFormatted, data: date.clone(), currentSlot, disabled: hasLimit });
          date.add(slotInterval, 'm');
        }
      });
    }
    return orderTimes ;
  }

  export const getFirstDay = (component, maximumPreparationTime) => {
    const { orderType } = store.getState().pendingOrderReducer;
    let now = moment();
    if (maximumPreparationTime) {
      now = moment().add(maximumPreparationTime, 'h');
    }
    let orderTimes = getOrderTimes(now, orderType, maximumPreparationTime);
    let dayToAdd = 1;
    store.dispatch(updateOrderTime(orderTimes[0]));
    while ((orderTimes.length === 0 || !_.find(orderTimes, orderTime => !orderTime.disabled)) && dayToAdd < 7) {
      now = moment().add(dayToAdd, 'd');
      orderTimes = getOrderTimes(now, orderType, maximumPreparationTime);
      dayToAdd++;
    }
    if (orderTimes.length > 0) {
      component.setState({ day: now, hourSelectValue: 0, selectDay: false,
        selectTime: false, isValidDate: true });
    }
  };

export const getExcludedOrderDays = (date, maximumPreparationTime, component) => {
  const { orderType } = component.props;
  const now = moment();
  const newDate = moment(date);
  let diffDays = now.diff(newDate, 'days');
  if (diffDays > 0) {
    return false;
  }
  if (maximumPreparationTime) {
    const nowPlusPreparationTime = moment().add(maximumPreparationTime, 'h');
    diffDays = nowPlusPreparationTime.diff(newDate, 'days');
    if (diffDays > 0) {
      return false;
    }
  }
  const orderTimes = getOrderTimes(newDate, orderType, maximumPreparationTime);
  if (orderTimes.length === 0 || !_.find(orderTimes, orderTime => !orderTime.disabled)) {
    return false;
  }
  const { openHours, specialHours } = store.getState().shopReducer;
  // Check if shop exceptionnaly close for a specific date
  const isTodayExceptionnallyClose = _.find(specialHours, hours => {
    const { day, slots } = hours;
    return isSameDay(day, date) && !slots;
  });
  if (isTodayExceptionnallyClose) {
    return false;
  }
  // Check if shop open for a specific date
  const openHour = _.find(openHours, (openHour, day) => {
    return newDate.format('dddd') === day && (openHour.slots || openHour.slotsDelivery);
  });
  if (openHour) {
    return true;
  }
  return false;
};


export const sendOrderMessenger = async component => {
  try {
    component.setState({ loading: true });
    const link = await apiService.configurationGetOne(['chatbot', 'link']) // TODO do we really need another DB call, can we get config from the store? also chatbot is deprecated, isn't it?
    const customerId = getWebViewCustomerId();
    const pageId = getWebViewPageId();
    const data = {
      customerId,
      pageId
    };
    await sendPostRequest(API_SEND_ORDER_MESSENGER, data, {});
    closeWebView();
    setTimeout(() => {
      component.setState({ loading: false });
      window.location.href = link;
      store.dispatch(showCartModal(false));
    }, 5000);
  } catch (error) {
    component.setState({ loading: false });
    store.dispatch(
      showMessageModal('Nous n\'avons pas pu envoyer votre commande. Veuillez réessayer.')
    );
    sendCloudWatchAlert(`Could not send order messenger ${error}`);
  }
};
