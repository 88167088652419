import apiService from '@dishopsaas/dishop-backend-api-service'
import { sendCloudWatchAlert } from '../utils/logs'
import { getIdToken } from './firebase/firebaseAuthentication'

import { findByAddress } from 'topkat-utils'

const isDev = false

export function initBackendV2(companyId) {

  apiService.beforeApiCall(async () => await getIdToken())

  apiService.init(isDev ? `http://localhost:9086/` : 'https://dev-backend-v2.dishop.co/', (err) => {

    const doLog = err.hasBeenLogged !== true

    if (err && err.msg) {
      const {
        msg = err?.response?.statusText,
        code = err.code || err.status || findByAddress(err, 'response.status'),
        ...extraInfos
      } = err || {}

      const fullMsg = `${code} ${msg}`
      const content = JSON.stringify(extraInfos, null, 2)
        .replace(/^ {2}(\s*)"([^"]+)"/gm, '$1$2')
        .replace(/(^{|}$)/g, '')

      if (doLog) console.error(fullMsg, content)
      sendCloudWatchAlert(fullMsg + '\n' + content, false)
    } else {
      if (doLog) console.error(err)
      sendCloudWatchAlert(err.toString(), false)
    }
    throw err
  }, 'user', companyId)
  apiService.setDefaultDatabase(companyId)
}

export function backendV2auth(firebaseToken: string) {
  apiService.setHeaders({ authorization: firebaseToken }, true)
}


export function setAnonymousUserId(anonymousUserId: string) {
  apiService.setHeaders({ anonymousUserId }, true)
}