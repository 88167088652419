import { UPDATE_ADDRES_IS_LOADED, UPDATE_ADDRESS, UPDATE_ADDRESS_ATTRIBUTE, UPDATE_PENDING_ORDER } from './types';
import { sendCloudWatchAlert } from '../../utils';
import {
  getCustomerAddress,
  setUserPendingOrderAddressAndOrderTypeFirebase,
  setUserShopIds
} from '../../utils/customer-address-and-shops';

export const updateAddress = (address, dispatch) => {
  if (dispatch) {
    dispatch({ type: UPDATE_ADDRESS, payload: { address } });
  } else {
    return { type: UPDATE_ADDRESS, payload: { address } };
  }
};

export const addAddress = async (
  dispatch: any,
  getState: any,
  newCustomerAddress: any,
  customerId: string,
  isUserAnonymous: boolean
) => {
  try {
    const { shopId, orderType } = getState().pendingOrderReducer;
    const oldCustomerAddress = await getCustomerAddress(customerId);
    if (oldCustomerAddress && shopId && (!newCustomerAddress || isUserAnonymous)) {
      updateAddress(oldCustomerAddress, dispatch);
    } else if (newCustomerAddress && shopId) {
      await setUserPendingOrderAddressAndOrderTypeFirebase(
        customerId,
        shopId,
        newCustomerAddress,
        orderType
      );
      updateAddress(newCustomerAddress, dispatch);
    } else if (!oldCustomerAddress && !newCustomerAddress) {
      updateAddress(null, dispatch);
    }
    if (!isUserAnonymous && shopId) {
      await setUserShopIds(shopId, customerId);
    }
  } catch (error) {
    sendCloudWatchAlert(`Error adding address ${error}`);
  }
};
export const updateAddressIsLoaded = (addressIsLoaded: boolean) => {
  return { type: UPDATE_ADDRES_IS_LOADED, payload: addressIsLoaded };
};

export const updateAddressAttribute = (attribute: any, value: any) => {
  return { type: UPDATE_ADDRESS_ATTRIBUTE, payload: { attribute, value } };
};

export const resetPendingOrder = () => {
  return { type: UPDATE_PENDING_ORDER, payload: { shopId: null, address: null, commande: {} } };
};
