import {
  BLACK,
  BLOCK_CONTENT,
  INACTIVITY_TIMEOUT,
  ORDER_TYPE_DELIVERY,
  QRCODE,
  START_CONTENT,
  TERMINAL,
  TERMINAL_V,
  WAIT_TIMEOUT,
  WEBVIEW,
  WHITE
} from '../constants'
import store from '../redux/store'
import {
  setTerminalTimeout,
  showCartModal,
  showInactivityModal,
  showOverlayCover,
  showProductModal
} from '../redux/actions/componentAction'
import { sendCloudWatchAlert, sendCloudWatchLogs } from './logs'
import { unlistenFirebase } from '../api/firebase/firebaseOn'
import { isStringNotNull } from './dataType'
import { resetCommande, userSignOut } from '../redux/actions/index.js'
import { resetCookieCustomerId } from './customer'

export const isWebView = () => {
  const domain = window.location.href;
  return domain.includes(WEBVIEW);
};

export const isQrCode = () => {
  const domain = window.location.href;
  return domain.includes(QRCODE) && !domain.includes('utm');
};

export const isTerminal = () => {
  const domain = window.location.href;
  return domain.includes(TERMINAL);

};

export const isTerminalVertical = () => {
  const domain = window.location.href;
  return domain.includes(TERMINAL_V);
};

export const getWebViewPageId = () => {
  return window.location.pathname.split('/')[2];
};

export const getTerminalId = () => {
  return window.location.pathname.split('/')[3];
};

export const isChatbot = () => {
  const { webapp } = store.getState().configurationReducer;
  const domain = window.location.hostname;
  if ((webapp && webapp.chatbot) || domain.includes('demobot')) {
    return true;
  }
  return false;
};

export const isDev = () => {
  const domain = window.location.hostname;
  if (domain.includes('localhost')) {
    return true;
  }
  return false;
};

export const getOffsetTop = (element) => {
  let offsetTop = 0;
  while (element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
};

export const resetTerminalTimeout = () => {
  let { terminalTimeout } = store.getState().componentReducer;
  clearTimeout(terminalTimeout);
  terminalTimeout = null;
  terminalTimeout = setTimeout(() => {
    sendCloudWatchLogs('show inactivity  session');
    store.dispatch(showInactivityModal(true));
    terminalTimeout = setTimeout(() => {
      resetTerminalSession();
    }, WAIT_TIMEOUT);
    store.dispatch(setTerminalTimeout(terminalTimeout));
  }, INACTIVITY_TIMEOUT);
  store.dispatch(setTerminalTimeout(terminalTimeout));
};

export const resetTerminalSession = () => {
  const { terminalTimeout } = store.getState().componentReducer;
  const { user } = store.getState().userReducer;
  sendCloudWatchLogs('Close and reset session');
  clearTimeout(terminalTimeout);
  store.dispatch(setTerminalTimeout(null));
  store.dispatch(showCartModal(false));
  store.dispatch(showProductModal(false));
  store.dispatch(showInactivityModal(false));
  store.dispatch(resetCommande());
  unlistenFirebase(user.customerId);
  store.dispatch(userSignOut())
  resetCookieCustomerId()
  store.dispatch(showOverlayCover(START_CONTENT));
};

export const getPaymentTypes = () => {
  let { paymentTypes } = store.getState().configurationReducer;
  const { qrcode, terminal, delivery } = store.getState().configurationReducer;
  const { shopConfiguration = {} } = store.getState().shopReducer;
  const { orderType, address: customerAddress } = store.getState().pendingOrderReducer;
  const { paymentTypes: deliveryPaymentTypes } = delivery;
  if (isQrCode() && qrcode) {
    paymentTypes = qrcode.paymentTypes;
  } else if (isTerminal() && terminal) {
    paymentTypes = terminal.paymentTypes;
  } else {
    if (shopConfiguration.paymentTypes) {
      paymentTypes = shopConfiguration.paymentTypes;
    }
    if (customerAddress && orderType === ORDER_TYPE_DELIVERY) {
      if (shopConfiguration.delivery && shopConfiguration.delivery.paymentTypes) {
        paymentTypes = shopConfiguration.delivery.paymentTypes;
      } else if (deliveryPaymentTypes) {
        paymentTypes = deliveryPaymentTypes;
      }
    }
  }
  return paymentTypes;
};

export const checkAccessContent = () => {
  const { qrcode, terminal, webapp } = store.getState().configurationReducer;
  if (isQrCode() && (!qrcode || !qrcode.enabled)) {
    store.dispatch(showOverlayCover(BLOCK_CONTENT));
  }
  if (isTerminal() && (!terminal || !terminal.enabled)) {
    store.dispatch(showOverlayCover(BLOCK_CONTENT));
  }
  if (!isTerminal() && !isQrCode() && (!webapp || !webapp.enabled)) {
    store.dispatch(showOverlayCover(BLOCK_CONTENT));
  }
};

export const isDarkTheme = () => {
  const { theme, marketplace } = store.getState().configurationReducer;
  return theme === 'dark' && (!marketplace || !marketplace.enabled);
};

export const getTheme = (index, baackgroundImage) => {
  const style = {
    backgroundColor: index % 2 ? BLACK : WHITE
  };
  const textColor = index % 2 ? 'text-white' : 'text-dark';
  if (index % 2 && isStringNotNull(baackgroundImage)) {
    style.backgroundImage = `url(${baackgroundImage})`;
    style.backgroundSize = 'cover';
  }
  return { style, textColor };
};

export const getMetadata = (name, webapp, descriptionText) => {
  let keywords =
    'Service, de, livraison, rapide, livraison, emporter, commande, Dishop';
  let title = name;
  let description = '';
  const {
    descriptionTitle,
    descriptionAddress,
    description: descriptionWebApp,
    keywords: keywordsWebApp
  } = webapp;
  if (webapp) {
    if (isStringNotNull(descriptionTitle)) {
      title += ` • ${descriptionTitle.slice(0, 50)}`;
    } else if (isStringNotNull(descriptionText)) {
      title += ` • ${descriptionText.slice(0, 50)}`;
      description = descriptionText.slice(0, 160);
    } else if (isStringNotNull(webapp.descriptionAddress)) {
      title += ` • ${descriptionAddress.slice(0, 50)}`;
      description = descriptionAddress.slice(0, 160);
    }
    if (isStringNotNull(descriptionWebApp)) {
      description = descriptionWebApp.slice(0, 160);
    }
    if (isStringNotNull(keywordsWebApp)) {
      keywords = keywordsWebApp;
    }
  }
  return { title, description, keywords };
};

export const interceptConsole = () => {
  // taken from http://tobyho.com/2012/07/27/taking-over-console-log/
  const { console } = window;
  if (!console) return;

  function intercept(method) {
    const original = console[method];
    console[method] = (...params) => {
      // check message
      if (params[0] && String(params[0]).includes('MapError')) {
        sendCloudWatchAlert(`Error google maps ${params[0]}`);
      }

      if (original.apply) {
        // Do this for normal browsers
        original.apply(console, params);
      } else {
        // Do this for IE
        const message = Array.prototype.slice.apply(params).join(' ');
        original(message);
      }
    };
  }
  const methods = ['error']; // only interested in the console.error method
  for (let i = 0; i < methods.length; i++) intercept(methods[i]);
};

export const getCurrentLanguage = () => {
  let language = 'fr';
  if (window.Localize && window.Localize.getLanguage()) {
    language = window.Localize.getLanguage();
    if (language === 'source') {
      language = 'fr';
    }
  }
  return language;
};

export const getTerminalVerticalHeight = () => {
  const { navbarHeight } = store.getState().componentReducer;
  let terminalMarginBottom = 0;
  if (document.getElementById('bottom-cart')) {
    terminalMarginBottom = document.getElementById('bottom-cart').scrollHeight;
  }
  return (
    window.innerHeight -
    Number(navbarHeight.replace('px', '')) -
    terminalMarginBottom -
    16
  );
};

export const isSplash = () => {
  const domain = window.location.href;
  const { splash } = store.getState().componentReducer;
  return splash || domain.includes('splash');
};

export const convertHexToRGBA = (hexCode, opacity = 1) => {
    const hex = hexCode.replace('#', '');
    const parseHex = (start, end) => parseInt(hex.substring(start, end), 16);
    const r = parseHex(0, 2);
    const g = parseHex(2, 4);
    const b = parseHex(4, 6);
    opacity = Math.min(Math.max(opacity, 0), 1);
    return `rgba(${r},${g},${b},${opacity})`;
};