export const removeUndefinedProperties = <T>(obj: T): T => {
  if (obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
        removeUndefinedProperties(obj[key]);
      } else if (obj[key] === undefined) {
        delete obj[key];
      }
    });
  }
  return obj;
};

export const removeNullProperties = <T>(obj: T): T =>
    Object.entries(obj).reduce((acc, [key, value]) => {
        if (value !== null) {
            acc[key] = value;
        }
        return acc;
    }, {} as T)