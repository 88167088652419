import React from 'react'
import Footer from '../../components/Footer'
import Cover from '../../components/Cover/Cover'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import ListShop from '../../components/ListShop/ListShop/ListSop.view';
import MarketplaceLanding from '../../components/MarketplaceLanding/MarketplaceLanding.view';
import Splash from '../../components/Splash';

export default function Marketplace() {
    const {
        address: customerAddress,
    } = useAppSelector((state: any) => state.pendingOrderReducer as any);
    const {
        closestShops
    } = useAppSelector((state: any) => state.shopReducer as any);
    const {
        addressTemp,
        isMobile,
        splash
    } = useAppSelector((state: any) => state.componentReducer);
    const dispatch = useAppDispatch();
    const displayListShop = !!(customerAddress || addressTemp?.selectedAddress) && closestShops?.length > 0;
    const shouldDisplayFullScreenBackgroundImg = !displayListShop && !isMobile;
    const listShopStyle = { position: 'relative' as any, minHeight: isMobile ? '64vh' : '52vh' }
    return (
        splash
            ? (<Splash/>)
            : (<>
                {(isMobile && (!closestShops?.length)
                        ? (<MarketplaceLanding/>)
                        : (<Cover marketplaceLanding fullScreenBackgroundImg={shouldDisplayFullScreenBackgroundImg}
                                  dispatch={dispatch}/>)
                )}
                {displayListShop && (<>
                    <div className={`row container mx-auto mt-3 ${!isMobile && 'justify-content-center'}`} style={listShopStyle}>
                        <ListShop isMobile={isMobile} closestShops={closestShops} dispatch={dispatch} selectedAddressOrderProperties={addressTemp}/>
                    </div>
                        <Footer />
                </>)}
            </>)
    )
}