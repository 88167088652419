import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Flag from 'react-world-flags';
import _ from 'lodash';
import { connect } from 'react-redux';
import { countries, codes } from './countries';
import { getCurrentLanguage } from '../../utils';
import '../../css/react-flags-select.css';

class FlagsSelect extends Component {
  constructor(props) {
    super(props);
    const defaultCountry = getCurrentLanguage();
    this.state = {
      openOptions: false,
      defaultCountry,
      filteredCountries: []
    };

    this.toggleOptions = this.toggleOptions.bind(this);
    this.closeOptions = this.closeOptions.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
    this.setCountries = this.setCountries.bind(this);
  }

  toggleOptions() {
    const { disabled, openOptions } = this.state;
    if (!disabled) {
      this.setState({
        openOptions: !openOptions
      });
    }
  }

  toggleOptionsWithKeyboard(evt) {
    evt.preventDefault();
    if (evt.keyCode === 27) {
      // esc key: hide options
      !this.state.disabled &&
        this.setState({
          openOptions: false
        });
    }
  }

  closeOptions(event) {
    if (
      event.target.alt !== 'flag' &&
      event.target !== this.refs.selectedLabel &&
      event.target !== this.refs.selectedFlag &&
      event.target !== this.refs.selectedFlagImg &&
      event.target !== this.refs.flagOptions &&
      event.target !== this.refs.filterText
    ) {
      this.setState({
        openOptions: false
      });
    }
  }

  onSelect(countryCode) {
    this.setState({
      selected: countryCode,
      filter: ''
    });
    this.props.onSelect && this.props.onSelect(countryCode);
    window.Localize.setLanguage(countryCode);
  }

  onSelectWithKeyboard(evt, countryCode) {
    evt.preventDefault();
    if (evt.keyCode === 13) {
      // enter key: select
      this.onSelect(countryCode);
      this.closeOptions(evt);
    } else if (evt.keyCode === 27) {
      // esc key: hide options
      this.toggleOptions();
    }
  }

  updateSelected(countryCode) {
    const isValid = countries[countryCode];

    isValid &&
      this.setState({
        selected: countryCode
      });
  }

  filterSearch(evt) {
    const filterValue = evt.target.value;
    const filteredCountries =
      filterValue &&
      this.state.countries.filter(key => {
        const label = this.props.customLabels[key] || countries[key];
        return label && label.match(new RegExp(filterValue, 'i'));
      });

    this.setState({ filter: filterValue, filteredCountries });
  }

  setCountries() {
    const fullCountries = Object.keys(countries);
    const { localize } = this.props;
    const { sourceLanguage, languages } = localize;
    const countriesToSelect = _.concat([sourceLanguage], languages);
    let selectCountries =
      countriesToSelect &&
      countriesToSelect.filter(country => {
        return countries[country];
      });

    // Filter BlackList
    if (this.props.blackList && selectCountries) {
      selectCountries = fullCountries.filter(countryKey => {
        return (
          selectCountries.filter(country => {
            return countryKey === country;
          }).length === 0
        );
      });
    }

    this.setState(
      {
        countries: selectCountries || fullCountries
      },
      () => {
        const { selected } = this.state;

        if (selected && !this.state.countries.includes(selected)) {
          this.setState({ selected: null });
        }
      }
    );
  }

  componentDidMount() {
    this.setCountries();
    !this.props.disabled && window.addEventListener('click', this.closeOptions);
  }

  componentWillUnmount() {
    !this.props.disabled && window.removeEventListener('click', this.closeOptions);
  }

  render() {
    const isSelected = this.state.selected || this.state.defaultCountry;
    const { selectedSize } = this.props;
    const { optionsSize } = this.props;
    const alignClass = this.props.alignOptions.toLowerCase() === 'left' ? 'to--left' : '';
    return (
      <div
        className={`react-tel-input flag-select ${
          this.props.className ? this.props.className : ''
        }`}
      >
        <button
          ref='selectedFlag'
          style={{ fontSize: selectedSize }}
          className='flag-select__btn'
          onClick={this.toggleOptions}
          onKeyUp={evt => this.toggleOptionsWithKeyboard(evt)}
          disabled={this.props.disabled}
          id='select_flag_button'
          type='button'
          aria-haspopup='listbox'
          aria-expanded={this.state.openOptions}
          aria-labelledby='select_flag_button'
        >
          {isSelected && (
            <span className='flag-select__option flag-select__option--placeholder'>
              <Flag alt='flag' code={codes[isSelected.toLowerCase()]} height={22} width={22} />

              {this.props.showSelectedLabel && (
                <span ref='selectedLabel' className='flag-select__option__label text-white'>
                  {this.props.customLabels[isSelected] || countries[isSelected]}
                </span>
              )}
            </span>
          )}
          {!isSelected && (
            <span className='flag-select__option flag-select__option--placeholder'>
              {this.props.placeholder}
            </span>
          )}
        </button>

        {this.state.openOptions && (
          <ul
            tabIndex='-1'
            role='listbox'
            ref='flagOptions'
            style={{ fontSize: optionsSize }}
            className={`flag-select__options ${alignClass}`}
          >
            {this.props.searchable && (
              <div className='filterBox'>
                <input
                  type='text'
                  placeholder={this.props.searchPlaceholder}
                  ref='filterText'
                  onChange={this.filterSearch}
                />
              </div>
            )}

            {(this.state.filter ? this.state.filteredCountries : this.state.countries).map(
              countryCode => (
                <li
                  key={countryCode}
                  role='option'
                  tabIndex='0'
                  id={`select_flag_${countryCode}`}
                  className={`flag-select__option p-1 ${
                    this.props.showOptionLabel ? 'has-label' : ''
                  }`}
                  onClick={() => this.onSelect(countryCode)}
                  onKeyUp={evt => this.onSelectWithKeyboard(evt, countryCode)}
                >
                  <div className='align-items-center'>
                    <Flag
                      alt='flag'
                      height='20'
                      width='20'
                      code={codes[countryCode.toLowerCase()]}
                    />
                    {this.props.showOptionLabel && (
                      <span className='flag-select__option__label'>
                        {this.props.customLabels[countryCode] || countries[countryCode]}
                      </span>
                    )}
                  </div>
                </li>
              )
            )}
          </ul>
        )}
      </div>
    );
  }
}

FlagsSelect.defaultProps = {
  selectedSize: 16,
  optionsSize: 14,
  placeholder: 'Select a country',
  showSelectedLabel: true,
  showOptionLabel: true,
  alignOptions: 'right',
  customLabels: {},
  disabled: false,
  buttonType: 'button',
  blackList: false,
  searchable: false,
  searchPlaceholder: 'Search'
};

FlagsSelect.propTypes = {
  countries: PropTypes.array,
  blackList: PropTypes.bool,
  customLabels: PropTypes.object,
  selectedSize: PropTypes.number,
  optionsSize: PropTypes.number,
  defaultCountry: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  showSelectedLabel: PropTypes.bool,
  showOptionLabel: PropTypes.bool,
  alignOptions: PropTypes.string,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  searchPlaceholder: PropTypes.string
};

const mapStateToProps = ({ configurationReducer }) => {
  const { localize } = configurationReducer;
  return {
    localize
  };
};

export default connect(mapStateToProps, {})(FlagsSelect);
