import { ORDER_FLOW_TYPE, ORDER_TYPE_CLICK_AND_COLLECT, ORDER_TYPE_CLICK_AND_SEAT, ORDER_TYPE_DELIVERY } from '../../constants';

export const getOrderSteps = (orderType, orderFlowType = ORDER_FLOW_TYPE.CLASSIC) => {
    const steps = ['Réception', 'Préparation'];
    if (orderType === ORDER_TYPE_DELIVERY) {
      if (orderFlowType === ORDER_FLOW_TYPE.REVERSE) {
        return ['Recherche', 'Réception', 'Préparation', 'Livraison'];
      }
      steps.push('En cours de livraison');
    } else if (orderType === ORDER_TYPE_CLICK_AND_COLLECT) {
      steps.push('En attente de récupération');
    } else if (orderType === ORDER_TYPE_CLICK_AND_SEAT) {
      steps.push('Service à table');
    }
      steps.push('Terminé');
    return steps;
  };