import store from '../../redux/store';
import { showSignUp } from '../../redux/actions';
import { ckeckUserAccount } from '../../api/firebase/firebaseAuthentication';
import { EMAIL } from '../../redux/actions/types';

export const isUserHasAccount = async email => {
  await ckeckUserAccount(email).then(result => {
    store.dispatch({ type: EMAIL, payload: email });
    if (result.length === 0) {
      store.dispatch(showSignUp(true));
    }
  });
};