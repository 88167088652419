import _ from 'lodash';
import { initializeCircles, isEqualDeep, sendCloudWatchAlert } from '../../utils';
import { DRIVER, DRIVER_ICON_URL, SHOP_ICON_URL, CUSTOMER_ICON_URL } from '../../constants';
import store from '../../redux/store';

const createMarker = (position, icon, map) => {
  const { google } = window;
  return new google.maps.Marker({
    position,
    map,
    icon
  });
};

export const initializeMap = (component, options = {}) => {
  const { google } = window;
  const defaultOptions = {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true
  };
  const myOptions = { ...defaultOptions, ...options };
  component.map = new google.maps.Map(component.refs.map_canvas, myOptions);
  component.directionsService = new google.maps.DirectionsService();
};
export const initializeMapWithAddress = (address, component) => {
  try {
      const { latitude, longitude } = address;
      const { secondaryColor } = store.getState().configurationReducer
      const center = { lat: latitude, lng: longitude };
      const options = { center };
      initializeMap(component, options);
      initializeCircles(component, center, secondaryColor);
  } catch (error) {
      sendCloudWatchAlert(`Error initializing map with address: ${error}`);
  }
};

export const updateMap = (customerlocation, component) => {
  try {
      const { google } = window;
      const { map } = component;
      const bounds = new google.maps.LatLngBounds();
      const point = {
          lat: parseFloat(customerlocation.latitude),
          lng: parseFloat(customerlocation.longitude)
      };
      new google.maps.Marker({ position: point, map });
      bounds.extend(point);
      map.setCenter({ lat: point.lat, lng: point.lng });
      map.setZoom(14);
      const circleCenter = { lat: point.lat, lng: point.lng };
      component.circle1.setCenter(circleCenter);
      component.circle2.setCenter(circleCenter);
  } catch (error) {
      sendCloudWatchAlert(`Error updating map: ${error}`);
  }
};

export const updateMapDirection = (orderLocation, customerLocation, mode, component) => {
  component.setState({ orderLocation });
  const { google } = window;
  const driverIcon = {
    url: DRIVER_ICON_URL,
    scaledSize: new google.maps.Size(70, 70)
  };
  const customerIcon = {
    url: CUSTOMER_ICON_URL,
    scaledSize: new google.maps.Size(40, 40)
  };
  const shopIcon = {
    url: SHOP_ICON_URL,
    scaledSize: new google.maps.Size(40, 40)
  };
  const { directionsService, map } = component;
  const rendererOptions = {
    map,
    suppressMarkers: true,
    polylineOptions: {
      strokeColor: '#6E747C',
      strokeWidth: 3
    }
  };
  const start = new google.maps.LatLng(orderLocation.latitude, orderLocation.longitude);
  const end = new google.maps.LatLng(customerLocation.latitude, customerLocation.longitude);
  const request = {
    origin: start,
    destination: end,
    travelMode: google.maps.DirectionsTravelMode.DRIVING
  };

  directionsService.route(request, (response, status) => {
    if (status === google.maps.DirectionsStatus.OK) {
      const { orderLocation: orderLocationState } = component.state;

      if (isEqualDeep(orderLocationState, orderLocation)) {
        const route = response.routes[0].legs[0];

        // Reinitialize markeers
        if (component.orderMarker) {
          component.orderMarker.setMap(null);
        }
        if (component.customerMarker) {
          component.customerMarker.setMap(null);
        }

        if (mode === DRIVER) {
          component.orderMarker = createMarker(route.start_location, driverIcon, map);
        } else {
          component.orderMarker = createMarker(route.start_location, shopIcon, map);
        }
        if (!_.isEqual(orderLocation, customerLocation) || mode === DRIVER) {
          component.customerMarker = createMarker(route.end_location, customerIcon, map);
        }
        // Reinitialize directions
        if (component.directionsDisplay) {
          component.directionsDisplay.setMap(null);
        }
        component.directionsDisplay = new google.maps.DirectionsRenderer(rendererOptions);
        component.directionsDisplay.setMap(map);
        component.directionsDisplay.setDirections(response);
      }
    }
  });
};
