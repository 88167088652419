export const initializeMessenger = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  const inlineScript = document.createTextNode(
    "(function(d, s, id){  var js,fjs = d.getElementsByTagName(s)[0];if (d.getElementById(id)) {return;}js = d.createElement(s);js.id = id;js.src = '//connect.facebook.net/en_US/messenger.Extensions.js';fjs.parentNode.insertBefore(js, fjs);})(document, 'script', 'Messenger')"
  );
  script.appendChild(inlineScript);
  document.body.appendChild(script);
};

export const closeWebView = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  const inlineScript = document.createTextNode(
    "MessengerExtensions.requestCloseBrowser(function success() {console.log('Webview closing');},function error(err) {console.log(err);});"
  );
  script.appendChild(inlineScript);
  document.body.appendChild(script);
};
