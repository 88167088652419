import { isTerminal, sendCloudWatchAlert } from '../../utils'
import { formatPhone } from '../../utils/customer'
import { deleteCustomerAccountBackend, updateUser } from '../../api/backend'
import { updateCustomerHubrise } from '../../api/hubrise'
import store from '../../redux/store'
import { listenFirebaseUsersData, unlistenFirebase } from '../../api/firebase/firebaseOn'

export const deleteUserAccount = async component => {
  const { showUpdateInfo, showAccountModal, showMessageModal, userSignOut, user, isUserAnonymous, updateUser } = component.props;
  const { customerId } = user;
  try {
    if (!isTerminal()) {
      unlistenFirebase(customerId);
    }
    await deleteCustomerAccountBackend();
    userSignOut();
    showUpdateInfo(false);
    showAccountModal(false);
    showMessageModal(`Nous vous confirmons la suppression de votre compte.`);
  } catch (error) {
    showMessageModal(`Nous n'avons pas pu supprimer votre compte, veuillez essayer de nouveau.`);
    sendCloudWatchAlert(`Error deleting user account ${error}`);
    if (!isTerminal()) {
      await listenFirebaseUsersData(customerId, isUserAnonymous, updateUser);
    }
  }
};

const updateUserPersonalInfos = async (firstName = '', lastName = '', numero = '', phone = {}) => {
  const { user, userConnected } = store.getState().userReducer;
  if (userConnected) {
    const { customerId } = user;
    await updateUser(customerId, { firstName, lastName, numero, phone })
  }
}

export const updateAccount = async (component, firstName, lastName, numero) => {
  const { updateProfile, showMessageModal, showUpdateInfo, user } = component.props;
  try {
    component.setState({ loading: true });
    numero = formatPhone(numero);
    updateProfile('fistName', firstName);
    updateProfile('lastName', lastName);
    updateProfile('numero', numero);
    await updateCustomerHubrise({ firstName, lastName, numero }, user?.customerId);
    await updateUserPersonalInfos(firstName, lastName, numero, component.phoneObj);
    showUpdateInfo(false);
    component.setState({ loading: false });
    showMessageModal('Votre compte a bien été mis à jour');
  } catch (error) {
    const errorMessage = error;
    sendCloudWatchAlert(`Could not update user info ${errorMessage}`);
    component.setState({ loading: false });
    showMessageModal(
      'La modification de votre compte a échoué. Veuillez vérifier les informations saisies.'
    );
  }
};
