import { useCallback, useState } from 'react';

export type SetPartialStateAction<T> = (updates: Partial<T>) => void;
export function useStateObject<T extends object>(
  initialState: T,
): [T, SetPartialStateAction<T>] {
  const [state, setState] = useState(initialState);

  const setStateObj = useCallback((updates: Partial<T>) => {
    setState((prevState) => ({ ...prevState, ...updates }));
  }, []);

  return [state, setStateObj];
}