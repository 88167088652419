import _ from 'lodash';

export const sortOpenHours = openHours => {
  let index = 0;
  const days = {};
  _.map(openHours, (openHour, day) => {
    if (openHour) {
      switch (day) {
        case 'Monday':
          index = 0;
          break;
        case 'Tuesday':
          index = 1;
          break;
        case 'Wednesday':
          index = 2;
          break;
        case 'Thursday':
          index = 3;
          break;
        case 'Friday':
          index = 4;
          break;
        case 'Saturday':
          index = 5;
          break;
        case 'Sunday':
          index = 6;
          break;
        default:
          break;
      }
      days[index] = openHour;
    }
  });
  return days;
};
