export const SECTION_HORIZONTAL = 'horizontal';
export const SECTION_VERTICAL = 'vertical';
export const PRODUCT_STYLE_GRID = 'grid';
export const PRODUCT_STYLE_LIST = 'list';
export const START_CONTENT = 'start';
export const END_CONTENT = 'end';
export const PAYMENT_CONTENT = 'payment';
export const CHOOSE_ORDER_TYPE_CONTENT = 'choose_order_type';
export const CHOOSE_PAYMENT_TYPE_CONTENT = 'choose_payment_type';
export const INPUT_EASEL_NUMBER_CONTENT = 'input_easel_number';
export const ERROR_MESSAGE_CONTENT = 'error_message';
export const WAIT_TIMEOUT = 30000;

export const INACTIVITY_TIMEOUT = 120000;

export const BLOCK_CONTENT = 'block';

export const TEXT_INPUT_MAX_SIZE = 60;
export const MODAL_SCROLL_OFFSET = 90;
export const UNIT = 16;

export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';
export const LINK = 'link';
export const KIOSK = 'kiosk';
// COLOR constants
export const COLOR_LIGHT_GRAY = 'lightgray';
export const COLOR_MID_GRAY = '#f8f9fa';
export const COLOR_GREY = '#6B757E';
export const COLOR_SECONDARY_TEXT = '#495057';

export const COLOR_GMAIL = '#dd4b39';
export const COLOR_FACEBOOK = '#3B5998';
export const DARK_COLOR = '#202022';
export const TEXT_COLOR = '#383838';
export const WHITE = 'white';
export const BLACK = 'black';
export const DARK_GRAY = '#3f3f3f';
export const QRCODE = 'qrcode';
export const TERMINAL_V = 'terminalv';
export const TERMINAL = 'terminal';
export const TERMINAL_H = 'terminalh';
export const WEBVIEW = 'webview';
export const WEBAPP = 'WEBAPP';
export const GOOGLE = 'google'
export const FACEBOOK = 'facebook'
