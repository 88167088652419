import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import PhoneInput from '../PhoneInput';
import { showSignUp, facebookOrGoogleSignUp } from '../../redux/actions';
import {
  renderErrorMessage,
  isStringNotNull,
  isObjectNotNull,
  formatPhone
} from '../../utils';
import Spinner from '../Spinner';
import Button from '../Button';
import { PRIMARY, UNIT, SECONDARY } from '../../constants';
import { resetEmail } from '../../redux/actions/userAction';
import styles from './SignupForm.styles';

const INITIAL_STATE = {};

class SignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  renderPhoneInput = ({ meta, input }) => {
    const { phone } = this.props;
     const { value, onBlur } = input;
    const className = `form-control ${
      (this.isPhoneIncorrect || !isStringNotNull(phone)) && meta.touched ? 'is-invalid' : ''
    }`;
    return (
      <div className='form-group text-left'>
        <PhoneInput
          onChange={(phone, country, e, formattedPhone) => {
              input.onChange(formattedPhone)
           }}
          onBlur={onBlur}
          value={value}
          className={className}
          isPhoneIncorrect={isPhoneIncorrect => {
            this.isPhoneIncorrect = isPhoneIncorrect;
          }}
          getPhoneObj={(obj) => {
          this.phoneObj = obj
          }}
        />
        {(this.isPhoneIncorrect || !isStringNotNull(phone)) && meta.touched && (
          <div className='invalid-feedback-block text-center'>
            Veuillez fournir un telephone valide
          </div>
        )}
      </div>
    );
  };

  renderInput = ({ input, type, placeholder, meta, description }) => {
    const className = `form-control ${meta.error && meta.touched ? 'is-invalid' : ''}`;
    return (
      <div className='form-group'>
        <input
          type={type}
          autoComplete='new-password'
          className={`${className} dark-modal`}
          placeholder={placeholder}
          {...input}
          style={{ ...styles.inputStyle }}
        />
        <small className='form-check-label'>{description}</small>
        <div className='invalid-feedback'>{meta.error}</div>
      </div>
    );
  };

  renderCheckBox = ({ input, meta, description, id }) => {
    const className = `form-check-input ${meta.error && meta.touched ? 'is-invalid' : ''}`;
    return (
      <div className='form-check mb-2 text-justify '>
        <div className='d-flex align-items-center'>
          <input
            className={`dark-modal ${className}`}
            type='checkbox'
            value=''
            id={id}
            {...input}
          />
          <small className='pl-2'>
            <label
              className='form-check-label text-secondary'
              htmlFor={id}
              style={{ fontSize: UNIT * 0.625 }}
            >
              {description}
            </label>
          </small>
        </div>
        {isStringNotNull(meta.error) && meta.touched && (
          <p className='invalid-feedback-block text-center'>{meta.error}</p>
        )}
      </div>
    );
  };

  onSubmit = ({ password, nom, prenom, phone, newsletter }) => {
  if (this.isPhoneIncorrect || !isStringNotNull(phone)) {
    return;
  }

  const numero = formatPhone(phone);
  const { onSubmit, email, signUpData, facebookOrGoogleSignUp } = this.props;

  if (isObjectNotNull(signUpData)) {
    const { firstName, lastName, uid, loginMethod } = signUpData;
    const profile = {
      firstName,
      lastName,
      email: signUpData.email,
      firstOrder: true,
      id: uid,
      newsletter: newsletter || false,
      numero,
      phone: this.phoneObj,
    };
    facebookOrGoogleSignUp(profile, loginMethod);
  } else {
    onSubmit(email, password, nom, prenom, numero, this.phoneObj, newsletter || false);
  }
};

  render() {
    const {
      loadingConnexion,
      errorMessage,
      handleSubmit,
      name,
      cgu,
      secondaryColor,
      showSignUp,
      signUpData
    } = this.props;
    const cguJSX = (
      <>
        En cliquant sur Inscription, vous acceptez nos{' '}
        <a href={cgu} target='_blank' rel='noopener noreferrer' style={{ color: secondaryColor }}>
          Conditions générales d'utilisation
        </a>
      </>
    );
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        {errorMessage !== '' && (
          <p className='text-danger'>{renderErrorMessage(errorMessage)}</p>
        )}
        {!isObjectNotNull(signUpData) && (
          <div>
            <Field
              name='nom'
              type='text'
              placeholder='Nom'
              component={this.renderInput}
            />
            <Field
              name='prenom'
              type='text'
              placeholder='Prénom'
              component={this.renderInput}
            />
            <Field
              name='password'
              type='password'
              placeholder='Mot de Passe'
              component={this.renderInput}
            />
            <Field
              name='confirmPassword'
              type='password'
              placeholder='Confirmation du mot de passe'
              component={this.renderInput}
            />
          </div>
        )}
        <Field
          name='phone'
          type='text'
          placeholder='Numéro de téléphone'
          component={this.renderPhoneInput}
        />

        <Field
          name='CGU'
          id='CGUCheckbox'
          type='checkbox'
          component={this.renderCheckBox}
          formClass='form-check-input'
          description={cguJSX}
        />
        <Field
          name='newsletter'
          id='newsletterCheckbox'
          type='checkbox'
          component={this.renderCheckBox}
          formClass='form-check-input'
          description={`Je souhaite recevoir les promotions et nouveautés de la part de ${name}`}
        />
        {loadingConnexion && (
          <div className='mt-4'>
            <Spinner />
          </div>
        )}
        <Button
          type={PRIMARY}
          className='btn-block mt-3'
          isSubmit
          style={{ borderRadius: UNIT * 2, fontSize: UNIT * 0.875 }}
        >
          Continuer
        </Button>
        {!loadingConnexion &&
          (!isObjectNotNull(signUpData) && (
            <Button
              type={SECONDARY}
              style={{ borderRadius: UNIT * 2, fontSize: UNIT * 0.875 }}
              className='btn-block mt-2'
              onClick={() => {
                showSignUp(false);
                resetEmail();
              }}
            >
              Retour à la page précédente
            </Button>
          ))}
      </form>
    );
  }
}

const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validate = formValues => {
  const errors = {};
  if (!formValues.nom) {
    errors.nom = 'Merci de mettre un nom';
  }
  if (!formValues.prenom) {
    errors.prenom = 'Merci de mettre un prénom';
  }
  if (!formValues.email) {
    errors.email = 'Merci de fournir un mail';
  } else if (!validateEmail(formValues.email)) {
    errors.email = 'Veuillez fournir un mail valide';
  }

  if (!formValues.password) {
    errors.password = 'Merci de fournir un mot de passe';
  }

  if (!formValues.confirmPassword) {
    errors.confirmPassword = 'Merci de confirmer le mot de passe';
  }

  if (formValues.confirmPassword !== formValues.password) {
    errors.confirmPassword = 'Les mots de passe ne sont pas identiques';
  }

  if (!formValues.CGU) {
    errors.CGU = 'Merci de valider les conditions générales d\'utilisation';
  }

  return errors;
};

const selector = formValueSelector('signupForm');

const mapStateToProps = state => {
  const { configurationReducer, userReducer } = state;
  const phone = selector(state, 'phone');
  const { secondaryColor, name, cgu } = configurationReducer;
  const { loadingConnexion, email, signUpData } = userReducer;
  return {
    secondaryColor,
    loadingConnexion,
    name,
    cgu,
    phone,
    email,
    signUpData
  };
};

SignupForm = connect(mapStateToProps, { showSignUp, facebookOrGoogleSignUp })(SignupForm);

export default reduxForm({
  form: 'signupForm',
  validate
})(SignupForm);
