export const COOKING_IMAGE_URL =
  'https://firebasestorage.googleapis.com/v0/b/lys1-e9cd8.appspot.com/o/webapp%2Fcooking.gif?alt=media&token=9a746da3-a26e-4405-b464-8dc1c602d49f';
export const DELIVERY_IMAGE_URL =
  'https://firebasestorage.googleapis.com/v0/b/lys1-e9cd8.appspot.com/o/webapp%2Flivreur.gif?alt=media&token=5d62cb77-c511-4ad9-a8d4-3f76687d0af5';
export const CONTACTING_IMAGE_URL =
  'https://firebasestorage.googleapis.com/v0/b/lys1-e9cd8.appspot.com/o/webapp%2Fcontacting-2.gif?alt=media&token=79974757-6ed1-4ea6-b674-cd8aeec4311e';
export const WAITER_IMAGE_URL = 'https://dishop-asset-app.s3-eu-west-1.amazonaws.com/waiter.gif';
export const SECURE_PAYMENT_STRIPE_IMAGE_URL =
  'https://firebasestorage.googleapis.com/v0/b/lys1-e9cd8.appspot.com/o/webapp%2Fsecure-stripe-payment-logo.png?alt=media&token=cf33f2c9-39f1-4f30-98aa-bcbb92ef666e';
export const IMAGE_UNAVAILABLE_IMAGE_URL =
  'https://dishop-asset-app.s3-eu-west-1.amazonaws.com/image_unavailable.png';
export const PAYMENT_IMAGE_URL = 'https://dishop-asset-app.s3-eu-west-1.amazonaws.com/payment.gif';
export const DRIVER_ICON_URL =
  'https://dishop-asset-app.s3-eu-west-1.amazonaws.com/Driver_gris.png';
export const CUSTOMER_ICON_URL =
  'https://dishop-asset-app.s3-eu-west-1.amazonaws.com/customer-gris.png';
export const SHOP_ICON_URL = 'https://dishop-asset-app.s3-eu-west-1.amazonaws.com/shop-gris.png';
export const CARD_ICON_URL = 'https://dishop-asset-app.s3-eu-west-1.amazonaws.com/card.png';
export const CLICK_AND_COLLECT_ICON_URL =
  'https://dishop-asset-app.s3-eu-west-1.amazonaws.com/click-collect.png';
export const TIME_ICON_URL = 'https://dishop-asset-app.s3-eu-west-1.amazonaws.com/time.png';
export const PLAYSTORE_IMAGE_URL =
  'https://dishop-asset-app.s3-eu-west-1.amazonaws.com/google-play.png';
export const APPLESTORE_IMAGE_URL =
  'https://dishop-asset-app.s3-eu-west-1.amazonaws.com/app-store.png';
export const MOBILEAPP_IMAGE_URL =
  'https://dishop-asset-app.s3-eu-west-1.amazonaws.com/mobile-app.png';

export const IMAGE_ASPECT_COVER = 'cover';
