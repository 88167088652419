export const DESCRIPTION_ADDRESS = 'Commandez vos plats préférés en quelques clics !';

export const DRIVER = 'Driver';
export const SHOP = 'Shop';

export const PLACEHOLDER_TEXT = '3 rue des jardins, Nice';

export const TEXT_VALIDATION_DELETE_ACCOUNT =
  'Êtes-vous sûr(e) de vouloir supprimer votre compte ? Toutes les informations liées à ce compte seront définitivement supprimées.';

export const TEXT_VALIDATION_DELETE_CB =
  'Êtes-vous sûr(e) de vouloir supprimer votre carte bancaire ? Elle sera définitivement supprimée.';

export const TEXT_CANCEL_ORDER = 'Êtes-vous sûr(e) de vouloir annuler votre commande ?';
export const DEFAULT_TEXT = 'Choisissez un restaurant';