import _ from 'lodash';
import {
  ADD_TO_COMMANDE,
  REMOVE_TO_COMMANDE,
  RESET_COMMANDE,
  UPDATE_ADDRES_IS_LOADED,
  UPDATE_ADDRESS,
  UPDATE_ADDRESS_ATTRIBUTE,
  UPDATE_COMMANDE,
  UPDATE_PENDING_ORDER
} from '../actions/types';

const INITIAL_STATE = {};

export default (state: any = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_TO_COMMANDE:
      return {
        ...state,
        commande: { ...state?.commande, [action.payload.id]: action.payload.product }
      };
    case REMOVE_TO_COMMANDE:
      return { ...state, commande: _.omit(state?.commande, action.payload.id) };
    case RESET_COMMANDE:
      return { ...state, commande: {} };
    case UPDATE_COMMANDE:
      return { ...state, commande: action.payload };
    case UPDATE_PENDING_ORDER:
      return { ...state, ...action.payload };
    case UPDATE_ADDRESS:
      return { ...state, address: action.payload.address, addressIsLoaded: true };
    case UPDATE_ADDRESS_ATTRIBUTE:
      return {
        ...state,
        address: {
          ...state.address,
          [action.payload.attribute]: action.payload.value
        }
      };
    case UPDATE_ADDRES_IS_LOADED:
      return { ...state, addressIsLoaded: action.payload };
    default:
      return state;
  }
};
