import React from 'react';
import { applicationError } from '../../utils';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    applicationError(error.msg || error.toString(), { err: error })
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return <div />; // TODO display proper error screen
    }
    return children;
  }
}

export default ErrorBoundary;
