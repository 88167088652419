import React from 'react';
import pckg from '../../../package.json';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../redux/hook';

function Footer(style) {

  const { cgu, imagesDescription } = useAppSelector(state => state.configurationReducer)//TODO type configurationReducer

  return (
    <footer id='sticky-footer' style={style}>
      <div className='container-fluid text-center border-top py-2'>
        <small>
          <div className='row d-flex align-items-center'>
            <div className='col-sm-4'>
              <p className='no-localization'>{`Version ${pckg.version} ${imagesDescription || '' }`}</p>
            </div>
            <div className='col-sm-4'>
              <p>
                <u>
                  <a href={cgu} target='_blank' rel='noopener noreferrer'>
                    Conditions générales d'utilisation
                  </a>
                </u>

              </p>
              <p>
              <u>
              {!window?.location?.pathname.includes('mentions-legales') && <Link className='topbar__logo' to='/mentions-legales' >
                    <a href={'/mentions-legales/'} target='_blank' rel='noopener noreferrer'>
                    Mentions légales
                    </a>
                    </Link>
}
                </u>
                </p>
            </div>
            <div className='col-sm-4'>
              <p>
                <a target='_blank' href='https://dishop.co' rel='noopener noreferrer'>
                  © Powered by <b>Dishop</b>
                </a>
              </p>
            </div>
          </div>
        </small>
      </div>
    </footer>
  )
}

export default Footer;
