import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import HorairesFooter from '../HorairesFooter';
import { showHoursModal } from '../../redux/actions';
import { initializeMap, updateMap } from './MapSection.services';
import { getTheme } from '../../utils';
import Button from '../Button';
import { PRIMARY, WHITE } from '../../constants';

class MapSection extends Component {
  componentDidMount() {
    const { googleMapsEnabled, shops } = this.props;
    if (googleMapsEnabled) {
      initializeMap(this);
      updateMap(shops, this);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { shops, googleMapsEnabled } = nextProps;
    if (!this.props.googleMapsEnabled && googleMapsEnabled) {
      initializeMap(this);
      updateMap(shops, this);
    }
    if (!_.isEqual(shops, this.props.shops) && googleMapsEnabled) {
      updateMap(shops, this);
    }
  }

  renderMultipleShops = () => {
    const { shops, showHoursModal } = this.props;

    return _.keys(shops).map(key => {
      const shop = shops[key];
      const { address, hours, support } = shop;
      const { street, postalCode, city } = address;
      return (
        <React.Fragment key={key}>
          <div className="row w-100">
            <div className="col-8">
              <h5>{shop.name}</h5>
              <p className="text-muted">
                {street}, {postalCode}, {city}
                <br />
                {support?.number}
              </p>
            </div>
            <div className="col-4 my-auto">
              <Button
                type={PRIMARY}
                onClick={() => {
                  const hoursModalData = {
                    show: true,
                    hours
                  };
                  showHoursModal(hoursModalData);
                }}
              >
                Voir les horaires
              </Button>
            </div>
          </div>
          <hr className="hr-gray hr" />
        </React.Fragment>
      );
    });
  };

  renderSingleShop = () => {
    const { shops } = this.props;

    const key = _.keys(shops)[0];
    const shop = shops[key];

    const { address, hours, support = {} } = shop;
    const { street, postalCode, city } = address;

    return (
      <div className="text-center" key={key}>
        <div className="row mb-3">
          <div className="col">
            <h5>Adresse</h5>
            <p>
              {street}
              <br />
              {postalCode}, {city}
              <br />
              {support.number}
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <h5>Nos Horaires</h5>
            <HorairesFooter hours={hours} />
          </div>
        </div>
      </div>
    );
  };

  renderMapContainer = () => {
    return <div ref="map_section" style={{ height: '70vh' }} />;
  };

  render() {
    const { shops, index, webapp } = this.props;
    const { imageCoverUrl3 } = webapp.images;
    const { style, textColor } = getTheme(index, imageCoverUrl3);

    const nbShops = Object.keys(shops).length

    return (
      <section className={textColor} style={style}>
        <div style={{ backgroundColor: index % 2 ? 'rgba(0,0,0,.8)' : WHITE }}>
          <div className="container">
            <div className="row py-5">
              <div className="col-lg-6 p-0 p-sm-4">
                {nbShops === 1 ? (
                  <>
                    <h1 className="mb-2 text-center text-title">Nous retrouver</h1>
                    <h5 className="mb-4 text-muted text-center">
                      Retrouvez votre magasin proche de chez vous
                    </h5>
                    {this.renderSingleShop()}
                  </>
                ) : nbShops > 1 ? (
                  <>
                    <h1 className="mb-2 text-center text-title">Nos Restaurants</h1>
                    <h5
                      className={`mb-4 text-center ${
                        textColor === 'text-white' ? textColor : 'text-muted'
                      }`}
                    >
                      Retrouvez votre commerce proche de chez vous
                    </h5>
                    <div>{this.renderMultipleShops()}</div>
                  </>
                ) : null}
              </div>
              <div className="col-lg-6 p-0 p-sm-4">{this.renderMapContainer()}</div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ shopReducer, configurationReducer, componentReducer }) => {
  const { shops } = shopReducer;
  const { googleMapsEnabled } = componentReducer;
  const { secondaryColor, webapp } = configurationReducer;
  return {
    shops,
    secondaryColor,
    webapp,
    googleMapsEnabled
  };
};

export default connect(mapStateToProps, { showHoursModal })(MapSection);
