import { ITEM_KEY_COUNTER_SEPARATOR } from '../constants'

export const getCounterFromItemRecursiveKey = itemRecursiveKey => {
  if (!itemRecursiveKey?.includes(ITEM_KEY_COUNTER_SEPARATOR)) {
    return null;
  }
  const cutKey = itemRecursiveKey?.split(ITEM_KEY_COUNTER_SEPARATOR);
  return cutKey?.[cutKey?.length - 1];
}


export const formatCategoryName = ({ shopCategory, parentItemLabel, parentItemRecursiveKey, isMultiple }) => {
  const counter = getCounterFromItemRecursiveKey(parentItemRecursiveKey);
  const categoryCounterName = counter && isMultiple ? ` N°${counter}` : '';
  const parentItemNamePrefix = `${parentItemLabel}${categoryCounterName} - `;
  return shopCategory.name.includes(parentItemNamePrefix)
    ? shopCategory.name
    : `${parentItemNamePrefix}${shopCategory.originalName || shopCategory.name}`;
}