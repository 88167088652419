import React from 'react';
import _ from 'lodash';
import {
  COMPONENT_MAP,
  COMPONENT_FEATURES,
  COMPONENT_BESTSELLER,
  COMPONENT_DESCRIPTION,
  COMPONENT_MOBILEAPP
} from '../../constants';
import Footer from '../../components/Footer';
import CoverSection from '../../components/CoverSection';
import DescriptionSection from '../../components/DescriptionSection';
import BestSellerSection from '../../components/BestSeller/BestSellerSection';
import CaracteristicsSection from '../../components/CaracteristicsSection';
import MapSection from '../../components/MapSection';
import MobileAppSectison from '../../components/MobileAppSection';
import { useAppSelector } from '../../redux/hook';

function Landing() {

  const { landing } = useAppSelector(state => state.configurationReducer);

  const RenderComponents = () => {

    return _.map(landing.components, (component, index) => {
      switch (component) {
        case COMPONENT_BESTSELLER:
          return <BestSellerSection key={index} index={index} />;
        case COMPONENT_FEATURES:
          return <CaracteristicsSection key={index} index={index} />;
        case COMPONENT_MAP:
          return <MapSection key={index} index={index} />;
        case COMPONENT_DESCRIPTION:
          return <DescriptionSection key={index} index={index} />;
        case COMPONENT_MOBILEAPP:
          return <MobileAppSectison key={index} index={index} />;
        default:
          break;
      }
    });
  };

  return (
    <div>
      <CoverSection />
      <RenderComponents/>
      <Footer />
    </div>
  );

}

export default Landing;
