import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import apiService from '@dishopsaas/dishop-backend-api-service'
import { convertHoursToDays, displayPriceFormat, isTerminal, isTerminalVertical, formatCategoryName } from '../../utils'
import { removeToCommande } from '../../redux/actions'
import { sendGoogleAnalytics } from '../../api'
import styles from './OrderItem.styles'
import { UNIT } from '../../constants/configuration'
import { findOptionParent } from './OrderItem.services'

class OrderItem extends React.Component {
 renderOptions = () => {
  const { cartItem } = this.props;
  const { items } = cartItem;
 if (items) {
  return _.map(items, item => {
    if (item) {
    const { options, categoryKey } = item;
    const optionLabels = _.map(options, 'name');
    const isChild = _.some(items, childItem => childItem?.categoryKey !== categoryKey && categoryKey.includes(childItem?.categoryKey));
    const prefix = isChild ? '- ' : '* ';
    const fontSize = isChild ? UNIT * 0.85 : UNIT * 0.9;
    const className = isChild ? 'ml-4' : 'ml-2';
    let { categoryName } = item
    if (options && isChild) {
    _.forEach(options, option => {
      const optionParent = findOptionParent(option, items);
      if (optionParent) {
        categoryName = formatCategoryName({
          shopCategory: { name: item.categoryName },
          parentItemLabel: optionParent.label,
          parentItemRecursiveKey: optionParent.itemRecursiveKey,
          isMultiple: optionParent.categoryMultiple
        });
      }
    });
}
    const itemRenderString = `${prefix}${categoryName}: ${optionLabels.join(',')}`;
    return (
      <div key={item.categoryId}>
        <span className={className} style={{ fontSize, display: 'inline-block' }}>
          {itemRenderString}
        </span>
        <br />
      </div>
    );
      }
  });
     }
};

  renderInstruction = () => {
    const { cartItem } = this.props;

    if (cartItem.instruction) {
      return (
        <div>
          <span className="ml-2" style={{ fontSize: UNIT * 0.9 }}>
            - Instructions : {cartItem.instruction}
          </span>
        </div>
      );
    }
  };

  deleteItem = async () => {
    const {
      removeToCommande,
      cartItemKey,
      userConnected,
      customerId,
      isUserAnonymous,
      cartItem
    } = this.props;
    const { nb, name, price, position, sectionName, key } = cartItem;
    if ((userConnected || isUserAnonymous) && !isTerminal()) {
      await apiService.pendingOrdersDelete([customerId, 'commande', cartItemKey])
    } else {
      removeToCommande(cartItemKey);
    }
    sendGoogleAnalytics('remove_from_cart', {
      items: [
        {
          id: key,
          name,
          price,
          position,
          category: sectionName,
          quantity: nb
        }
      ]
    });
  };

  render() {
    const { cartItem, secondaryColor, deleteOption } = this.props;
    const { nb, unit = null, name, price, preparationTime } = cartItem;
    return (
      <>
        <div>
          <div
            className={`row ${isTerminalVertical() ? 'd-flex align-items-center' : 'justify-content-between'}`}
          >
            {deleteOption && (
              <div className={`${isTerminalVertical() ? 'col-1 text-center pt-1' : 'mr-2'}`}>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    this.deleteItem();
                  }}
                >
                  <i
                    className="far fa-trash-alt"
                    style={
                      isTerminalVertical()
                        ? { color: secondaryColor, fontSize: UNIT * 1.25 }
                        : { color: secondaryColor }
                    }
                  />
                </a>
              </div>
            )}
            <div className="col-8">
              {unit ? (
                <p
                  style={
                    isTerminalVertical()
                      ? { ...styles.receiptProductItemTitleStyle }
                      : { ...styles.receiptProductItemTitleDefaultStyle }
                  }
                >
                  <span className="no-localization">
                    {nb} {unit}
                  </span>{' '}
                  de {name}
                </p>
              ) : (
                <p
                  style={
                    isTerminalVertical()
                      ? { ...styles.receiptProductItemTitleStyle }
                      : { ...styles.receiptProductItemTitleDefaultStyle }
                  }
                >
                  <span className="no-localization">x {nb}</span> {name}
                </p>
              )}
            </div>
            <div className={`my-auto text-right col-3 ${isTerminalVertical() && 'pr-sm-3'}`}>
              {displayPriceFormat(price)}
            </div>
          </div>
          <div className={`${isTerminalVertical() ? 'pl-5' : 'pl-3 pl-sm-4'}`}>
            {this.renderOptions()}
            {this.renderInstruction()}
            {preparationTime > 0 && deleteOption && (
              <p className="ml-2 text-warning mb-0" style={{ fontSize: UNIT * 0.9 }}>
                {`~ ${convertHoursToDays(preparationTime) > 0
                  ? `${convertHoursToDays(preparationTime)} jour(s)`
                  : `${preparationTime} heure(s)`} de préparation`}
              </p>
            )}
          </div>
        </div>
        <hr className='my-4 hr' />
      </>
    );
  }
}

const mapStateToProps = ({ configurationReducer, userReducer }) => {
  const { secondaryColor } = configurationReducer;
  const { userConnected, user, isUserAnonymous } = userReducer;
  return {
    secondaryColor,
    userConnected,
    customerId: user.customerId,
    isUserAnonymous
  };
};

export default connect(mapStateToProps, { removeToCommande })(OrderItem);
