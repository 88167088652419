const styles = color => {
  const style = {
    navBar: {
      backgroundColor: color,
      opacity: '100%',
      transition: 'all 0.3s ease'
    },
    navBar2: {
      backgroundColor: `${color}AA`,
      boxShadow: '1px 1px 35px 0 rgba(51, 51, 51, 0.4)',
      transition: 'all 0.3s ease'
    },
    navBarMarketplace: {
      height: window.innerHeight * 0.085,
      backgroundColor: color,
      opacity: '100%',
      transition: 'all 0.3s ease',
      paddingTop: 0,
      paddingBottom: 0
    }
  };
  return style;
};

export default styles;
