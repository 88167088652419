import { sendCloudWatchAlert, sendCloudWatchLogs } from '../../utils'
import { retrieveCard, updateCustomerPaymentCardBackend, updateDefaultCard } from '../../api'
import { deleteCustomerPaymentCardBackend } from '../../api/backend'
import store from '../../redux/store'
import { showMessageModal, showPaymentModal } from '../../redux/actions'

export const changeDefaultCreditCard = async (component, newCardId) => {
  try {
    sendCloudWatchLogs(`Updating default card to ${newCardId}`);
    const { user, userConnected } = store.getState().userReducer;
    const { customerId } = user;
    component.setState({ actionModal: null, loading: true });
    if (userConnected) {
      const { stripeCustomerId } = user;
      const card = await retrieveCard({
        cardId: newCardId,
        customerId
      });
      if (card?.customer) {
        sendCloudWatchLogs('Updating default source on stripe');
        await updateDefaultCard({
          cardId: newCardId,
          stripeCustomerId,
          customerId
        });
      }
      await updateCustomerPaymentCardBackend(newCardId);
    }
    component.setState({ loading: false, selectedCard: {} });
    store.dispatch(showPaymentModal(false));
  } catch (error) {
    sendCloudWatchAlert(`Error updating default credit card: ${error}`);
    store.dispatch(
      showMessageModal(
        'Nous n\'avons pas pu modifier votre carte par défaut. Veuillez réessayer ou ajouter une nouvelle carte bancaire.'
      )
    );
    component.setState({ loading: false });
  }
};

export const deleteCB = async (component, cardId) => {
  const { showMessageModal } = component.props;
  try {
    await deleteCustomerPaymentCardBackend(cardId);
    showMessageModal(`Nous vous confirmons la suppression de votre carte bancaire`);
  } catch (error) {
    showMessageModal(
      `Nous n'avons pas pu supprimer votre carte bancaire, veuillez essayer de nouveau.`
    );
    sendCloudWatchAlert(`Error deleting user CB ${error}`);
  }
};
