import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { showMessageModal } from '../../redux/actions';
import ModalBorder from '../ModalBorder';
import Spinner from '../Spinner';
import Button from '../Button';
import { PRIMARY, SECONDARY } from '../../constants';

class MessageModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  render() {
    const { messageModal, dataModal = {}, name, showMessageModal } = this.props;
    const { onClick, showCancel, text, buttonName } = dataModal;
    const { loading } = this.state;
    return (
      <Modal size='sm' show={messageModal !== null} onHide={() => showMessageModal(null)}>
        <ModalBorder>
          <Modal.Header closeButton className='dark-modal'>
          <h5 className={`text-secondary mb-0 label ${text && 'text-center'}`}>{text || name}</h5>
          </Modal.Header>
          <Modal.Body className='dark-modal'>
            <p className='text-center label'>{messageModal}</p>
          </Modal.Body>
          <Modal.Footer className='justify-content-center border-top-0 pt-0 dark-modal'>
            {loading && <Spinner />}
            {!loading && showCancel && (
              <Button type={SECONDARY} onClick={() => showMessageModal(null)}>
                Annuler
              </Button>
            )}
            {!loading && (
              <Button
                type={PRIMARY}
                onClick={async () => {
                  if (onClick) {
                    try {
                      this.setState({ loading: true });
                      await onClick();
                      this.setState({ loading: false });
                    } catch (error) {
                      this.setState({ loading: false });
                    }
                  } else {
                    showMessageModal(null);
                  }
                }}
              >
                {onClick ? 'Valider' : text ? buttonName : 'Ok'}
              </Button>
            )}
          </Modal.Footer>
        </ModalBorder>
      </Modal>
    );
  }
}

const mapStateToProps = ({ configurationReducer, componentReducer }) => {
  const { messageModal, dataModal } = componentReducer;
  const { secondaryColor, name, onClickAction } = configurationReducer;
  return { secondaryColor, name, onClickAction, messageModal, dataModal };
};

export default connect(mapStateToProps, { showMessageModal })(MessageModal);