import { ITEM_KEY_CATEGORY_SEPARATOR } from '../../constants'
import _ from 'lodash'

export const findOptionParent = (option, items) => {
  const { categoryKey: optionKey } = option
  const lastOptionKey = _.last(optionKey.split(ITEM_KEY_CATEGORY_SEPARATOR));

  let foundOptionParent = null;
  _.forEach(items, category => {
    if (!foundOptionParent && category?.options) {
      foundOptionParent = _.find(category.options, option => {
        if (option.subcategories) {
           const lastItemRecursiveKey = _.last(option.itemRecursiveKey.split(ITEM_KEY_CATEGORY_SEPARATOR));
          return (option.subcategories.includes(lastOptionKey) && optionKey.includes(lastItemRecursiveKey)) ;
        }
        return false;
      });
     foundOptionParent = foundOptionParent && { ...foundOptionParent, categoryMultiple: category.categoryMultiple };
    }
  });

  return foundOptionParent
};