import React from 'react';
import _ from 'lodash';
import CarouselReactBootstrap from 'react-bootstrap/Carousel';

const Carousel = ({ slides }) => {
  return (
    <CarouselReactBootstrap controls={false}>
      {_.map(slides, (slide, index) => {
        return (
          <CarouselReactBootstrap.Item>
            <CarouselReactBootstrap.Caption>
              <h1 className='mb-4'>Cliquer pour commencer !</h1>
            </CarouselReactBootstrap.Caption>
            <img className='d-block w-100 h-100' src={slide} alt={index} />
          </CarouselReactBootstrap.Item>
        );
      })}
    </CarouselReactBootstrap>
  );
};

export default Carousel;
