import { Buffer } from 'buffer';

const iv = 'e0c1aef6e53b50c4d2a3a42a92d72e97'; // TODO move to environment variable with CI
const encryptionKey = '181212ccbba04bf381bce657c5a7986a181212ccbba04bf381bce657c5a7986a'; // TODO move to environment variable with CI
const algorithm = {
    name: 'AES-CBC',
    iv: new Uint8Array(Buffer.from(iv, 'hex'))
};

const getKey = async () => {
    return crypto.subtle.importKey(
        'raw',
        new Uint8Array(Buffer.from(encryptionKey, 'hex')),
        'AES-CBC',
        false,
        ['decrypt']
    );
};

export const decrypt = async (encryptedText: string): Promise<string> => {
    const key = await getKey();
    const encryptedBuffer = Uint8Array.from(Buffer.from(encryptedText, 'hex')).buffer;
    const decryptedBuffer = await crypto.subtle.decrypt(algorithm, key, encryptedBuffer);
    return new TextDecoder().decode(decryptedBuffer);
};