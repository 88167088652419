import React from 'react';
import { connect } from 'react-redux';
import { isDarkTheme } from '../../utils';
import { UNIT, COLOR_LIGHT_GRAY } from '../../constants/configuration';

const INITIAL_STATE = {};

class ModalBorder extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  render() {
    const {
      children,
      color = COLOR_LIGHT_GRAY,
      className,
      style = {},
      id,
      onScroll,
      theme,
      width,
      margin
    } = this.props;
    return (
      <div
        className={`dishop-iso ${className}`}
        style={{
          borderRadius: UNIT * 2,
          overflow: 'auto',
          border: `${!isDarkTheme() ? '0px' : '1px'} solid ${color}`,
          width,
          margin,
          ...style
        }}
        id={id}
        onScroll={onScroll}
      >
        <div
          className={`${theme}-theme`}
          style={{
            borderRadius: UNIT * 2,
            ...style
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ configurationReducer }) => {
  const { marketplace = {}, theme } = configurationReducer;
  return {
    marketplace,
    theme
  };
};

export default connect(mapStateToProps)(ModalBorder);