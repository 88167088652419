import { UPDATE_DRIVERS_DATA } from '../actions/types';

const INITIAL_STATE = {
  drivers: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_DRIVERS_DATA:
      const { chargeId, driverData } = action.payload;
      return {
        ...state,
        drivers: { ...state.drivers, [chargeId]: driverData }
      };
    default:
      return state;
  }
};
