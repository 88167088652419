import {
  getCurrentNbOption,
  getNbInCartOfProduct,
  getOptionAssociatedProduct,
  getStockRemaining,
  isProductDisabled,
  isStringNotNull
} from '../../utils'
import { generateItemRecursiveKey, isUniqueOption } from '../ListProducts/ProductModal/ProductModal.services.js'
import _ from 'lodash'

export const isCheckedOrSelected = (productItems, index, item) =>
  !!_.find(productItems?.[index]?.options, option =>
    option?.itemRecursiveKey && option.itemRecursiveKey === generateItemRecursiveKey(item)
  );

export const getStockOption = option => {
  let stockOption = null;
  const product = getOptionAssociatedProduct(option);
  if (product && parseInt(product.stock, 10) >= 0) {
    stockOption = product.stock;
  }
  return stockOption;
};

export const getStockRemainingOption = (options, option, nbProduct) => {
  let stockRemainingOption = null;
  const stockOption = getStockOption(option);
  if (parseInt(stockOption, 10) >= 0) {
    const { key, productKey } = option;
    const nbOption = getCurrentNbOption(options, key);
    const nbInCart = getNbInCartOfProduct(key, productKey);
    stockRemainingOption = getStockRemaining(stockOption, nbInCart, nbProduct * nbOption);
    if (
      stockRemainingOption > 0 &&
      getStockRemaining(stockOption, nbInCart, nbProduct * (nbOption + 1)) < 0
    ) {
      stockRemainingOption = 0;
    }
  }
  return stockRemainingOption;
};

export const optionHasNotEnoughStock = (component, item, nbProduct) => {
  const { productItems } = component.props;
  let stockRemainingOption = false;
  let notEnoughStock = false;
  const { productKey } = item;
  if (productKey) {
    const product = getOptionAssociatedProduct(item);
    if (product) {
      stockRemainingOption = getStockRemainingOption(productItems, item, nbProduct);
      notEnoughStock = isProductDisabled(product);
    }
  }
  return notEnoughStock || parseInt(stockRemainingOption, 10) <= 0;
};

export const getOptionData = item => {
  const { productKey } = item;
  let result = {};
  if (productKey) {
    const product = getOptionAssociatedProduct(item);
    if (product) {
      const { image, description } = product;
      result = { image, description };
    }
  }
  return result;
};

export const handleCounterClick = (component, item, category, action) => {
  if (!isStringNotNull(item.price)) {
    item.price = 0;
  }
  const { index, addItem, removeItem } = component.props;
  if (action === 'add') {
    addItem(index, category, item, true);
  } else {
    removeItem(index, category, item, true);
  }
};

export const handleCheckboxClick = (component, item) => {
  const { index, category, addItem, removeItem, productItems } = component.props;
  if (!isStringNotNull(item.price)) {
    item.price = 0;
  }
  const checked = isCheckedOrSelected(productItems, index, item);
  if (!checked) {
    addItem(index, category, item);
  } else {
    removeItem(index, category, item);
  }
};

export const handleRadioButtonClick = (component, item) => {
  const { index, category, addUniqueItem } = component.props;
  if (!isStringNotNull(item.price)) {
    item.price = 0;
  }
  addUniqueItem(index, category, item);
};

export const generateOptionChoiceMessage = (category) => {
  let choiceMessage = category?.min
    ? '* Champ Obligatoire'
    : '';
  if (!isUniqueOption(category)) {
    choiceMessage += category?.min
      ? ' - '
      : '';
    if (category?.min && category?.min < category?.max) {
      choiceMessage += `Entre ${category.min} et ${category.max} choix`;
    } else if (category?.min && category?.min === category?.max) {
      choiceMessage += `Choisissez ${category.max} choix`;
    } else if (category?.min && !category?.max) {
      choiceMessage += `Choisissez au moins ${category.min} choix`;
    } else if (category?.max > 1) {
      choiceMessage += `Choisissez jusqu'à ${category.max}`;
    }
  }
  return choiceMessage;
};

export const getFreeItem = (options, item, category) => {
  const { optionFree, multiple } = category;
  const freeItems = []
  const optionsArray = _.values(options);
  _.map(optionsArray, (elm, index) => {
    if (optionFree && index < optionFree) {
      freeItems.push(elm.key)
    }
  })
  const isFreeItem = multiple
    ? freeItems.includes(item?.key) && freeItems.length < optionFree
    : freeItems.includes(item?.key);

  item.isFree = isFreeItem || optionsArray.length < optionFree;
  return item

}
