import {
    getAddressDetails,
    getAddressFormatted,
    getClosestShops,
    isAddressValid,
    isQrCode,
    parseAddress,
    renderErrorMessage,
    sendCloudWatchAlert
} from '../../../utils';
import {
    NO_SHOP_AVAILABLE_ERROR_MESSAGE,
    NOT_FOUND_INDEX,
    ORDER_TYPE_DELIVERY,
    PLACEHOLDER_TEXT,
    UNAVAILABLE_ADDRESS,
    WRONG_ADDRESS
} from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';
import { useCallback, useEffect, useRef, useState } from 'react';
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete/dist';
import Geocode from 'react-geocode/lib';
import { updateAddressTemp, updateClosestShops, updateCustomerAddressCheck } from '../../../redux/actions';
import { checkDeliveryAddress } from '../../../api';
import { CUSTOMER_ADDRESS_CHECK_VALUES } from '../../../redux/reducers/componentReducer';
import { isCustomerAddressDefined } from '../../../utils/customer-address-and-shops';

const PLACE_HOLDER_LETTER_DEBOUNCE_TIME = 200;
const getShops = async (address: any, orderType: string) => (await getClosestShops(address, orderType))
    ?.filter(shop => !shop?.closed);

export const useAddressInputMarketplace = () => {
    const dispatch = useAppDispatch();
    const {
        secondaryColor,
        orderTypes: defaultShopOrderTypes,
        qrcode
    } = useAppSelector(state => state.configurationReducer);
    const orderTypes = isQrCode() ? qrcode?.orderTypes : defaultShopOrderTypes
    const { isMobile, googleMapsEnabled, addressTemp } = useAppSelector(state => state.componentReducer);
    const {
        address: customerAddress,
        orderType: pendingOrderOrderType
    } = useAppSelector(state => state.pendingOrderReducer);
    const [isCustomerAddress, setIsCustomerAddress] = useState((addressTemp?.isCustomerAddress !== undefined && !isMobile) ? addressTemp?.isCustomerAddress : !!customerAddress);
    const [typedAddress, setTypedAddress] = useState((!isMobile && addressTemp?.lastTypedAddressValidValue) || '');
    const [lastTypedAddressValidValue, setLastTypedAddressValidValue] = useState((!isMobile && addressTemp?.lastTypedAddressValidValue) || '');
    const [selectedAddressPlaceId, setSelectedAddressPlaceId] = useState((!isMobile && addressTemp?.selectedAddressPlaceId) || null)
    const [getShopsIsLoading, setGetShopsIsLoading] = useState(false)
    const defaultOrderTypeIndex = orderTypes?.findIndex((type: string) => (addressTemp?.orderType !== undefined ? type === addressTemp?.orderType : type == pendingOrderOrderType));
    const [orderTypeChoiceIndex, setOrderTypeChoiceIndex] = useState(defaultOrderTypeIndex !== NOT_FOUND_INDEX ? defaultOrderTypeIndex : 0)
    const [errorMessage, setErrorMessage] = useState(null)
    const inputElementRef = useRef(null);
    const [isFirstTimeFetching, setIsFirstTimeFetching] = useState(true);
    const [displayIsSavingSpinner, setDisplayIsSavingSpinner] = useState(false);

    const orderType = orderTypes[orderTypeChoiceIndex];
    const hasErrorAddressMessage = [UNAVAILABLE_ADDRESS, WRONG_ADDRESS].includes(errorMessage);
    const isFilledAddressValid = isCustomerAddress
        ? isCustomerAddressDefined(customerAddress)
        : !!selectedAddressPlaceId && typedAddress === lastTypedAddressValidValue && lastTypedAddressValidValue?.length && !hasErrorAddressMessage;
    const shouldRenderAddressSearchInput =
        (orderType === ORDER_TYPE_DELIVERY || !isCustomerAddress) &&
        googleMapsEnabled &&
        !isCustomerAddress;

    const getAddressFromCustomerAddress = useCallback(async (customerAddress: any) => {
            if (!isCustomerAddressDefined(customerAddress)) {
                return [];
            }
            return customerAddress?.placeId
                ? geocodeByPlaceId(customerAddress.placeId)
                : geocodeByAddress(getAddressFormatted(customerAddress))
        }, [customerAddress]
    );

    const fetchAdressData = useCallback(async (selectedAddressPlaceId: string, customerAddress: any) => {
        try {
            if (orderType === ORDER_TYPE_DELIVERY) {
                setGetShopsIsLoading(true);
                dispatch(updateAddressTemp({
                    getShopsIsLoading: true
                }));
            }
            const foundPlaces = await (selectedAddressPlaceId
                    ? geocodeByPlaceId(selectedAddressPlaceId)
                    : getAddressFromCustomerAddress(customerAddress)
            );
            const firstFoundPlace = foundPlaces?.length
                ? foundPlaces[0]
                : null;
            const foundSelectedAddress = foundPlaces?.length
                ? parseAddress(firstFoundPlace?.address_components)
                : null;
            if (!foundSelectedAddress) {
                return null;
            }
            const { lat, lng } = await getLatLng(firstFoundPlace);
            const addresses = await Geocode.fromLatLng(lat, lng);
            const { district, subLocality } = getAddressDetails(addresses.results);
            foundSelectedAddress.district = district;
            foundSelectedAddress.subLocality = subLocality;
            foundSelectedAddress.location = {
                latitude: lat,
                longitude: lng
            };
            foundSelectedAddress.placeId = firstFoundPlace?.place_id;
            return foundSelectedAddress;
        } catch (error) {
            await handleCatchError(error);
        }
    }, [orderType]);

    const getAvailableClosestShopsFromAddress = useCallback(async (orderType: string, selectedAddress: any) => {
        try {
            if (orderType === ORDER_TYPE_DELIVERY) {
                setGetShopsIsLoading(true);
                dispatch(updateAddressTemp({
                    getShopsIsLoading: true
                }));
            }
            if (!isAddressValid(selectedAddress, orderType)) {
                setGetShopsIsLoading(false);
                dispatch(updateAddressTemp({
                    getShopsIsLoading: false
                }));
                return await getShopsIfInvalidAddress();
            }
            const shops = await getShops(selectedAddress, orderType);
            if (!shops?.length) {
                dispatch(updateClosestShops([]));
                setErrorMessage(orderType === ORDER_TYPE_DELIVERY
                    ? UNAVAILABLE_ADDRESS
                    : NO_SHOP_AVAILABLE_ERROR_MESSAGE);
                setGetShopsIsLoading(false);
                dispatch(updateAddressTemp({
                    getShopsIsLoading: false
                }));
                return [];
            }
            if (orderType !== ORDER_TYPE_DELIVERY) {
                dispatch(updateClosestShops(shops));
                setGetShopsIsLoading(false);
                dispatch(updateAddressTemp({
                    getShopsIsLoading: false
                }));
                return shops;
            }
            const deliverableShops = await getDeliverableShops(selectedAddress, shops);
            if (!deliverableShops?.length) {
                dispatch(updateClosestShops([]));
                setErrorMessage(UNAVAILABLE_ADDRESS)
                setGetShopsIsLoading(false);
                dispatch(updateAddressTemp({
                    getShopsIsLoading: false
                }));
                return [];
            }
            dispatch(updateClosestShops(deliverableShops));
            setGetShopsIsLoading(false);
            return deliverableShops;
        } catch (error) {
            await handleCatchError(error);
            setGetShopsIsLoading(false);
        }
    }, [customerAddress, orderTypeChoiceIndex, selectedAddressPlaceId, isCustomerAddress, errorMessage]);

    const getShopsIfInvalidAddress = useCallback(async () => {
        if (orderType !== ORDER_TYPE_DELIVERY) {
            const shops = await getShops(null, orderType);
            dispatch(updateClosestShops(shops));
            setErrorMessage(shops?.length ? null : NO_SHOP_AVAILABLE_ERROR_MESSAGE)
            return shops;
        }
    }, [orderType])

    const handleAnimatePlaceHolderText = useCallback(() => {
        let str = '';
        let n = 0;
        const animate = () => {
            if (inputElementRef.current) {
                if (n < PLACEHOLDER_TEXT.length) {
                    str += PLACEHOLDER_TEXT[n];
                    inputElementRef.current.placeholder = str;
                    n += 1;
                } else {
                    str = '';
                    n = 0;
                    inputElementRef.current.placeholder = str;
                    setTimeout(animate, PLACE_HOLDER_LETTER_DEBOUNCE_TIME * 5);
                    return;
                }
            }
        }
        const interval = setInterval(animate, PLACE_HOLDER_LETTER_DEBOUNCE_TIME);
        return () => clearInterval(interval);
    }, [orderTypeChoiceIndex]);

    const handleValidationButtonClick = useCallback(async () => {
        if (getShopsIsLoading || displayIsSavingSpinner) {
            return;
        }
        if (!isCustomerAddress && !selectedAddressPlaceId) {
            setErrorMessage(WRONG_ADDRESS);
            return;
        }
        setDisplayIsSavingSpinner(true)
        getShopsFromFilledAddress()
            .then(() => {
                setDisplayIsSavingSpinner(false)
                dispatch(updateCustomerAddressCheck(CUSTOMER_ADDRESS_CHECK_VALUES.VALID));
            })
    }, [orderTypeChoiceIndex, selectedAddressPlaceId, getShopsIsLoading, isCustomerAddress]);

    useEffect(() => {
        if (getShopsIsLoading) {
            return;
        }
        if (!isCustomerAddressDefined(customerAddress)) {
            setIsFirstTimeFetching(false);
            return;
        }
        if (isMobile) {
            setIsCustomerAddress(!!customerAddress);
            return;
        }
        const shouldFetchData = (
            addressTemp?.isCustomerAddress !== undefined
                ? addressTemp?.isCustomerAddress
                : (isCustomerAddressDefined(customerAddress) && !!pendingOrderOrderType)
        ) && !addressTemp?.orderType;
        if (shouldFetchData) {
            setIsCustomerAddress(true);
            setOrderTypeChoiceIndex(defaultOrderTypeIndex || 0);
            fetchAdressData(selectedAddressPlaceId, customerAddress)
                .then((initialAddress: any) => getAvailableClosestShopsFromAddress(pendingOrderOrderType, initialAddress)
                    .then(() => {
                            setIsFirstTimeFetching(false);
                            dispatch(updateAddressTemp({
                                selectedAddress: initialAddress,
                                lastTypedAddressValidValue,
                                orderType: pendingOrderOrderType,
                                selectedAddressPlaceId,
                                getShopsIsLoading: false,
                                isCustomerAddress: true,
                            }))
                        }
                    ))
        } else if (!addressTemp?.selectedAddress && !isCustomerAddressDefined(customerAddress)) {
            getAvailableClosestShopsFromAddress(orderType, null).then(() => {
                setIsFirstTimeFetching(false);
            })
        } else {
            setIsFirstTimeFetching(false);
        }
    }, [customerAddress, pendingOrderOrderType]);

    const getShopsFromFilledAddress = async () => {
        return fetchAdressData(selectedAddressPlaceId, customerAddress)
            .then(async (foundAddress: any) => getAvailableClosestShopsFromAddress(orderType, foundAddress)
                .then((shops: any[]) => {
                    if (shops?.length) {
                        dispatch(updateAddressTemp({
                            selectedAddress: foundAddress,
                            lastTypedAddressValidValue,
                            orderType,
                            selectedAddressPlaceId,
                            isCustomerAddress,
                            getShopsIsLoading: false
                        }));
                    } else {
                        dispatch(updateAddressTemp({
                            orderType,
                            isCustomerAddress,
                            getShopsIsLoading: false
                        }));
                        dispatch(updateClosestShops([]))
                    }
                }))
    };

    useEffect(() => {
        if (isMobile) {
            return;
        }
        const selectedAddressUnchanged = (selectedAddressPlaceId === addressTemp?.selectedAddressPlaceId) &&
            selectedAddressPlaceId?.length &&
            orderType === addressTemp?.orderType;
        if ((selectedAddressUnchanged && !isMobile) || (!selectedAddressPlaceId && !isCustomerAddress) || isFirstTimeFetching) {
            dispatch(updateAddressTemp({
                getShopsIsLoading: false
            }));
            return;
        }
        getShopsFromFilledAddress();
    }, [selectedAddressPlaceId, isCustomerAddress, orderTypeChoiceIndex]);

    useEffect(() => {
        return handleAnimatePlaceHolderText();
    }, [orderTypeChoiceIndex]);

    const handleCatchError = async (error: any) => {
        const errorMessage = String(error)
        if (errorMessage) {
            if (!errorMessage.includes('déployé sur') && !errorMessage.includes('incorrecte')) {
                sendCloudWatchAlert(`Could not add address: ${errorMessage}`)
            }
            if (errorMessage.includes('ZERO_RESULTS')) {
                setErrorMessage(UNAVAILABLE_ADDRESS)
            }
            if (errorMessage.includes('CANT_GEOCODE_ADDRESS')) {
                setErrorMessage(renderErrorMessage('stuart/address-not-served'));
            }
            if (!errorMessage?.includes('INVALID_REQUEST') && !errorMessage?.includes('ZERO_RESULTS')) {
                sendCloudWatchAlert(`Could not get address ${error}`)
            }
        }
    }

    const getDeliverableShops = async (address: any, shops: any): Promise<any[]> => {
        try {
            const deliverableShops = await Promise.all(
                shops?.map(async (shop: any) => {
                    if (shop?.configuration?.uberDirect?.enabled || shop?.configuration?.stuart?.enabled) {
                        return await checkDeliveryAddress(address, shop?.shopId) ? shop : null
                    }
                    return shop
                })
            );
            return deliverableShops?.filter((shop: any) => !!shop);
        } catch (error) {
            await handleCatchError(error);
            return [];
        }
    }

    const handleAddressTypeSelection = async ({ target: target }) => {
        const isCustomerAddressChoosen = target.value === 'true';
        setIsCustomerAddress(isCustomerAddressChoosen);
        setErrorMessage(null)
        if (isCustomerAddressChoosen) {
            setSelectedAddressPlaceId(null)
            setTypedAddress('');
        } else {
            setTypedAddress('')
        }
    };

    const handleOrderTypeSelection = async (selectedOrderTypeIndex: number) => {
        if (getShopsIsLoading) {
            return;
        }
        setOrderTypeChoiceIndex(selectedOrderTypeIndex);
        setErrorMessage(null)
        if (!addressTemp) {
            return;
        }
        if ((typedAddress !== lastTypedAddressValidValue || !lastTypedAddressValidValue?.length) && !isCustomerAddress) {
            setSelectedAddressPlaceId(null)
            setTypedAddress('')
            setLastTypedAddressValidValue('')
            dispatch(updateClosestShops([]))
            return;
        }
    }

    const handleAddressSelection = async (selectedAddressValue: any, placeId: string) => {
        if (!placeId) {
            return
        }
        if ((placeId === selectedAddressPlaceId && placeId?.length)) {
            setTypedAddress(selectedAddressValue);
            if (!errorMessage && !isMobile) {
                await getAvailableClosestShopsFromAddress(orderType, addressTemp?.selectedAddress)
            }
            return;
        }
        if (orderType === ORDER_TYPE_DELIVERY && placeId !== selectedAddressPlaceId && !isMobile) {
            setGetShopsIsLoading(true)
            dispatch(updateAddressTemp({
                getShopsIsLoading: true
            }));
        }
        setTypedAddress(selectedAddressValue);
        setLastTypedAddressValidValue(selectedAddressValue);
        setErrorMessage(null);
        setSelectedAddressPlaceId(placeId);
    }

    const handleAddressInputFormControlError = (status: any, clearSuggestions: any) => {
        const errorMessage = String(status);
        if (!errorMessage.includes('ZERO_RESULTS')) {
            sendCloudWatchAlert(`Error from google api ${status}`);
        }
        setErrorMessage(WRONG_ADDRESS);
        clearSuggestions();
    }

    return {
        handleAddressTypeSelection,
        handleOrderTypeSelection,
        handleAddressSelection,
        setTypedAddress,
        handleAddressInputFormControlError,
        handleValidationButtonClick,
        errorMessage,
        isMobile,
        isCustomerAddress,
        customerAddress,
        secondaryColor,
        orderTypes,
        orderTypeChoiceIndex,
        typedAddress,
        isFilledAddressValid,
        hasErrorAddressMessage,
        shouldRenderAddressSearchInput,
        getShopsIsLoading,
        inputElementRef,
        orderType,
        displayIsSavingSpinner
    }
}