const styles = {
  imageSectionTerminalVertical: {
    height: '75px',
    zIndex: '-1'
  },
  imageSection: {
    width: '40px',
    height: '40px'
  }
};

export default styles;
