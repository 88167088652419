import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import Product from '../Product'
import { getSectionsRef, selectSection } from '../../../redux/actions'
import {
  filterSections,
  getTerminalVerticalHeight,
  isDarkTheme,
  isProductDisabled,
  isStringNotNull,
  isTerminalVertical,
  convertHexToRGBA,
  isProductDisabledOrderType
} from '../../../utils'
import { PRODUCT_STYLE_GRID, PRODUCT_STYLE_LIST } from '../../../constants'
import { UNIT } from '../../../constants/configuration'
import { handleScrollTerminalVertical, updateMsgSectionRange } from './ListProduct.services'

class ListProduct extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
      messages: {},
    };
  }
   componentDidMount() {
      handleScrollTerminalVertical(this);
      this.intervalId = setInterval(() => updateMsgSectionRange(this), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  renderList = section => {
    if (section) {
      const { productStyle, isHorizontalSection } = this.props;
      const { messages } = this.state;
      const { products } = section;
      return _.keys(products)
        .sort((a, b) => {
          const productA = products[a];
          const productB = products[b];
          if (productA && productB) {
            const positionA = isProductDisabled(productA)
              ? 70 + productA.position
              : productA.position;
            const positionB = isProductDisabled(productB)
              ? 70 + productB.position
              : productB.position;
            return positionA - positionB;
          } else return 0
        })
        .filter(key => {
          return !products[key].hidden;
        })
        .map(key => {
          const product = products[key];
          if (product?.name) {
            const cle = key.replace(/[0-9]/g, '');
            let productClass = 'col-6 col-lg-3 col-md-4 d-flex p-0 px-1';
            if (productStyle === PRODUCT_STYLE_GRID) {
              if (isHorizontalSection) {
                productClass += ' px-xl-4';
              } else {
                productClass += ' px-xl-3';
              }
            }
            if (productStyle === PRODUCT_STYLE_LIST || isTerminalVertical()) {
              productClass = `col-12 col-lg-4 col-md-6 pr-0 pr-md-3 `;
            }
            return (
              <div className={productClass} key={product.key || product.name}>
                <Product
                  product={product}
                  title={product.name}
                  image={product.image}
                  price={product.price}
                  description={product.description}
                  cle={cle}
                  productKey={key}
                  section={section}
                  messagesSectionRange={messages}
                />
              </div>
            );
          }
        });
    }
  };

  renderPanel = () => {
    const { sections, getSectionsRef, secondaryColor } = this.props;
    const { messages } = this.state;
    const newSections = filterSections(sections)
    if (newSections?.length) {
      return (
        <div className='col'>
          <div>
            {_.map(newSections, (section, index) => {
              let sectionClassList = `${
                isTerminalVertical()
                  ? 'border-bottom-mid-gray'
                  : `border-bottom ${isDarkTheme() && 'border-secondary'}`
              }`;
              if (index !== 0) {
                sectionClassList += ' pt-4';
              }
              return (
                <div
                  className={sectionClassList}
                  ref={el => {
                    getSectionsRef(section.key, el);
                    if (!this.sectionsRef) {
                      this.sectionsRef = {};
                    }
                    this.sectionsRef[section.key] = el;
                  }}
                  key={index}
                >
                  <div className='target-section' id={`${section.key}`}>
                    <div
                      className={`${
                        isTerminalVertical()
                          ? 'border-bottom-mid-gray'
                          : `border-bottom ${isDarkTheme() && 'border-secondary'}`
                      }`}
                    >
                      <div className='row justify-content-center align-items-center'>
                        {isStringNotNull(section.iconUrl) && !isTerminalVertical() && (
                          <img
                            alt={section.name}
                            src={section.iconUrl}
                            style={{ width: '40px', height: '40px' }}
                            className='mr-2 rounded-circle'
                          />
                        )}
                        <h4
                          className='font-weight-bold text-color text-center align-self-center mb-0 text-break'
                          style={isTerminalVertical() ? { fontSize: UNIT * 3.125 } : {}}
                        >
                          {!!section?.name && (section.name[0].toUpperCase() + section.name.slice(1).toLowerCase())}
                        </h4>
                      </div>
                      <div className='pb-4'>
                        {isStringNotNull(section.description) && (
                          <p className='text-color-secondary text-center align-self-center mb-0 text-break mt-1'>
                            {section.description}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                    {messages?.[section.key] && (
                      <div style={{ backgroundColor: convertHexToRGBA(secondaryColor, 0.2), }}>
                        <h4 style={{ textAlign: 'center', color: secondaryColor, fontSize: 14, padding: UNIT * 0.8 }}>
                        {`Les produits de ${section.name} seront bientôt disponibles. La prochaine plage d'activation est ${messages[section.key]}.`}
                        </h4>
                      </div>
                    )}
                  <div className='row my-3'>{this.renderList(section)}</div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };

  render() {
    const { navbarHeight } = this.props;
    return (
      <div
        className={`${isTerminalVertical() && 'sticky-top hide-scroll overflow-auto'}`}
        id='list-products'
        style={
          isTerminalVertical()
            ? {
              top: navbarHeight,
              height: getTerminalVerticalHeight()
            }
            : {}
        }
      >
        {this.renderPanel()}
      </div>
    );
  }
}

const mapStateToProps = ({
  sectionReducer,
  shopReducer,
  configurationReducer,
  componentReducer,
}) => {
  const { sections } = shopReducer;
  const { secondaryColor, productStyle } = configurationReducer;
  const { sectionKey } = sectionReducer;
  const { isHorizontalSection, navbarHeight } = componentReducer;
  return {
    sections,
    sectionKey,
    secondaryColor,
    productStyle,
    isHorizontalSection,
    navbarHeight
  };
};

export default connect(mapStateToProps, { getSectionsRef, selectSection })(ListProduct);
