import React from 'react'
import _ from 'lodash'
import Shop from '../Shop'
import { updateAddressAndShop } from '../../../utils/customer-address-and-shops';
import { updateAddressTemp } from '../../../redux/actions';

type ListShopProps = {
    isMobile: boolean
    dispatch:any,
    closestShops: any[],
    selectedAddressOrderProperties: {
        selectedAddress: string;
        orderType: string;
        getShopsIsLoading: boolean
    }
}
export default function ListShop({ isMobile, closestShops, selectedAddressOrderProperties, dispatch }: ListShopProps) {
    const shopClassname = `col-xl-4 col-lg-4 col-md-6 col-sm-6 ${isMobile && 'col-6'} mb-2 ${isMobile ? 'px-1' : 'px-3'}`;
    const handleOnShopClick = async (shopId: string) => {
        if (selectedAddressOrderProperties?.getShopsIsLoading) {
            return false;
        }
        dispatch(updateAddressTemp({ getShopsIsLoading: true }))
        const customerNewAddress = selectedAddressOrderProperties?.selectedAddress;
        await updateAddressAndShop(
            shopId,
            customerNewAddress,
            selectedAddressOrderProperties?.orderType
        );
        dispatch(updateAddressTemp({ getShopsIsLoading: false }))
        return true;
    }
    return (
        <> {closestShops?.length > 0 &&
            _.keys(closestShops)
                .sort((a, b) => {
                    const shopA = closestShops[a];
                    const shopB = closestShops[b];
                    if (shopA && shopB) {
                        const positionA = shopA.closed ? 70 : 2;
                        const positionB = shopB.closed ? 70 : 1;
                        return positionA - positionB;
                    } else return 0
                })
                .map(key => {
                    const shop = closestShops[key];
                    const { name, address, distance, sliding, shopId, description, closed } = shop;
                    return (
                        <div className={shopClassname} key={key}>
                            <Shop
                                onShopCardClick={handleOnShopClick}
                                shopIndex={shopId}
                                name={name}
                                address={address}
                                distance={distance}
                                sliding={sliding}
                                description={description}
                                closed={closed}
                            />
                        </div>
                    );
                })}
        </>
    )
}