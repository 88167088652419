import React from 'react';
import { connect } from 'react-redux';
import { showPaymentModal } from '../../redux/actions';
import { getCreditCard } from '../../utils';
import Button from '../Button';
import { UNIT } from '../../constants/configuration';

class CreditCard extends React.Component {
  renderCreditCard = card => {
    const { showPaymentModal, buttonName } = this.props;
    const { name } = card;
    return (
      <div className='input-group mb-3'>
        <input
          type='text'
          className='form-control no-localization text-center dark-modal'
          placeholder={name}
          aria-describedby='button-addon2'
          readOnly
        />
        <div className='input-group-append'>
          <Button
            className='btn-outline-secondary label'
            id='button-addon2'
            style={{ borderRadius: `0px ${UNIT / 4}px ${UNIT / 4}px 0px` }}
            onClick={() => {
              showPaymentModal(true);
            }}
          >
            {buttonName}
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { user } = this.props;
    const { cards } = user;
    const card = getCreditCard(cards);
    return <div>{card && <div>{this.renderCreditCard(card)}</div>}</div>;
  }
}

const mapStateToProps = ({ componentReducer, userReducer }) => {
  const { paymentModal } = componentReducer;
  const { user } = userReducer;
  return {
    paymentModal,
    user
  };
};

export default connect(mapStateToProps, { showPaymentModal })(CreditCard);