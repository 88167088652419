import _ from 'lodash';
import { isTerminalVertical, getOffsetTop, isSectionNotInRange, filterSections, getNextDay } from '../../../utils';
import moment from 'moment';

export const handleScrollTerminalVertical = component => {
  if (isTerminalVertical()) {
    document.getElementById('list-products').addEventListener('scroll', () => {
      const { selectSection, navbarHeight } = component.props;
      const extraTop = 4;
      let offsetTop = 0;
      let newSectionKey = null;
      if (component.sectionsRef) {
        _.map(component.sectionsRef, (sectionRef, sectionKey) => {
          const scrollY = document.getElementById('list-products').scrollTop;
          if (
            sectionRef &&
            scrollY >= getOffsetTop(sectionRef) - parseInt(navbarHeight, 10) - extraTop &&
            getOffsetTop(sectionRef) > offsetTop
          ) {
            offsetTop = getOffsetTop(sectionRef);
            newSectionKey = sectionKey;
          }
        });

        if (newSectionKey) {
          selectSection(newSectionKey);
        }
      }
    });
  }
};

const getTrueEndDate = (startDate, endDate, daysOfWeek) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const daysOfWeekArray = Object.keys(daysOfWeek);

  let lastTargetDay = null;

  for (let m = start; m.isSameOrBefore(end); m.add(1, 'days')) {
    if (daysOfWeekArray.includes(m.format('dddd'))) {
      lastTargetDay = moment(m);
    }
  }

  return lastTargetDay ? lastTargetDay.locale('fr').format('DD MMMM') : null;
};


export const updateMsgSectionRange = (component) => {
  const sections = filterSections(component.props.sections);
  const { messages } = component.state;

  if (!sections) return;

  const currentDate = moment();
  const messagesToUpdate = {};

  _.forEach(sections, section => {
    const { activationRange, key } = section;
    if (!activationRange || !isSectionNotInRange(section)) {
      if (messages[key]) messagesToUpdate[key] = null;
      return;
    }

    const { startDate, endDate, daysOfWeek } = activationRange;
    const oneWeekBeforeStartDate = moment(startDate).subtract(7, 'days');

    if (currentDate.isSameOrAfter(oneWeekBeforeStartDate)) {
      const dayOfWeek = currentDate.format('dddd');
      const currentDayConfig = daysOfWeek[dayOfWeek];
      const currentSlots = currentDayConfig?.slots || [];

      let activationRangeString = '';

      if (currentDate.isAfter(endDate)) {
        messagesToUpdate[key] = null;
      } else if (currentDate.isBefore(startDate)) {
        const nextDay = getNextDay(daysOfWeek, startDate, endDate);
        const formattedStartDate = daysOfWeek[moment(startDate).format('dddd')] ? moment(startDate).locale('fr').format('DD MMMM') : nextDay ? nextDay.split(' ').slice(1).join(' ') : ''
        const formattedEndDate = daysOfWeek[moment(endDate).format('dddd')] ? moment(endDate).locale('fr').format('DD MMMM') : getTrueEndDate(startDate, endDate, daysOfWeek);
        activationRangeString = formattedStartDate === formattedEndDate || !formattedEndDate ? `le ${formattedStartDate}` : `du ${formattedStartDate} au ${formattedEndDate}`
      } else {
        const nextDay = getNextDay(daysOfWeek, startDate, endDate, moment());
        const validSlot = currentSlots.length && !currentDayConfig?.fullDay 
        ? _.find(currentSlots, slot => currentDate.isBefore(moment(slot.split('-')[1], 'HH:mm'))) 
        : null;

        activationRangeString = validSlot ? `de ${validSlot.replace('-', ' à ')}`: nextDay;
      }

      if (!_.isEqual(messages[key], activationRangeString)) {
        messagesToUpdate[key] = activationRangeString;
      }
    }
  });

  component.setState(prevState => ({
    messages: {
      ...prevState.messages,
      ...messagesToUpdate
    }
  }));
};