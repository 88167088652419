import {
  CUSTOMER_ADDRESS_CHECK,
  IS_GOOGLE_MAPS_ENABLED,
  IS_MOBILE,
  MESSAGE_MODAL,
  SET_NAVBAR_HEIGHT,
  SET_TERMINAL_TIMEOUT,
  SHOW_ACCOUNT_MODAL,
  SHOW_ADDRESS_MODAL,
  SHOW_CART_MODAL,
  SHOW_CHOOSE_SHOP,
  SHOW_CONNEXION_MODAL,
  SHOW_FORGET_PASSWORD,
  SHOW_HORIZONTAL_SECTION,
  SHOW_HOURS_MODAL,
  SHOW_INACTIVITY_MODAL,
  SHOW_ORDER_MODAL,
  SHOW_ORDER_STATUS,
  SHOW_OVERLAY_COVER,
  SHOW_PAYMENT_MODAL,
  SHOW_PRODUCT_MODAL,
  SHOW_SIGNUP,
  SHOW_SPLASH,
  SHOW_UPDATE_INFO,
  UPDATE_ADDRESS_TEMP
} from './types'
import { SECTION_HORIZONTAL } from '../../constants/configuration'
import { isTerminal, isTerminalVertical } from '../../utils'

export const showMessageModal = (message, data) => {
  return {
    type: MESSAGE_MODAL,
    payload: { message, data }
  };
};

export const showPaymentModal = show => {
  return {
    type: SHOW_PAYMENT_MODAL,
    payload: show
  };
};

export const showConnexionModal = show => {
  return { type: SHOW_CONNEXION_MODAL, payload: show };
};

export const showCartModal = show => {
  return {
    type: SHOW_CART_MODAL,
    payload: show
  };
};

export const showProductModal = show => {
  return {
    type: SHOW_PRODUCT_MODAL,
    payload: show
  };
};

export const showSplash = (dispatch, show) => {
  dispatch({
    type: SHOW_SPLASH,
    payload: show
  });
};

export const showHoursModal = hoursModalData => {
  return {
    type: SHOW_HOURS_MODAL,
    payload: hoursModalData
  };
};

export const showSignUp = show => {
  return {
    type: SHOW_SIGNUP,
    payload: show
  };
};

export const showForgetPassword = show => {
  return {
    type: SHOW_FORGET_PASSWORD,
    payload: show
  };
};

export const showAccountModal = show => {
  return {
    type: SHOW_ACCOUNT_MODAL,
    payload: show
  };
};

export const showAddressModal = show => {
  return {
    type: SHOW_ADDRESS_MODAL,
    payload: show
  };
};

export const updateCustomerAddressCheck = check => {
  return {
    type: CUSTOMER_ADDRESS_CHECK,
    payload: check
  };
};

export const showChooseShop = show => {
  return {
    type: SHOW_CHOOSE_SHOP,
    payload: show
  };
};

export const showOverlayCover = show => {
  return {
    type: SHOW_OVERLAY_COVER,
    payload: show
  };
};

export const setIsMobile = isMobile => {
  return {
    type: IS_MOBILE,
    payload: isMobile
  };
};

export const setNavbarHeight = () => {
  let height = 16;
  if (isTerminal() && document.getElementById('cover')) {
    height += document.getElementById('cover').scrollHeight;
  } else if (document.getElementById('info-bar')) {
    height += document.getElementById('info-bar').scrollHeight;
  } else if (document.getElementsByTagName('nav')[0]) {
    height += document.getElementsByTagName('nav')[0].scrollHeight;
  }
  if (!isTerminalVertical()) {
    document.documentElement.style.setProperty('--navbar-height', `${height}px`);
    document.documentElement.style.setProperty('--navbar-margin', `-${height}px`);
  }

  return {
    type: SET_NAVBAR_HEIGHT,
    payload: `${height}px`
  };
};

export const showInactivityModal = show => {
  return {
    type: SHOW_INACTIVITY_MODAL,
    payload: show
  };
};

export const setTerminalTimeout = timeout => {
  return {
    type: SET_TERMINAL_TIMEOUT,
    payload: timeout
  };
};

export const showHorizontalSection = () => (dispatch, getState) => {
  const { isMobile } = getState().componentReducer;
  const { sectionOrientation } = getState().configurationReducer;
  dispatch({
    type: SHOW_HORIZONTAL_SECTION,
    payload: isMobile || sectionOrientation === SECTION_HORIZONTAL
  });
};

export const isGoogleMapsEnabled = enabled => {
  return {
    type: IS_GOOGLE_MAPS_ENABLED,
    payload: enabled
  };
};

export const updateAddressTemp = address => {
  return {
    type: UPDATE_ADDRESS_TEMP,
    payload: address
  };
};

export const showUpdateInfo = show => {
  return {
    type: SHOW_UPDATE_INFO,
    payload: show
  };
};

export const showOrderModal = show => {
  return {
    type: SHOW_ORDER_MODAL,
    payload: show
  };
};

export const showOrderStatus = show => {
  return {
    type: SHOW_ORDER_STATUS,
    payload: show
  };
};
