import React from 'react';
import _ from 'lodash';

class HorairesFooter extends React.Component {
  renderHoursLine = day => {
    if (day) {
      if (day.slots) {
        return (
          <>
            <div className='col-6 text-right px-3'>{day.name}</div>
            <div className='col-6 text-left px-3'>
              {_.map(day.slots, (slot, index) => {
                let slotString = `${slot}`;
                if (index !== day.slots.length - 1) {
                  slotString += ' / ';
                }
                return slotString;
              })}
            </div>
          </>
        );
      }
      return (
        <>
          <div className='col-6 text-right px-3'>{day.name}</div>
          <div className='col-6 text-left px-3'>Fermé</div>
        </>
      );
    }
  };

  renderHours = () => {
    const { hours } = this.props;
    if (hours) {
      return (
        <>
          <div className='row'>{this.renderHoursLine(hours.Monday)}</div>
          <div className='row'>{this.renderHoursLine(hours.Tuesday)}</div>
          <div className='row'>{this.renderHoursLine(hours.Wednesday)}</div>
          <div className='row'>{this.renderHoursLine(hours.Thursday)}</div>
          <div className='row'>{this.renderHoursLine(hours.Friday)}</div>
          <div className='row'>{this.renderHoursLine(hours.Saturday)}</div>
          <div className='row'>{this.renderHoursLine(hours.Sunday)}</div>
        </>
      );
    }
  };

  render() {
    return <div>{this.renderHours()}</div>;
  }
}

export default HorairesFooter;
