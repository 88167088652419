import React from 'react'
import { connect } from 'react-redux'
import Spinner from '../Spinner'

class Splash extends React.Component {
  render() {
    const { innerWidth, innerHeight } = window;
    return (
      <div
        className='justify-content-center align-items-center'
        style={{
          height: innerHeight,
          width: innerWidth,
          display: 'flex'
        }}
      >
        <div className='align-items-center'>
          <Spinner />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ sectionReducer, pendingOrderReducer }) => {
  const { sectionKey } = sectionReducer;
  const { address: customerAddress } = pendingOrderReducer;
  return {
    sectionKey,
    customerAddress
  };
};

export default connect(mapStateToProps, {})(Splash);
