import Geocode from 'react-geocode';
import { GOOGLE_API_KEY } from '../constants';
import store from '../redux/store';
import { isGoogleMapsEnabled } from '../redux/actions';
import { sendCloudWatchAlert } from '../utils';

export const initializeGoogle = googleData => {
  const { apiKey = GOOGLE_API_KEY, analytics } = googleData;
  initializeGooglePlaces(apiKey, 0);
  initializeGoogleMaps(apiKey);
  if (analytics) {
    initializeGoogleAnalytics(analytics);
  }
};

export const initializeGooglePlaces = (googleApiKey, retry) => {
  window.gm_authFailure = function() {
    // remove the map div or maybe call another API to load map
    // maybe display a useful message to the user
    if (retry < 3) {
      initializeGooglePlaces(googleApiKey, retry + 1);
    } else {
      sendCloudWatchAlert('Error initializing google maps');
    }
  };
  const script = document.createElement('script');
  script.setAttribute(
    'src',
    `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`
  );
  script.onload = function() {
    store.dispatch(isGoogleMapsEnabled(true));
  };
  document.body.appendChild(script);
};

export const initializeGoogleAnalytics = analytics => {
  const script = document.createElement('script');
  script.setAttribute('async', 'true');
  script.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${analytics}`);
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  window.gtag = function() {
    window.dataLayer.push(arguments);
  };
  window.gtag('js', new Date());
  window.gtag('config', analytics);
};

export const initializeGoogleMaps = googleApiKey => {
  Geocode.setApiKey(googleApiKey);
  Geocode.setLanguage('fr');
};

export const sendGoogleAnalytics = (eventName, eventParams) => {
  const { google } = store.getState().configurationReducer;
  if (google.analytics && window.gtag) {
    window.gtag('event', eventName, eventParams);
  }
};
