import React from 'react';
import { connect } from 'react-redux';
import { TEXT_INPUT_MAX_SIZE } from '../../constants';

class TextInputCounter extends React.Component {
  render() {
    const { className, placeholder, style, counterStyle, value, onChange } = this.props;
    return (
      <>
        <textarea
          type='text'
          className={`dark-modal ${className}`}
          placeholder={placeholder}
          style={style}
          value={value}
          onChange={onChange}
          rows={2}
          maxLength={TEXT_INPUT_MAX_SIZE}
        />
        {value && (
          <div className='text-muted' style={counterStyle}>
            {value.length || '0'}/{TEXT_INPUT_MAX_SIZE}
          </div>
        )}
      </>
    );
  }
}

export default connect(null, {})(TextInputCounter);
