import { Cookies } from 'react-cookie';
import { COOKIE_ADDRESS, COOKIE_ORDER_TYPE, COOKIE_SHOP_ID } from '../constants';
import store from '../redux/store';
import { UPDATE_PENDING_ORDER } from '../redux/actions/types';

export const setPendingOrderCookies = (
    isCustomerAddress: boolean,
    newAddress: any,
    shopId: string,
    orderType: string
) => {
    const cookies = new Cookies();
    if (isCustomerAddress) {
        cookies.set(COOKIE_ADDRESS, JSON.stringify(newAddress));
    }
    if (shopId) {
        cookies.set(COOKIE_SHOP_ID, JSON.stringify(shopId));
    }
    if (orderType) {
        cookies.set(COOKIE_ORDER_TYPE, JSON.stringify(orderType));
    }
};

export const dispatchDataFromCookies = () => {
    const newCustomerAddress = new Cookies().get(COOKIE_ADDRESS);
    const cacheShopId = new Cookies().get(COOKIE_SHOP_ID);
    const cacheOrderType = new Cookies().get(COOKIE_ORDER_TYPE);
    const { shops, shopId } = store.getState().shopReducer as any;
    let orderType = cacheOrderType;
    if (shops?.length) {
        const shop = cacheShopId ? shops.find((shop: any) => shop?.shopId === cacheShopId || shopId) : shops[0];
        orderType = shop?.configuration?.orderTypes?.includes(cacheOrderType) ? cacheOrderType : shop?.configuration?.orderTypes[0];
    }
    store.dispatch({
        type: UPDATE_PENDING_ORDER,
        payload: {
            shopId: cacheShopId,
            orderType,
            address: newCustomerAddress,
            addressIsLoaded: true
        }
    });
};
