import { UNIT, COLOR_LIGHT_GRAY } from '../../../constants/configuration';

const styles = {
  productCard: { width: '100%', cursor: 'pointer', borderRadius: UNIT },
  verticalTerminalProductCard: {
    width: '90%',
    height: '370px',
    borderRadius: UNIT * 2,
    border: `2px solid ${COLOR_LIGHT_GRAY}`,
    cursor: 'pointer',
    margin: 'auto'
  },
  productPicture: {
    borderRadius: `${UNIT}px ${UNIT}px 0px 0px`
  },
  productPictureBorder: {
    borderRadius: `${UNIT}px 0px 0px ${UNIT}px`
  },
  productPictureTerminalVertical: {
    height: '175px'
  }
};

export default styles;
