import { SELECT_SECTION, GET_SECTIONS_REF } from './types';

export const selectSection = sectionKey => {
  return {
    type: SELECT_SECTION,
    payload: sectionKey
  };
};

export const getSectionsRef = (sectionKey, sectionRef) => {
  return {
    type: GET_SECTIONS_REF,
    payload: {
      sectionKey,
      sectionRef
    }
  };
};
