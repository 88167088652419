import { formatPhone } from '../../utils';

export const formatPhoneService = (component, value) => {
  if (component.phoneInput) {
    const {
      selectedCountry: { countryCode }
    } = component.phoneInput.state;
    value = formatPhone(value, countryCode);
  }
  return value;
};
