import React from 'react'
import { connect } from 'react-redux'
import styles from './MarketplaceLanding.styles'
import { BLACK } from '../../constants'
import AddressInputMarketPlace from '../AddressInput/for_marketplace/AddressInputMarketplace.view'

class MarketplaceLanding extends React.Component {
  render() {
    const { webapp, name, theme, descriptionText } = this.props;
    const { images, descriptionAddress, hideTitle } = webapp;
    const { imageCoverOrderUrl } = images;
    return (
      <div
        className={`${theme}-theme text-white cover dishop-iso  dark-modal`}
        id='cover'
        style={{
          backgroundImage: `url(${imageCoverOrderUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: window.innerHeight * 0.915
        }}
      >
        <div className='px-sm-5 py-sm-4 text-white' style={styles.overlayCover}>
          <div className='row h-100 mt-sm-0 justify-content-center'>
            <div className='row text-center mt-sm-0'>
              <div className='col my-auto px-3 px-sm-5'>
                {!hideTitle && (
                  <p className='text-white text-title cover-title mb-0'>
                    {name}
                  </p>
                )}
                <div className='px-lg-5 px-0'>
                  <p
                    className={`line-break cover-description mb-3 ${'px-lg-5'} px-0`}
                    style={{ textShadow: `2px 2px 4px ${BLACK}` }}
                  >
                    {descriptionText || descriptionAddress}
                  </p>
                </div>
                <div
                  className={`${theme}-theme rounded d-inline-block dishop-iso  dark-modal border-color`}
                  style={{ ...styles.container }}
                >
                  <AddressInputMarketPlace />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ configurationReducer }) => {
  const { webapp, name, theme, descriptionText } = configurationReducer;
  return {
    descriptionText,
    webapp,
    name,
    theme
  };
};

export default connect(mapStateToProps, {})(MarketplaceLanding);
