import _ from 'lodash';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Media from 'react-bootstrap/Media';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import Radio from '@mui/material/Radio';
import React from 'react';
import Button from '../Button';
import AddPayment from '../AddPayment';
import Spinner from '../Spinner';
import { showPaymentModal, showMessageModal } from '../../redux/actions';
import { changeDefaultCreditCard, deleteCB } from './PaymentModal.services';
import { TEXT_VALIDATION_DELETE_CB, COLOR_LIGHT_GRAY, SECONDARY } from '../../constants';
import { addCustomerCard, sendGoogleAnalytics } from '../../api';
import ModalBorder from '../ModalBorder';

const INITIAL_STATE = {
  loading: false,
  actionModal: null,
  newCard: {
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: ''
  },
  cardId: null,
  selectedCard: {}
};

class PaymentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  changeCard = card => {
    this.setState({
      selectedCard: card
    });
  };

  renderCreditCards = () => {
    const { user, secondaryColor, showMessageModal } = this.props;
    const { selectedCard } = this.state;
    const { cards } = user;
    return _.keys(cards).map(key => {
      const card = cards[key];
      const { name, id } = card;
      return (
        <Media
          as='li'
          className='py-2 border-bottom label'
          onClick={() => {
            this.changeCard(card);
          }}
          style={{ cursor: 'pointer' }}
          key={key}
        >
          <Media.Body>
            <div className='row'>
              <div className='form-check pl-0 mr-3 align-self-center'>
                <Radio
                  onChange={() => this.changeCard(card)}
                  id={name}
                  checked={selectedCard.id === id}
                  style={{
                    color: selectedCard.id === id ? secondaryColor : COLOR_LIGHT_GRAY
                  }}
                  size='small'
                />
              </div>
              <p className='center align-self-center text-secondary no-localization label'>
                {name}
              </p>
              <DeleteIcon
                fontSize='small'
                className='ml-auto text-muted align-self-center label'
                onClick={e => {
                  e.stopPropagation();
                  showMessageModal(TEXT_VALIDATION_DELETE_CB, {
                    onClick: () => deleteCB(this, id),
                    showCancel: true
                  });
                }}
              />
            </div>
          </Media.Body>
        </Media>
      );
    });
  };

  render() {
    const { paymentModal, showPaymentModal, user, secondaryColor } = this.props;
    const { loading } = this.state;
    const { cards } = user;
    return (
      <>
        <Modal
          show={paymentModal}
          onHide={() => {
            if (!loading) {
              showPaymentModal(false);
            }
          }}
        >
          <ModalBorder>
            <Modal.Body className='dark-modal'>
              <div>
                {cards && !_.isEmpty(cards) && (
                  <div>
                    <div className='mb-4'>{this.renderCreditCards()}</div>
                  </div>
                )}
                <div className='mt-4'>
                  <AddPayment />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className='justify-content-center dark-modal'>
              {loading && <Spinner />}
              {!loading && (
                <>
                  <Button
                    type={SECONDARY}
                    onClick={() => {
                      showPaymentModal(false);
                    }}
                  >
                    Fermer
                  </Button>
                  <Button
                    type={SECONDARY}
                    style={{
                      backgroundColor: secondaryColor,
                      borderColor: secondaryColor
                    }}
                    onClick={async () => {
                      const { selectedCard } = this.state;
                      if (_.isEmpty(selectedCard) || !cards || !cards[selectedCard.id]) {
                        const { stripe, elements } = this.props;
                        this.setState({ loading: true });
                        const card = await addCustomerCard(this, stripe, elements);
                        this.setState({ loading: false });
                        if (card) {
                          showPaymentModal(false);
                        }
                      } else {
                        const { id } = selectedCard;
                        changeDefaultCreditCard(this, id);
                      }
                      sendGoogleAnalytics('add_payment_info', {});
                    }}
                  >
                    <div>Choisir CB</div>
                  </Button>
                </>
              )}
            </Modal.Footer>
          </ModalBorder>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({ configurationReducer, userReducer, componentReducer }) => {
  const { paymentModal } = componentReducer;
  const { secondaryColor } = configurationReducer;
  const { user } = userReducer;
  return {
    secondaryColor,
    user,
    paymentModal
  };
};

export default connect(mapStateToProps, {
  showPaymentModal,
  showMessageModal
})(PaymentModal);